import Entity from '../entity'

export default class ProductField extends Entity<number>{
    product_id: number;
    airline_id: number;
    country_id: number;
    price_infants: number;
    price_children: number;
    origin: string;
    destination: string;
    duration_type: string;
    duration: number;
    stars: number;
    minimum_pax: number;
    maximum_pax: number;
    frecuency_days: Array<any>;
    frecuency_hours: Array<any>;
    room_prices: Array<any>;
    allow_children: boolean;
    inherit_features: boolean;
    aditional_product: boolean;
    has_waste: boolean;
    pack_items_excluded: Array<any>;
    categories: Array<number>;
    notes: string;
}


