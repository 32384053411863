import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import WorkOrderTask from '../../entities/production/workordertask'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Task from '@/store/entities/catalogs/product';
import WorkOrder from '@/store/entities/production/workorder';
import WorkOrderActivity from '@/store/entities/production/workorderactivity';

interface WorkOrderTaskState extends ListState<WorkOrderTask>{
    editModel:WorkOrderTask;    
    order:number;    
}
class WorkOrderTaskModule extends ListModule<WorkOrderTaskState,any,WorkOrderTask>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<WorkOrderTask>(),
        loading:false,
        editModel: new WorkOrderTask(),
        activeList: new Array<WorkOrderTask>(),
        order: 0,
        path: 'work-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<WorkOrderTaskState, any>, payload: any) {
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: WorkOrderTaskState) {
            state.editModel = Object.assign({});
            state.editModel.quantity = 1;
            state.editModel.process_name = '';
            state.editModel.activities = [];
        },
        init(state: WorkOrderTaskState, model: WorkOrder) {
            state.editModel = Object.assign({});
            state.order = 0;
            state.list = [];
            if (model != undefined) {
                state.order = model.id;
                state.list = model.tasks;
            }
            state.totalCount = state.list.length;
        },
        add(state: WorkOrderTaskState, task: any) {
            let detail = new WorkOrderTask();
            detail.work_order_id = state.order;
            detail.process_id = task.process_id;
            detail.quantity = task.quantity;
            detail.process_name = task.process_name;
            detail.activities = [];

            state.list.push(detail);
            state.totalCount = state.list.length;
        },
        delete(state: WorkOrderTaskState, index: number) {
            state.list.splice(index, 1);
            state.totalCount = state.list.length;
        },
        addActivity(state: WorkOrderTaskState, item: any) {
            if (item != null) {
                let act = new WorkOrderActivity();

                act.work_order_id = state.order;
                act.work_order_task_id = item.id;
                act.activity_id = item.activity.id;
                act.activity_name = item.activity.name;

                state.list[item.index].activities.push(act);
            }
        },
        deleteActivity(state: WorkOrderTaskState, data: any) {
            state.list[data.index].activities.splice(data.act, 1);
        },
    });
}
const workordertaskModule=new WorkOrderTaskModule();
export default workordertaskModule;