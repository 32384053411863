import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import WorkOrder from '@/store/entities/production/workorder';
import WorkOrderHistory from '@/store/entities/sales/history';
import WorkOrderProduct from '@/store/entities/production/workorderproduct';
import Quotation from '@/store/entities/sales/quotation';

interface WorkOrderState extends ListState<WorkOrder>{
    editModel: WorkOrder;
    hisLoading: boolean;
    history: Array<WorkOrderHistory>;
    has_changes: boolean;
    reportList: Array<any>;
    filename: string;
}
class WorkOrderModule extends ListModule<WorkOrderState, any, WorkOrder>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<WorkOrder>(),
        loading: false,
        hisLoading: false,
        has_changes: false,
        editModel:new WorkOrder(),
        activeList: new Array<WorkOrder>(),
        history: new Array<WorkOrderHistory>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'work-orders'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<WorkOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (response.data.result && context.state.activeList != undefined)
                context.state.activeList.push(response.data.result);
            context.state.has_changes = false;
            return response.data.result;
        },
        async update(context: ActionContext<WorkOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (context.state.activeList != undefined) {
                context.state.activeList.forEach((item, k) => {
                    if (item.id == payload.data.id)
                        context.state.activeList[k] = Util.extend(context.state.activeList[k], payload.data);
                });
            }
            context.state.has_changes = false;
            return response.data.result;

        },
        async get(context: ActionContext<WorkOrderState, any>, payload: any) {
            if (payload.id == undefined) return {};
            context.state.loading = true;

            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            let item = reponse.data.result as WorkOrder;
            context.state.loading = false;
            return item;
        },
        async getLastNumber(context: ActionContext<WorkOrderState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/work-orders/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<WorkOrderState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/work-orders/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async confirm(context: ActionContext<WorkOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/work-orders/confirm', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.has_changes = false;
            return response.data.result;
        },
        async reject(context: ActionContext<WorkOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/work-orders/reject', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.has_changes = false;
            return response.data.result;
        },
        async checkStocks(context: ActionContext<WorkOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/work-orders/check-stocks', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async start(context: ActionContext<WorkOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/work-orders/start', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.has_changes = false;
            return response.data.result;
        },
        async finish(context: ActionContext<WorkOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/work-orders/finish', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.has_changes = false;
            return response.data.result;
        },
        async getAllActive(context: ActionContext<WorkOrderState, any>, payload: any) {
            let params = (payload.data == undefined ? {} : payload.data);
            params.perPage = 100;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: params }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.activeList = reponse.data.result.data;
            context.state.loading = false;
        },
        async print(context: ActionContext<WorkOrderState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.get('/api/work-order-pdf', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            return response.data.result;
        },
        async reportWorkOrders(context: ActionContext<WorkOrderState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/work-orders', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: WorkOrderState, model: WorkOrder) {
            let today = Util.abp.clock.today();

            let serie = Util.abp.setting.get('WO_SERIE');

            state.editModel = Object.assign({});
            state.editModel.issue_date = today;
            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PRODUCTION_COSTCENTER');
            state.editModel.customer_id = Util.abp.setting.get('DEFAULT_CUSTOMER');
            state.editModel.customer_name = '';
            state.editModel.request_by_id = Util.abp.session.employeeId;
            state.editModel.responsable_id = Util.abp.session.employeeId;
            state.editModel.priority_id = Util.abp.list.get('PRIORITY', 'P1').id;
            state.editModel.work_type_id = Util.abp.list.get('T_WORK', 'FA').id;
            state.editModel.project = null;
            
            
            state.editModel.quantity_ordered = 1;
            state.editModel.serie = (serie == 'YYYY' ? today.substr(0, 4) : serie);
            state.editModel.number = '00000';
        },
        edit(state: WorkOrderState, model: WorkOrder) {
            state.editModel = model;
            if (model.customer != null)
                state.editModel.customer_name = model.customer.fullname;

            if (model.work_product != null)
                state.editModel.work_product_name = model.work_product.name;
        },
        loadFromQuotation(state: WorkOrderState, model: Quotation) {
            if (model == undefined) return;
            var today = new Date();

            let work_type = (model['product'].product_type_id == Util.abp.setting.get('DEFAULT_PRODUCTION_TYPE') ? 'FA' : 'SE');

            state.editModel = Object.assign({});
            state.editModel.reference = model.reference;
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.sale_quotation_id = model.id;
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PRODUCTION_COSTCENTER');
            state.editModel.customer_id = model.customer_id;
            state.editModel.customer_name = model.customer_name;
            state.editModel.work_product_name = model['product'].product_name;
            state.editModel.request_by_id = Util.abp.session.employeeId;
            state.editModel.responsable_id = Util.abp.session.employeeId;
            state.editModel.priority_id = Util.abp.list.get('PRIORITY', 'P1').id;
            state.editModel.work_type_id = Util.abp.list.get('T_WORK', work_type).id;
            state.editModel.work_product_id = model['product'].product_id;
            state.editModel.quantity_ordered = model['product'].quantity;
            state.editModel.serie = today.toISOString().substr(0, 4);
            state.editModel.number = '00000';
            state.editModel.description = model['product'].product_name;
            state.editModel.total_products = model['product'].total_wt;      
            state.editModel.quotation_nro = model.nro;      
            //state.editModel.products = new Array<WorkOrderProduct>();
            
            //model['materials'].forEach((item, index) => {
            //    let det = new WorkOrderProduct();
            //    det.product_id = item.product_id;
            //    det.product_attribute_id = 0;
            //    det.product_type_id = item.item_type_id;
            //    det.unity_id = item.item_unity_id;
            //    det.product_name = item.item_name;
            //    det.code = null;
            //    det.quantity = item.quantity;
            //    det.quantity_total = item.quantity * state.editModel.quantity_ordered;
            //    det.stock = null;
            //    det.cost_wt = item.item_price;
            //    det.total_wt = item.item_price * det.quantity_total;
            //    det.unity_sym = item.unity_sym;
            //    state.editModel.products.push(det);
            //});
        },
        setChanged(state: WorkOrderState, value: boolean) {
            state.has_changes = value;
        },
        setProject(state: WorkOrderState, project: any) {
            state.editModel.project = project;
            state.editModel.project_id = project.id;
            state.editModel.customer_id = project.customer_id;
            state.editModel.customer_name = project.customer_name;
        },
    });
}
const workorderModule=new WorkOrderModule();
export default workorderModule;