import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Stock from '../../entities/stocks/stock'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface StockState extends ListState<Stock>{
    editModel: Stock;
    activeList: Array<Stock>;
    loading2: boolean;
    reportList: Array<any>;
    reportList2: Array<any>;
    warehouses: Array<any>;
    filename: string;
}
class StockModule extends ListModule<StockState, any, Stock>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Stock>(),
        loading:false,
        loading2: false,
        editModel:new Stock(),
        activeList: new Array<Stock>(),
        reportList: new Array<any>(),
        reportList2: new Array<any>(),
        warehouses: new Array<any>(),
        filename: '',
        path: 'stocks'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<StockState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            }); 
            context.state.loading = false;
            return response.data.result;
        },
        async transfer(context: ActionContext<StockState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path + "/transfer", payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async getStockWithoutLocation(context: ActionContext<StockState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/stocks-without-location', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return reponse.data.result;
        },
        async updateBatch(context: ActionContext<StockState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/stocks/batch', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async export(context: ActionContext<StockState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/stocks/export', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return reponse.data.result.filename;
        },
        async importStocks(context: ActionContext<StockState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/stocks/import',
                payload.data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList = response.data.result;
            return true;
        },

        async reportMinimalQuantity(context: ActionContext<StockState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/minimal-quantity', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportLowSales(context: ActionContext<StockState, any>, payload: any) {
            context.state.loading2 = true;
            context.state.reportList2 = [];
            let reponse = await Ajax.get('/api/reports/low-sales', { params: payload.data }).catch(e => {
                context.state.loading2 = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList2 = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading2 = false;
        },
        async reportStockWarehouse(context: ActionContext<StockState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/stock-warehouse', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.warehouses = reponse.data.result.warehouses;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportInventoryCount(context: ActionContext<StockState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/inventory-count', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportStockAvailable(context: ActionContext<StockState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/stock-available', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportReserved(context: ActionContext<StockState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/stock-reserved', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setReportList(state: StockState, list: Array<any>) {
            state.reportList = list;
        },
    });
}
const stockModule=new StockModule();
export default stockModule;