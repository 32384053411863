import Entity from '../entity'

export default class ProductSupplier extends Entity<number> {
    product_id: number;
    product_attribute_id: number;
    supplier_id: number;
    currency_id: number;
    supplier_reference: string;
    supplier_cost: number;
}

