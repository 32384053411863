import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import SubCategory from '../../entities/catalogs/subcategory'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface SubCategoryState extends ListState<SubCategory>{
    editModel: SubCategory;
}
class SubCategoryModule extends ListModule<SubCategoryState, any, SubCategory>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<SubCategory>(),
        activeList: new Array<SubCategory>(),
        loading:false,
        editModel:new SubCategory(),
        viewModel:new SubCategory(),
        path: 'subcategories',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: SubCategoryState, model: SubCategory) {
            state.editModel = model;
        },
        setCats(state: SubCategoryState, data: any) {
            state.editModel.line_id = data.line_id;
            state.editModel.category_id = data.category_id;
        },
    });
}
const subcategoryModule=new SubCategoryModule();
export default subcategoryModule;