import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PurchaseImportDetail from '../../entities/purchases/purchaseimportdetail'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import PurchaseImport from '@/store/entities/purchases/purchaseimport';

interface PurchaseImportDetailState extends ListState<PurchaseImportDetail>{
    editModel:PurchaseImportDetail;
    purchaseimport:number;    
}
class PurchaseImportDetailModule extends ListModule<PurchaseImportDetailState,any,PurchaseImportDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PurchaseImportDetail>(),
        loading:false,
        editModel: new PurchaseImportDetail(),
        activeList: new Array<PurchaseImportDetail>(),
        purchaseimport: 0,
        path: 'imports'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<PurchaseImportDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: PurchaseImportDetailState, model: PurchaseImportDetail) {
            state.editModel = model;
        },
        init(state: PurchaseImportDetailState, model: PurchaseImport) {
            state.editModel = Object.assign({});
            state.list = [];
            if (model != undefined) {
                state.purchaseimport = model.id;
                state.list = model.details;
            }
        },
        setProduct(state: PurchaseImportDetailState, product: any) {
            state.editModel.product = product;
            state.editModel.product.manufacturer_name = product.manufacturer.name;
            state.editModel.product.category_name = product.category_default != undefined ? product.category_default.name : '';
                         
            let vehicles: Array<number> = [];
            product.compatibilities.forEach((item, index) => {
                let value = item['vehicle_id'];
                vehicles.push(value);
            });
            state.editModel.product.vehicles = vehicles;
        },
        add(state: PurchaseImportDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id;
            });

            if (exist.length > 0) {
                exist[0].quantity++;
                exist[0].total = exist[0].quantity * exist[0].cost;
            } else {
                let detail = new PurchaseImportDetail();
                detail.import_id = state.editModel.id;
                detail.product_id = product.id;
                detail.product_attribute_id = 0;
                detail.warehouse_id = Util.abp.setting.get('DEFAULT_WAREHOUSE'); //REVISAR - debe ser almacen segun tipo de usuario
                detail.unity_id = product.unity_id;
                //detail.unity_sym = product.unity.symbol;
                detail.product_name = Util.formatProdName(product);
                detail.version = product.version;
                //detail.discount_type
                detail.quantity = 1;
                detail.cost = product.cost;
                
                state.list.push(detail);
            }
        },
        update(state: PurchaseImportDetailState, detail: PurchaseImportDetail) {
            //detail.price = detail.price_wt;

            detail.total = detail.quantity * detail.cost;    
        },
        delete(state: PurchaseImportDetailState, id: number) {
            state.list = state.list.filter((detail: any) => {
                return detail.id !== id;
            });
            //state.list = state.list.splice(state.list.indexOf(item), 1);
        },
        save(state: PurchaseImportDetailState, detail: PurchaseImportDetail) {
            for (var i = 0; i < state.list.length; i++) {
                if (state.list[i].id == detail.id) {
                    state.list[i] = detail;
                }
            }
        },
    });
}
const purchaseimportdetailModule=new PurchaseImportDetailModule();
export default purchaseimportdetailModule;