import Entity from '../entity'
import Vehicle from '../logistics/vehicle';
import Equipment from '../logistics/equipment';

export default class Maintenance extends Entity<number>{
    reference: string;
    serie: string;
    number: string;
    name: string;
    branch_id: number;
    item_type_id: number;
    equipment_id: number;
    vehicle_id: number;
    request_by_id: number;
    responsable_id: number;
    type_id: number;
    project_id: number;
    work_order_id: number;
    priority_id: number;
    current_state_id: number;
    duration: number;
    worked_hours: number;
    cost: number;
    request_date: string;
    estimated_start_date: string;
    start_date: string;
    finish_date: string;
    invoice_nro: string;
    notes: string;
    valid: boolean;
    in_process: boolean;
    repaired: boolean;

    vehicle: Vehicle;
    equipment: Equipment;
}


