import Entity from '../entity'
import PurchaseQuotationSupplier from './purchasequotationsupplier';
import Currency from '../preferences/currency';

export default class PurchaseQuotation extends Entity<number>{
    reference: string;
    branch_id : number;
    currency_id : number;
    payment_type_id: number;
    purchase_planning_id: number;
    purchase_request_id : number;
    current_state_id : number;
    exchange_rate: number;
    issue_date: string;
    due_date: string;
    serie: string;
    number: string;
    request_number: string;
    valid: boolean;
    send: boolean;
    ordered: boolean;

    current_state: any;
    suppliers: Array<PurchaseQuotationSupplier>;
    currency: Currency;
    currency_sign: string;
}


