import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import FoodPlan from '@/store/entities/foods/foodplan';
import PageResult from '@/store/entities/page-result';

interface FoodPlanState extends ListState<FoodPlan>{
    editModel: FoodPlan;
}
class FoodPlanModule extends ListModule<FoodPlanState, any, FoodPlan>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<FoodPlan>(),
        loading: false,
        editModel:new FoodPlan(),
        activeList: new Array<FoodPlan>(),
        path: 'food-plans'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastCode(context: ActionContext<FoodPlanState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/food-plans/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.code = result;

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: FoodPlanState, model: FoodPlan) {
            let date = new Date();          

            state.editModel = Object.assign({});
            state.editModel.customer_id = null;
            state.editModel.year = parseInt(Util.abp.clock.today().substr(0, 4));
            state.editModel.month = parseInt(Util.abp.clock.today().substr(5, 2));
            state.editModel.week = null;
            state.editModel.start_date = null;
            state.editModel.end_date = null;
        },
        edit(state: FoodPlanState, model: FoodPlan) {
            state.editModel = model;
        },
    });
}
const foodplanModule=new FoodPlanModule();
export default foodplanModule;