import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ProductCodebar from '../../entities/stocks/productcodebar'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'
import axios from 'axios'

interface ProductCodebarState extends ListState<ProductCodebar>{
    editModel: ProductCodebar;
}
class ProductCodebarModule extends ListModule<ProductCodebarState, any, ProductCodebar>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ProductCodebar>(),
        loading:false,
        editModel:new ProductCodebar(),
        activeList: new Array<ProductCodebar>(),
        path: 'product-codebars'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async print(context: ActionContext<ProductCodebarState, any>, payload: any) {
            context.state.loading = true;

            let localUrl = Util.abp.setting.get('LOCAL_STICKER_PRINTER_URL');
            let mode = Util.abp.setting.get('REMOTE_PRINTER_MODE');
            let useRemote = Util.abp.setting.get('USE_REMOTE_PRINTER');
            let method = Util.abp.setting.get('REMOTE_PRINTER_METHOD');

            let response = await Ajax.post('/api/product-sticker', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            if (mode == 'LOCAL' && useRemote == 1) {
                if (response.data.result.localUrl != undefined) localUrl = response.data.result.localUrl;
                if (method == 'POST') {
                    await axios.post(localUrl, response.data.result);
                } else {
                    let str = payload.data.products.map(a => {
                        return a.product_id + '_' + a.product_attribute_id + '_' + a.copies;
                    }).join(',');

                    let href: any = localUrl + "?endpoint=" + (url + 'api/product-sticker') + "&token=" + window.abp.auth.getToken() + "&file=1&sticker=" + payload.data.sticker + "&products=" + str;
                    const link = document.createElement('a');
                    link.href = href;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    return '';
                }
                return '';
            } else 
                return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setListFromImport(state: ProductCodebarState, items: Array<any>) {
            state.list = [];
            items.forEach(item => {
                let codebar = new ProductCodebar();

                codebar.key = item.product_id + '_' + item.product_attribute_id;
                codebar.product_id = item.product_id;
                codebar.product_attribute_id = item.product_attribute_id;
                codebar.code = item.code;
                codebar.upc = item.upc;
                codebar.reference = item.reference;
                codebar.name = item.product_name;
                codebar.manufacturer = item.manufacturer;
                codebar.quantity = item.quantity;
                codebar.copies = item.quantity;
                codebar.batch_id = (item.batches != null ? item.batches[0].batch_id : 0);

                state.list.push(codebar);
            });
        },
    });
}
const productcodebarModule=new ProductCodebarModule();
export default productcodebarModule;