import Entity from '../entity'

export default class InvoiceCharge extends Entity<number>{
    code: number;
    name: string;
    charge_type_id: number;
    rate: number;
    active: boolean;
}


