import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Inventory from '../../entities/stocks/inventory'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import InventoryDetail from '@/store/entities/stocks/inventorydetail';

interface InventoryState extends ListState<Inventory>{
    editModel:Inventory;
    hasChanges: boolean;      
    reportList: Array<any>; 
    filename: string;     
}
class InventoryModule extends ListModule<InventoryState,any,Inventory>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Inventory>(),
        loading:false,
        hasChanges: false,
        editModel: new Inventory(),
        reportList: new Array<any>(),
        filename: '',
        path: 'inventories'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<InventoryState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async update(context: ActionContext<InventoryState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async get(context: ActionContext<InventoryState, any>, payload: any) {
            if (payload.id == undefined) return {};

            context.state.loading = true;
            let reponse = await Ajax.get('/api/inventories/get/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            let item = reponse.data.result as Inventory;
            context.state.loading = false;
            return item;
        },
        async getLastNumber(context: ActionContext<InventoryState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/inventories/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async validate(context: ActionContext<InventoryState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/inventories/validate', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return true;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: InventoryState, model: Inventory) {
            state.editModel = Object.assign({});
            state.editModel.inventory_date = Util.abp.clock.today();
            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.warehouse_id = Util.abp.session.warehouseId;
            state.editModel.number = 0;
            state.editModel.valid = false;
            state.editModel.details = new Array<InventoryDetail>();
            state.hasChanges = false;
        },
        edit(state: InventoryState, model: Inventory) {
            state.editModel = model;
            state.hasChanges = false;
        },
        setChanged(state: InventoryState, value: boolean) {
            state.hasChanges = value;
        },
    });
}
const inventoryModule=new InventoryModule();
export default inventoryModule;