import Entity from '../entity'
import Address from '../customers/address';
import Employee from '../admin/employee';

export default class Workshop extends Entity<number>{
    company_id: number;
    branch_id: number;
    warehouse_id: number;
    address_id: number;
    code: string;
    name: string;
    active: boolean;

    address: Address;
    employees: Array<any>;
    employee: any;
}


