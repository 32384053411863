import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PurchaseRequestDetail from '../../entities/purchases/purchaserequestdetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import PurchaseRequest from '@/store/entities/purchases/purchaserequest';

interface PurchaseRequestDetailState extends ListState<PurchaseRequestDetail>{
    editModel:PurchaseRequestDetail;    
    order:number;    
}
class PurchaseRequestDetailModule extends ListModule<PurchaseRequestDetailState,any,PurchaseRequestDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PurchaseRequestDetail>(),
        loading:false,
        editModel: new PurchaseRequestDetail(),
        activeList: new Array<PurchaseRequestDetail>(),
        order: 0,
        path: 'purchase-requests'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<PurchaseRequestDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: PurchaseRequestDetailState, model: PurchaseRequestDetail) {
            state.editModel = model;
        },
        init(state: PurchaseRequestDetailState, model: PurchaseRequest) {
            state.editModel = Object.assign({});
            if (model != undefined) {
                state.order = model.id;
                state.list = model.details;
            }
        },
        add(state: PurchaseRequestDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id && detail.product_attribute_id == product['product_attribute_id'];
            });

            if (exist.length > 0) {
                exist[0].quantity += product.quantity;
            } else {
                let detail = new PurchaseRequestDetail();
                detail.purchase_request_id = state.editModel.id;
                detail.product = product;
                detail.product_id = product.id;
                detail.product_attribute_id = product['product_attribute_id'];
                detail.unity_id = product.unity_id;
                detail.unity_sym = product.unity_symbol;
                detail.code = product.code;
                detail.upc = product.upc;
                detail.product_name = Util.formatProdName(product);
                detail.version = product.version;
                detail.quantity = product.quantity;
                detail.cost_wt = parseFloat((parseFloat(String(product.cost_wt)) / product['exchange_rate']).toFixed(6));
                detail.total_wt = detail.quantity * detail.cost_wt;
                detail.product_cost = product.product_cost;
                detail.stock = product.stock;
                detail.service = product['service'];

                state.list.push(detail);
            }
        },
        updateList(state: PurchaseRequestDetailState, data: any) {
            state.list[data.index].quantity = data.detail.quantity;
            state.list[data.index].cost_wt = data.detail.cost_wt;
            state.list[data.index].total_wt = data.detail.total_wt;
        },
        delete(state: PurchaseRequestDetailState, index: number) {
            state.list.splice(index, 1);
        },
    });
}
const orderdetailModule=new PurchaseRequestDetailModule();
export default orderdetailModule;