import Entity from '../entity'
import MeasureUnit from '../catalogs/measureunit';
import Product from '../catalogs/product';

export default class InventoryDetail extends Entity<number>{
    inventory_id: number;
    product_id: number;
    product_attribute_id: number;
    unity_id: number;
    product_name: string;
    quantity: number;
    stock: number;
    code: string;
    
    unity: MeasureUnit;
    product: Product;
    unity_sym: string;
}


