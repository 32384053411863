import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PurchaseRequest from '@/store/entities/purchases/purchaserequest';
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PurchaseRequestDetail from '@/store/entities/purchases/purchaserequestdetail';
import PurchaseRequestHistory from '@/store/entities/purchases/history';
import WorkOrder from '@/store/entities/production/workorder';

interface PurchaseRequestState extends ListState<PurchaseRequest>{
    editModel: PurchaseRequest;    
    hisLoading: boolean;
    hasChanges: boolean;
    history: Array<PurchaseRequestHistory>;
}
class PurchaseRequestModule extends ListModule<PurchaseRequestState, any,PurchaseRequest>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PurchaseRequest>(),
        loading:false,
        hisLoading: false,
        hasChanges: false,
        editModel: new PurchaseRequest(),
        activeList: new Array<PurchaseRequest>(),
        history: new Array<PurchaseRequestHistory>(),
        path: 'purchase-requests'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<PurchaseRequestState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async update(context: ActionContext<PurchaseRequestState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async getLastNumber(context: ActionContext<PurchaseRequestState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/purchase-requests/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<PurchaseRequestState, any>, payload: any) {
            context.state.hisLoading = true; 
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/purchase-requests/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async request(context: ActionContext<PurchaseRequestState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/purchase-requests/request', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async response(context: ActionContext<PurchaseRequestState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/purchase-requests/response', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async print(context: ActionContext<PurchaseRequestState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.get('/api/purchase-requests-pdf', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: PurchaseRequestState, model: PurchaseRequest) {
            var today = Util.abp.clock.today();
            let serie = Util.abp.setting.get('P_REQUEST_SERIE');

            state.editModel = Object.assign({});
            state.editModel.order_date = today;
            state.editModel.user_id = Util.abp.session.userId;
            state.editModel.area_id = Util.abp.setting.get('DEFAULT_AREA');
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PURCHASE_COSTCENTER');     
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_PURCHASE_CURRENCY');
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.currency = null;
            state.editModel.exchange_rate = 1;
            state.editModel.total_wt = 0;
            state.editModel.priority_id = 0;
            state.editModel.work_order_id = null;            
            state.editModel.serie = (serie == 'YYYY' ? today.substr(0, 4) : serie);
            state.editModel.number = '0000';
            state.editModel.details = new Array<PurchaseRequestDetail>();
            
            state.editModel.area_id = null;
            state.editModel.user_id = Util.abp.session.userId;
        },
        edit(state: PurchaseRequestState, model: PurchaseRequest) {
            state.editModel = model;
            state.editModel.currency_sign = model.currency.sign;
            state.hasChanges = false;
        },
        setCurrency(state: PurchaseRequestState, currency: any) {
            state.editModel.currency = currency;
            state.editModel.currency_id = currency.id;
            state.editModel.currency_sign = currency.sign;
            state.editModel.exchange_rate = currency.conversion_rate;
            state.hasChanges = true;
        },
        updateAmount(state: PurchaseRequestState, details: Array<PurchaseRequestDetail>) {
            state.editModel.total_wt = 0;

            details.forEach((item, index) => {
                state.editModel.total_wt += item.total_wt;
            });
            state.hasChanges = true;
        },
        loadFromWorkOrder(state: PurchaseRequestState, model: WorkOrder) { 
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);

            let serie = Util.abp.setting.get('P_REQUEST_SERIE');

            state.editModel = Object.assign({});
            state.editModel.reference = model.reference;
            state.editModel.user_id = Util.abp.session.userId;
            state.editModel.area_id = Util.abp.setting.get('DEFAULT_AREA');
            state.editModel.cost_center_id = model.cost_center_id;
            state.editModel.branch_id = model.branch_id;
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_PURCHASE_CURRENCY');
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.exchange_rate = 1;
            state.editModel.currency = null;
            state.editModel.work_order_id = model.id; 
            state.editModel.priority_id = null;
            state.editModel.request_type_id = Util.abp.list.get('T_REQUEST', 'OT').id;
            state.editModel.order_date = Util.abp.clock.today();
            state.editModel.delivery_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.serie = (serie == 'YYYY' ? Util.abp.clock.today().substr(0, 4) : serie);
            state.editModel.number = '0000';
            state.editModel.order_number = model.nro;
            state.editModel.total_wt = 0;
            state.editModel.details = new Array<PurchaseRequestDetail>();

            model.products.forEach((item, index) => {
                let quantity = (item.quantity_total - item.quantity_request) - item.stock;
                if (item.quantity_received > 0)
                    quantity = item.quantity_total - item.stock;

                if (quantity > 0) {
                    let det = new PurchaseRequestDetail();
                    det = Util.extend(true, {}, item);
                    det.quantity = quantity;
                    det.total_wt = det.quantity * det.cost_wt;
                    det.service = false;
                    det.order_number = model.nro;
                    det.work_order_id = model.id; 
                    state.editModel.total_wt += det.total_wt;

                    delete det.id;
                    state.editModel.details.push(det);
                }
            });
        },
        loadFromWorkOrders(state: PurchaseRequestState, list: Array<WorkOrder>) {
            if (list == undefined) return;
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);

            let item = list[0];
            state.editModel.reference = item.reference;
            state.editModel.cost_center_id = item.cost_center_id;
            state.editModel.work_order_id = item.id;
            state.editModel.branch_id = item.branch_id;
            state.editModel.request_type_id = Util.abp.list.get('T_REQUEST', 'OT').id;
            state.editModel.order_date = Util.abp.clock.today();
            state.editModel.delivery_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.order_number = item.nro;
            state.editModel.total_wt = 0;
            state.editModel.details = new Array<PurchaseRequestDetail>();

            let order_number = [];
            list.forEach(model => {
                order_number.push(model.nro);

                model.products.forEach((item, index) => {
                    let quantity = (item.quantity_total - item.quantity_request) - item.stock;
                    if (quantity > 0) {
                        let det = new PurchaseRequestDetail();
                        det = Util.extend(true, {}, item);
                        det.quantity = quantity;
                        det.total_wt = det.quantity * det.cost_wt;
                        det.service = false;
                        det.order_number = model.nro;
                        det.work_order_id = model.id;
                        state.editModel.total_wt += det.total_wt;

                        delete det.id;
                        state.editModel.details.push(det);
                    }
                });
            });

            state.editModel.order_number = order_number.join('/');
        },
        loadFromPlanning(state: PurchaseRequestState, data: any) {
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);
            let serie = Util.abp.setting.get('P_REQUEST_SERIE');

            state.editModel = Object.assign({});
            state.editModel.reference = data.model.reference;
            state.editModel.purchase_planning_id = data.model.id;
            state.editModel.user_id = Util.abp.session.userId;
            state.editModel.area_id = Util.abp.setting.get('DEFAULT_AREA');     
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PURCHASE_COSTCENTER');     
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_PURCHASE_CURRENCY');
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.exchange_rate = 1;
            state.editModel.currency = null;
            state.editModel.request_type_id = Util.abp.list.get('T_REQUEST', 'NU').id;
            state.editModel.order_date = Util.abp.clock.today();
            state.editModel.delivery_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.serie = (serie == 'YYYY' ? Util.abp.clock.today().substr(0, 4) : serie);
            state.editModel.number = '0000';
            state.editModel.total_wt = 0;
            state.editModel.details = new Array<PurchaseRequestDetail>();

            data.products.forEach((item, index) => {
                if (item.quantity_request < item.quantity) {
                    let det = new PurchaseRequestDetail();
                    det = Util.extend(true, {}, item);

                    //det.product_id = item.product_id;
                    //det.product_attribute_id = item.product_attribute_id;
                    //det.unity_id = item.unity_id;
                    //det.product_name = item.product_name;
                    //det.code = item.code;
                    //det.quantity = item.quantity;
                    //det.cost_wt = item.cost_wt;
                    det.quantity = item.quantity - item.quantity_request;
                    det.total_wt = det.quantity * det.cost_wt;
                    det.service = false;

                    state.editModel.total_wt += det.total_wt;

                    delete det.id;
                    state.editModel.details.push(det);
                }
            });
        },
        setChanged(state: PurchaseRequestState, value: boolean) {
            state.hasChanges = value;
        },
    });
}
const orderModule = new PurchaseRequestModule();
export default orderModule;