import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import AccountCombined from '../../entities/cashiers/accountcombined'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface AccountCombinedState extends ListState<AccountCombined>{
    editModel: AccountCombined;
    reportList: Array<any>;
    dues: Array<any>;
}
class AccountCombinedModule extends ListModule<AccountCombinedState,any,AccountCombined>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<AccountCombined>(),
        loading:false,
        editModel: new AccountCombined(),
        activeList: new Array<AccountCombined>(),
        reportList: new Array<any>(),
        dues: new Array<any>(),
        path: 'account-combined'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async get(context: ActionContext<AccountCombinedState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.loading = false;
            return reponse.data.result;
        },
        async updateDue(context: ActionContext<AccountCombinedState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/account-combined/due/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: AccountCombinedState, model: AccountCombined) {
            state.editModel = Object.assign({});
            state.editModel.amount = 0;
            state.editModel.paid = 0;
            state.editModel.balance = 0;
            state.editModel.charge_interest = false;
            state.editModel.charge_extra_fee = false;
        },
        setDues(state: AccountCombinedState, list: Array<any>) {
            state.dues = list;
        },
        setTotals(state: AccountCombinedState, model: AccountCombined) {
            state.editModel.paid = model.paid;
            state.editModel.balance = model.balance;
            state.editModel.state_id = model.state_id;
        },
    });
}
const accountcombinedModule=new AccountCombinedModule();
export default accountcombinedModule;