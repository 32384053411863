import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import SunatSummary from '../../entities/sales/sunatsummary'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Sale from '@/store/entities/sales/sale';

interface SunatSummaryState extends ListState<SunatSummary>{
    editModel:SunatSummary;    
}
class SunatSummaryModule extends ListModule<SunatSummaryState,any,SunatSummary>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<SunatSummary>(),
        loading:false,
        editModel: new SunatSummary(),
        activeList: new Array<SunatSummary>(),
        path: 'sunat-summaries'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<SunatSummaryState, any>, payload: any) {
            context.state.loading = true;
            let model = payload.data;
            model.serie = context.state.editModel.serie;
            model.summary_type = context.state.editModel.summary_type;
            model.number_start = context.state.editModel.number_start;
            model.number_end = context.state.editModel.number_end;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async invoice(context: ActionContext<SunatSummaryState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/sunat-summary-invoice', payload.data).catch(e => {
                context.state.loading = false;
                return { error: true, response: e };
            });
            context.state.loading = false;

            if (response['error'])
                return response;
            else
                return response['data'].result;
        },
        async verifyInvoice(context: ActionContext<SunatSummaryState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/sunat-summary-verify', payload.data).catch(e => {
                context.state.loading = false;
                return { error: true, response: e };
            });
            context.state.loading = false;
            if (response['error'])
                return response;
            else
                return response['data'].result;
        },
        async getLastDocument(context: ActionContext<SunatSummaryState, any>, payload: any) {
            let result = null;
            context.state.loading = true;
            await Ajax.get('/api/sunat-summaries/last-document', { params: payload.data }).then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.document = result;
            context.state.loading = false;
            return result;
        },
        async downloadInvoiceFile(context: ActionContext<SunatSummaryState, any>, payload: any) {
            let enableNube = Util.abp.setting.get('NUBEFACT_EINVOICE_ENABLE');
            let enableSunat = Util.abp.setting.get('SUNAT_EINVOICE_ENABLE');
            context.state.loading = true;
            let data = { params: payload.data };
            if (enableSunat == 1) data['responseType'] = 'blob';
            let response = await Ajax.get('/api/sunat-summaries-download', data).then(response => {
                const link = document.createElement('a');
                if (enableNube == 1) {
                    const url = response.data.result;
                    link.href = url;
                    if (payload.data.type == 'pdf')
                        link.target = '_blank';
                } else if (enableSunat == 1) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    link.href = url;
                }
                link.setAttribute('download', payload.data.filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: SunatSummaryState, model: SunatSummary) {
            var today = new Date();
            state.editModel = Object.assign({});
            state.editModel.date_process = Util.abp.clock.today();
            state.editModel.invoiced = false;
            state.editModel.serie = '';
            state.editModel.summary_type = 'invoice';
            state.editModel.number_start = 0;
            state.editModel.number_end = 0;
            state.editModel.document = 'RC-' + state.editModel.date_process.replace('-', '').replace('-', '');
        },
        setInvoicesList(state: SunatSummaryState, list: Array<Sale>) {
            if (list == undefined) return;
            if (list.length == 0) return;
            state.editModel.number_start = parseInt(list[0].invoice_number);
            state.editModel.number_end = parseInt(list[0].invoice_number);

            list.forEach((item, index) => {
                state.editModel.serie = item.invoice_serie;
                if (parseInt(item.invoice_number) < state.editModel.number_start)
                    state.editModel.number_start = parseInt(item.invoice_number);

                if (parseInt(item.invoice_number) > state.editModel.number_end)
                    state.editModel.number_end = parseInt(item.invoice_number);
            });
        },
    });
}
const sunatsummaryModule=new SunatSummaryModule();
export default sunatsummaryModule;