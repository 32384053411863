import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import InvoiceType from '../../entities/sales/invoicetype'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import InvoiceTypeSerie from '@/store/entities/sales/invoicetypeserie';

interface InvoiceTypeState extends ListState<InvoiceType>{
    editModel: InvoiceType;
    seriesList: Array<InvoiceTypeSerie>;  
}
class InvoiceTypeModule extends ListModule<InvoiceTypeState,any,InvoiceType>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<InvoiceType>(),
        loading:false,
        editModel: new InvoiceType(),
        activeList: new Array<InvoiceType>(),
        seriesList: new Array<InvoiceTypeSerie>(),
        path: 'invoice-types'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setSeriesList(state: InvoiceTypeState, series: Array<InvoiceTypeSerie>) {
            state.seriesList = series;
        }
    });
}
const invoicetypeModule=new InvoiceTypeModule();
export default invoicetypeModule;