import Entity from '../entity'

export default class Printer extends Entity<number>{
    branch_id: number;
    printer_type_id: number;
    port_type_id: number;
    page_type_id: number;
    name: string;
    ip: string;
    characters: number;
    format: string;
    active: boolean;
}


