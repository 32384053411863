import Entity from '../entity'

export default class SaleDiscount extends Entity<number>{
    sale_id: number;
    name: string;
    discount_type: string;
    discounts: number;
    base: number;
    reduction: number;
    total: number;
}


