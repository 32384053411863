import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import FixedAssetType from '../../entities/logistics/fixedassettype'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface FixedAssetTypeState extends ListState<FixedAssetType>{
    editModel: FixedAssetType;
    activeList: Array<FixedAssetType>;
}
class FixedAssetTypeModule extends ListModule<FixedAssetTypeState, any, FixedAssetType>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<FixedAssetType>(),
        loading:false,
        editModel:new FixedAssetType(),
        activeList: new Array<FixedAssetType>(),
        path: 'fixedasset-types'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastCode(context: ActionContext<FixedAssetTypeState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/fixedasset-types/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: FixedAssetTypeState, id: number) {
            state.editModel = Object.assign({});
            state.editModel.code = '';
            state.editModel.name = '';
            state.editModel.depreciation_method_id = Util.abp.list.get('M_DEPRES', 'LR').id;
            state.editModel.useful_life_unit = 'A';
            state.editModel.useful_life = 1;
        },
    });
}
const fixedassettypeModule=new FixedAssetTypeModule();
export default fixedassettypeModule;