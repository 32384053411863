import Entity from '../entity'

export default class Voucher extends Entity<number>{
    reference: string;
    customer_id: number;
    currency_id: number;
    product_id: number;
    sale_id: number;
    sale_detail_id: number;
    product_name: string;
    sale_date: string;
    date_from: string;
    date_to: string;
    amount: number;
    used_amount: number;
    balance: number;
    active: boolean;
}


