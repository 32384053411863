import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import SaleOrder from '../../entities/sales/saleorder'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import appconst from '../../../lib/appconst'
import Customer from '@/store/entities/customers/customer';
import SaleOrderDetail from '@/store/entities/sales/saleorderdetail';
import SaleOrderHistory from '@/store/entities/sales/history';
import Address from '@/store/entities/customers/address';
import PageResult from '@/store/entities/page-result';
import Currency from '@/store/entities/preferences/currency';
import Quotation from '@/store/entities/sales/quotation';

interface SaleOrderState extends ListState<SaleOrder>{
    editModel: SaleOrder;    
    hisLoading: boolean;
    hasChanges: boolean;
    history: Array<SaleOrderHistory>;
    addresses: Array<Address>;
    discounts: number;
}
class SaleOrderModule extends ListModule<SaleOrderState, any, SaleOrder>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<SaleOrder>(),
        loading:false,
        hisLoading: false,
        hasChanges: false,
        editModel: new SaleOrder(),
        activeList: new Array<SaleOrder>(),
        addresses: new Array<Address>(),
        history: new Array<SaleOrderHistory>(),
        discounts: 0,
        path: 'sale-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async createFromQuotation(context: ActionContext<SaleOrderState, any>, payload: any) {
            context.state.loading = true;
            let data = payload.data;
            data.serie = Util.abp.setting.get('ORDER_SERIE');
            data.reference = payload.data.reference;
            data.quotation_nro = payload.data.nro;
            data.sale_quotation_id = payload.data.id;
            data.issue_date = Util.abp.clock.today();
            data.delivery_date = Util.abp.clock.today();
            data.current_state_id = 0;
            data.id = 0;

            let prod = Util.abp.list.getProdId('PS');
            for (var i = 0; i < data.details.length; i++) {
                if (data.details[i].product_type_id == prod) {
                    data.details[i].id = 0;
                    data.details[i].warehouse_id = Util.abp.session.warehouseId;

                    delete data.details[i].sale_quotation_id;
                    delete data.details[i].unity;
                    delete data.details[i].tax;
                }
            }

            let response = await Ajax.post('/api/' + (context.state as any).path, data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async create(context: ActionContext<SaleOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async update(context: ActionContext<SaleOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async reserveStocks(context: ActionContext<SaleOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '-reserve/' + payload.data.order.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            context.state.editModel = response.data.result;
            context.state.activeList = [];
        },
        async getLastNumber(context: ActionContext<SaleOrderState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/sale-orders/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<SaleOrderState, any>, payload: any) {
            context.state.hisLoading = true; 
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/sale-orders/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async print(context: ActionContext<SaleOrderState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.get('/api/sale-order-invoice', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            return response.data.result;
        },
        async getPendingOrders(context: ActionContext<SaleOrderState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/sale-orders/pending', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<SaleOrder>;
            context.state.totalCount = page.total;
            context.state.list = page.data;
        },
        async downloadOrder(context: ActionContext<SaleOrderState, any>, payload: any) {
            context.state.loading = true;
            payload.data.downloadPDF = true;    
            await Ajax.get('/api/sale-order-invoice', {
                responseType: 'blob',
                params: payload.data
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', payload.data.filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: SaleOrderState, model: SaleOrder) {
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 30);
            state.addresses = [];
            let serie = Util.abp.setting.get('ORDER_SERIE');

            let currency = new Currency();
            currency.id = Util.abp.setting.get('DEFAULT_CURRENCY_ORDER');
            currency.conversion_rate = 1;
            state.editModel = Object.assign({});

            state.editModel.customer = Object.assign({});
            state.editModel.customer_id = null;
            state.editModel.customer_code = '';
            state.editModel.customer_name = '';
            state.editModel.customer_document = '';
            state.editModel.group_name = '';
            state.editModel.credit_line = 0;
            state.editModel.credit_days = 0;

            state.editModel.delivery_address = null;
            state.editModel.delivery_address_id = null;
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.delivery_date = Util.abp.clock.today();
            state.editModel.expiration_date = Util.abp.clock.today();
            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.customer_id = null;
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY_ORDER');
            state.editModel.currency = currency;
            state.editModel.currency_sign = 'S/';
            state.editModel.exchange_rate = 1;
            state.editModel.payment_type_id = Util.abp.list.get('C_SALEPAID', 'CO').id;
            state.editModel.discounts = 0.00;
            state.editModel.subtotal = 0.00;
            state.editModel.subtotal_wt = 0.00;
            state.editModel.taxes = 0.00;
            state.editModel.total = 0.00;
            state.editModel.total_wt = 0.00;
            state.editModel.total_paid = 0.00;
            state.editModel.balance = 0.00; 
            state.editModel.serie = (serie == 'YYYY' ? Util.abp.clock.today().substr(0, 4) : serie);
            state.editModel.number = '00000';
            state.editModel.details = new Array<SaleOrderDetail>();
            state.hasChanges = false;
            state.discounts = 0;

            state.editModel.seller_id = Util.abp.session.userId;

        },
        edit(state: SaleOrderState, model: SaleOrder) {
            state.editModel = model;
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.customer = model.customer;
            state.editModel.customer_code = model.customer.code;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_document = model.customer.document_full;
            state.editModel.credit_line = model.customer.credit_line;
            if (model.customer.group != null) state.editModel.group_name = model.customer.group.name;
            state.hasChanges = false;
            state.discounts = model.discounts;
        },
        setCurrency(state: SaleOrderState, currency: Currency) {                                 
            state.editModel.currency = currency;
            state.editModel.currency_id = currency.id;
            state.editModel.currency_sign = currency.sign;
            state.editModel.exchange_rate = currency.conversion_rate;
            state.hasChanges = true;
        },
        setCustomer(state: SaleOrderState, customer: any) {
            state.editModel.customer = customer;
            state.editModel.customer_id = customer.id;
            state.hasChanges = true;
        },
        updatePayments(state: SaleOrderState, total_paid: any) {
            state.editModel.total_paid = parseFloat(total_paid);
            state.editModel.balance = state.editModel.total_wt - state.editModel.total_paid;
            if (state.editModel.total_paid > 0)
                state.editModel.current_state_id = appconst.sState.PART;

            if (state.editModel.total_paid == state.editModel.total_wt)
                state.editModel.current_state_id = appconst.sState.PAID;
            state.hasChanges = true;
        },
        setTotals(state: SaleOrderState, data: any) {
            if (state.editModel.total_wt != data.total_wt) state.hasChanges = true;
            state.editModel.subtotal = data.subtotal;
            state.editModel.subtotal_wt = data.subtotal_wt;
            state.editModel.discounts = data.discounts;
            state.editModel.taxes = data.taxes;
            state.editModel.total = data.total;
            state.editModel.total_wt = data.total_wt;
            state.editModel.balance = data.balance;
            state.editModel.total_paid = data.total_paid;
            state.editModel.discounts = data.discount_total;
            state.discounts = data.discount_total;
            if (Math.abs(state.editModel.balance) < 0.01) state.editModel.balance = 0;
        },
        setAddresses(state: SaleOrderState, addresses: any) {
            state.addresses = addresses;
        },
        loadFromQuotation(state: SaleOrderState, model: Quotation) {
            if (model == undefined) return;
            var today = new Date();

            state.editModel = Object.assign({});
            state.editModel.branch_id = model.branch_id;
            state.editModel.customer = model.customer;
            state.editModel.customer_code = model.customer.code;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_document = model.customer.document_full;
            state.editModel.credit_line = model.customer.credit_line;
            state.editModel.group_name = model.group_name;
            state.editModel.reference = model.reference;
            state.editModel.quotation_nro = model.nro;
            state.editModel.sale_quotation_id = model.id;
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.delivery_date = Util.abp.clock.today();
            state.editModel.expiration_date = Util.abp.clock.today();
            state.editModel.payment_type_id = model.payment_type_id;
            state.editModel.customer_id = model.customer_id;
            state.editModel.currency_id = model.currency_id;
            state.editModel.seller_id = model.seller_id;
            state.editModel.credit_days = model.credit_days;
            state.editModel.including_taxes = model.including_taxes;
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.exchange_rate = model.exchange_rate;
            state.editModel.discounts = model.discounts;
            state.editModel.subtotal = model.subtotal;
            state.editModel.subtotal_wt = model.subtotal_wt;
            state.editModel.taxes = model.taxes;
            state.editModel.total = model.total;
            state.editModel.total_wt = model.total_wt;
            state.editModel.total_paid = 0;
            state.editModel.balance = model.total_wt
            state.editModel.serie = Util.abp.setting.get('ORDER_SERIE');
            state.editModel.number = '00000';
            state.editModel.details = new Array<SaleOrderDetail>();
            state.hasChanges = false;
            state.discounts = model.discounts;

            let prod = Util.abp.list.getProdId('PS');
            model.details.forEach((item, index) => {
                if (item.product_type_id == prod) {
                    let det = new SaleOrderDetail();
                    det = Util.extend(true, {}, item);
                    det.warehouse_id = Util.abp.session.warehouseId;
                    delete det.id;
                    state.editModel.details.push(det);
                }
            });
        },
        setChanged(state: SaleOrderState, value: boolean) {
            state.hasChanges = value;
        },
    });
}
const saleorderModule = new SaleOrderModule();
export default saleorderModule;