import Entity from '../entity'

export default class Category extends Entity<number>{
    line_id: number;
    name: string;
    code: number;
    description: string;
    budget: number;
    active: boolean;
    public_name: string;
    public_visibility: boolean;

    image_url: string;
    imageData: string;
}


