import Entity from '../entity'
import AssignmentDetail from './assignmentdetail';

export default class Assignment extends Entity<number>{
    reference: string;
    number: string;
    assigned_to_id: number;
    received_by_id: number;
    delivery_by_id: number;
    concept_id: number;
    current_state_id: number;
    location_id: number;
    issue_date: string;
    assignment_date: string;
    notes: string;
    valid: boolean;

    details: Array<AssignmentDetail>
}


