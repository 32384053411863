import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Equipment from '../../entities/logistics/equipment'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import EquipmentFault from '@/store/entities/logistics/equipmentfault';

interface EquipmentState extends ListState<Equipment>{
    editModel: Equipment;
    activeList: Array<Equipment>;
    faults: Array<EquipmentFault>;
}
class EquipmentModule extends ListModule<EquipmentState, any, Equipment>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Equipment>(),
        loading:false,
        editModel:new Equipment(),
        activeList: new Array<Equipment>(),
        faults: new Array<EquipmentFault>(),
        path: 'equipments'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: EquipmentState, model: any) {
            state.editModel = Object.assign({});
            state.editModel.equipment_type_id = Util.abp.list.get('T_ITEM', 'MA').id;
            state.editModel.hour_rate = 0;
            state.editModel.maintenance_frequency = 0;
            state.editModel.maintenance_duration = 0;
            state.editModel.avg_estimated_failure = 0;
            state.faults = [];            
        },
        edit(state: EquipmentState, model: any) {
            state.editModel = model;
            state.editModel.fixed_asset_name = (model.fixed_asset != null ? model.fixed_asset.description : (model.product != null ? model.product.name : null));
            state.faults = model.faults;
        },
        addFault(state: EquipmentState, model: any) {
            let fault = new EquipmentFault();

            fault.description = model.description;
            fault.fail_date = model.fail_date;
            fault.number = state.faults.length + 1;

            state.faults.push(fault);
        },
        deleteFault(state: EquipmentState, index) {
            state.faults.splice(index, 1);
        },
    });
}
const equipmentModule=new EquipmentModule();
export default equipmentModule;