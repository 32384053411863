import Entity from '../entity'
import ManufacturerModel from './manufacturermodel';

export default class Manufacturer extends Entity<number>{
    type_id: number;
    name: string;
    active: boolean;
    public_name: string;
    public_visibility: boolean;
    models: Array<ManufacturerModel>;
}


