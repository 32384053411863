import Entity from '../entity'

export default class RefundDetail extends Entity<number>{
    refund_id: number;
    product_id: number;
    fixed_asset_id: number;
    refund_reason_id: number;
    conservation_id: number;
    code: string;
    product_name: string;
    serial: string;
}


