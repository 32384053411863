import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import OrderDetail from '../../entities/sales/orderdetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import Order from '@/store/entities/sales/order';

interface OrderDetailState extends ListState<OrderDetail>{
    editModel:OrderDetail;    
    order: number;
    selectIndex: number;
    selectedItems: Array<any>;
    items: Array<any>;
    aditionals: Array<any>;
    remarks: Array<any>;
    price_rule: number;
    customer: number;
    group: number;
    method: number;
    reduction: number;
    price_rules: Array<number>;
}
class OrderDetailModule extends ListModule<OrderDetailState,any,OrderDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<OrderDetail>(),
        loading:false,
        editModel: new OrderDetail(),
        activeList: new Array<OrderDetail>(),
        order: 0,
        selectIndex: null,
        selectedItems: new Array<any>(),
        items: new Array<any>(),
        aditionals: new Array<any>(),
        remarks: new Array<any>(),
        customer: 0,
        group: 0,
        method: 0,
        reduction: 0,
        price_rule: Util.abp.setting.get('DEFAULT_PRICE_RULE_SALE'),
        price_rules: new Array<number>(),
        path: 'order-details'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<OrderDetailState, any>, payload: any) {
            context.state.loading = true;
            if (payload.data.order_id == null) payload.data.order_id = context.state.order;
            let response = await Ajax.post('/api/order-details', payload.data).catch(e => {
                context.state.loading = false;
                return null;
            });
            context.state.loading = false;
            if (response == null)
                return response;

            return response.data.result;
        },
        async update(context: ActionContext<OrderDetailState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/order-details/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return null;
            });
            context.state.loading = false;
            if (response == null)
                return response;

            return response.data.result;

        },
        async delete(context: ActionContext<OrderDetailState, any>, payload: any) {
            context.state.loading = true;
            let id = payload.data.id;
            let response = await Ajax.delete('/api/order-details/' + id).catch(e => {
                context.state.loading = false;
                return null;
            });

            if (response == null)
                return response;

            context.state.loading = false;
            let index = 0;
            context.state.list.forEach((detail: OrderDetail, idx) => {
                if (detail.id == payload.data.id)
                    index = idx;
            });
            context.state.list.splice(index, 1);
            context.state.totalCount = context.state.list.length;

            return response.data.result;
        },
        async updateRemarks(context: ActionContext<OrderDetailState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/order-details/remarks', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            let newDetail = response.data.result;
            context.state.list.forEach(a => {
                if (a.id == newDetail.id) {
                    a.remarks = newDetail.remarks;
                    a.has_remarks = newDetail.has_remarks;
                    a.aditional_price = newDetail.aditional_price;
                    a.aditional_price_wt = newDetail.aditional_price_wt;
                    a.taxes = newDetail.taxes;
                    a.total = newDetail.total;
                    a.total_wt = newDetail.total_wt;
                }
            });

            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        init(state: OrderDetailState, model: Order) {
            state.editModel = Object.assign({});
            state.order = model.id;
            state.price_rule = Util.abp.setting.get('DEFAULT_PRICE_RULE_SALE');
            state.reduction = 0;
            state.customer = 0;
            state.group = 0;
            state.method = 0;
            state.totalCount = 0;
            state.selectedItems = [];
            state.selectIndex = null;

            if (model != undefined) {
                state.order = model.id;
                state.list = model.details;
                state.totalCount = state.list.length;

                state.list.forEach(a => {
                    let state = Util.abp.list.getItem(a.current_state_id);
                    if (state == null) {
                        a.state_color = state.extra
                    }
                });

                if (state.list.length > 0) {
                    state.selectIndex = 0;
                    state.price_rule = model.details[0].price_rule_id;
                }
            }
        },
        edit(state: OrderDetailState, model: OrderDetail) {
            state.editModel = model;
            state.editModel.quantity = parseInt(String(state.editModel.quantity));           
        },
        add(state: OrderDetailState, product: OrderDetail) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id == product.product_id && detail.product_attribute_id == product.product_attribute_id;
            });

            if (exist.length > 0) {
                exist[0].quantity = parseInt(String(product.quantity));
                exist[0].last_added = product.last_added;
                exist[0].current_state_id = product.current_state_id;
                exist[0].preparation_time = product.preparation_time;
                exist[0].discounts = product.discounts;
                exist[0].discount_type = product.discount_type;
                exist[0].aditional_price = product.aditional_price;
                exist[0].aditional_price_wt = product.aditional_price_wt;
                exist[0].price = product.price;
                exist[0].price_wt = product.price_wt;
                exist[0].total = product.total;
                exist[0].total_wt = product.total_wt;
                exist[0].taxes = product.total_wt;

                if (exist[0].remarks[exist[0].quantity - 1] == undefined) {
                    exist[0].remarks.push({
                        excluded: [],
                        aditionals: [],
                        remarks: [],
                        remarksIds: [],
                        optional: null,
                        groups: [],
                        text: ''
                    });
                }
                
            } else {
                if (product.remarks == null) {
                    product.remarks = [{
                        excluded: [],
                        aditionals: [],
                        remarks: [],
                        remarksIds: [],
                        optional: null,
                        groups: [],
                        text: ''
                    }];
                }
                product.has_remarks = false;

                state.list.push(product);
            }
            state.totalCount = state.list.length;
        },
        setSelectIndex(state: OrderDetailState, index: number) {
            state.selectIndex = index;
        },
        setSelectedItems(state: OrderDetailState, list: Array<any>) {
            if (list != null) {
                state.selectedItems = list;
            }
        },
        updateState(state: OrderDetailState, list: Array<any>) {
            state.list.forEach(a => {
                let item = list.filter(b => { return b.id == a.id })[0];
                if (item != undefined) {
                    a.current_state_id = item.current_state_id;
                    a.served = item.served;
                    a.printed = item.printed;
                }
            });
        },
        setCustoms(state: OrderDetailState, model: any) {
            state.items = model.items;
            state.aditionals = model.aditionals;
            state.remarks = model.remarks;

            for (var i = 0; i < state.editModel.quantity; i++) {

                state.remarks.forEach((group, idx) => {
                    if (state.editModel.remarks[i].remarksIds[group.id] == undefined) 
                        state.editModel.remarks[i].remarksIds[group.id] = (group.group_type == 'multiple' ? [] : null);

                    //let ids = state.editModel.remarks[i].remarksIds[group.id];

                    if (state.editModel.remarks != null && state.editModel.remarks[i] != null) {
                        let rems = state.editModel.remarks[i].remarks;
                        group.items.forEach(item => {
                            let remark = rems.filter(a => { return a.preparation_group_id == item.preparation_group_id && a.id == item.id })[0];
                            if (remark != null) {
                                item.quantity = remark.quantity;
                            }
                        });
                    }
                });

                state.items.forEach(item => {
                    if (item.group_id > 0) {
                        if (state.editModel.remarks[i].groups != undefined && state.editModel.remarks[i].groups[item.group_nro - 1] == undefined)
                            state.editModel.remarks[i].groups[item.group_nro - 1] = null;
                    }
                });
            }
        },
        setPriceRule(state: OrderDetailState, data: any) {
            //state.list.forEach(item => {
            //    if (item.product_id == data.product_id && item.product_attribute_id == data.product_attribute_id) {
            //        item.product_price = data.product_price;
            //        item.price_wt = data.price_wt;
            //        item.price = data.price;
            //        item.price_rule_id = data.price_rule_id;
            //        Util.updateTotalDetails(item);
            //    }
            //});
        },
        setGlobalParams(state: OrderDetailState, data: any) {
            let changePrice = false;
            let check = false;
            //if (data.price_rule_id != undefined) {
            //    changePrice = (data.price_rule_id != state.price_rule);
            //    state.price_rule = Util.abp.setting.get('DEFAULT_PRICE_RULE');
            //    if (data.price_rule_id != undefined && data.price_rule_id > 0)
            //        state.price_rule = data.price_rule_id;
            //    check = true;
            //}
            //if (data.price_rules != undefined)
            //    state.price_rules = data.price_rules;

            //if (data.reduction != undefined) {
            //    state.reduction = data.reduction;
            //    check = true;
            //}

            //if (data.customer_id != undefined) {
            //    state.customer = data.customer_id;
            //    check = true;
            //}
            //if (data.group_id != undefined) {
            //    state.group = data.group_id;
            //    check = true;
            //}
            //if (data.payment_method_id != undefined) {
            //    state.method = data.payment_method_id;
            //    check = true;
            //}

            //if (check) {
            //    state.list.forEach(item => {
            //        if (!item.discount_manual) {
            //            if ((item.id == null || item.id == undefined)) {
            //                item.discounts = 0;
            //            }

            //            if (item.special_price_rule != null) {
            //                item.special_price_rule_id = null;

            //                let rule1 = ((state.group > 0 && item.special_price_rule.groups != null && item.special_price_rule.groups.length > 0 && item.special_price_rule.groups.indexOf(state.group) >= 0) || item.special_price_rule.groups == null);
            //                let rule2 = ((state.customer > 0 && item.special_price_rule.customers != null && item.special_price_rule.customers.length > 0 && item.special_price_rule.customers.indexOf(state.customer) >= 0) || item.special_price_rule.customers == null);
            //                let rule3 = ((state.method > 0 && item.special_price_rule.payment_methods != null && item.special_price_rule.payment_methods.length > 0 && item.special_price_rule.payment_methods.indexOf(state.method) >= 0) || item.special_price_rule.payment_methods == null);
            //                let rule4 = ((state.price_rule > 0 && item.special_price_rule.price_rules != null && item.special_price_rule.price_rules.length > 0 && item.special_price_rule.price_rules.indexOf(item.price_rule_id) >= 0) || item.special_price_rule.price_rules == null);
            //                let rule5 = (item.quantity >= item.special_price_rule.from_quantity);

            //                if (rule1 && rule2 && rule3 && rule4 && rule5) {
            //                    item.special_price_rule_id = item.special_price_rule.id;
            //                    item.discount_type = item.special_price_rule.discount_type;
            //                    item.discounts = item.special_price_rule.discount;
            //                    if (item.discount_type == 'amount') {
            //                        item.discounts = item.special_price_rule.discount * item.quantity;
            //                    }
            //                }

            //                Util.updateTotalDetails(item);
            //            } else if (state.reduction > 0) {
            //                item.discount_type = 'percent';
            //                item.discounts = state.reduction;
            //                Util.updateTotalDetails(item);
            //            }
            //        }
            //    });
            //}

            ////revisar si cambio de tipo de precio
            //if (changePrice) {
            //    state.list.forEach(item => {
            //        if (item.price_rule_id != state.price_rule && item.product != null && item.product.prices != null) {
            //            let rule = item.product.prices.filter((item: any) => {
            //                return item.price_rule_id == state.price_rule;
            //            })[0];

            //            item.price_rule_id = state.price_rule;
            //            item.price_wt = parseFloat(String(rule.price_wt));
            //            item.price = Util.removeTaxes(item.price_wt, item.tax_rate);
            //            item.product_price = parseFloat(String(rule.price_wt));
            //            Util.updateTotalDetails(item);
            //        }
            //    });
            //}
        },
        updateStocks(state: OrderDetailState, products: any) {
            state.list.forEach(item => {
                let exist = products.filter((product: any) => {
                    return item.product_id === product.id && item.product_attribute_id == product['product_attribute_id'];
                });

                if (exist.length > 0) {
                    item.stock = exist[0].stock;
                }
            });
        },
    });
}
const orderdetailModule=new OrderDetailModule();
export default orderdetailModule;