import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import TransferOrderDetail from '../../entities/stocks/transferorderdetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import TransferOrder from '@/store/entities/stocks/transferorder';

interface TransferOrderDetailState extends ListState<TransferOrderDetail>{
    editModel:TransferOrderDetail;    
    transferorder:number;    
}
class TransferOrderDetailModule extends ListModule<TransferOrderDetailState,any,TransferOrderDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<TransferOrderDetail>(),
        loading:false,
        editModel: new TransferOrderDetail(),
        activeList: new Array<TransferOrderDetail>(),
        transferorder: 0,
        path: 'transfer-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<TransferOrderDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: TransferOrderDetailState, model: TransferOrderDetail) {
            state.editModel = model;
        },
        init(state: TransferOrderDetailState, model: TransferOrder) {
            state.editModel = Object.assign({});
            if (model != undefined) {
                state.transferorder = model.id;
                state.list = model.details;

                state.list.forEach((item, index) => {
                    if (item.batches != null) {
                        item.batches.forEach(ba => {
                            ba.warehouse_id = item.warehouse_id;
                        });
                    }
                });
            }
        },
        setProduct(state: TransferOrderDetailState, product: any) {
            state.editModel.product = product;

        },
        add(state: TransferOrderDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id && detail.product_attribute_id == product['product_attribute_id'];
            });

            if (exist.length > 0) {
                exist[0].quantity += product.quantity;
            } else {
                let detail = new TransferOrderDetail();
                detail.transfer_order_id = state.editModel.id;
                detail.code = product.code;
                detail.product_id = product.id;
                detail.product_attribute_id = product['product_attribute_id'];
                detail.warehouse_id = (product['warehouse_id'] != undefined ? product['warehouse_id'] : Util.abp.session.warehouseId);
                detail.unity_id = product.unity_id;
                detail.unity_sym = product.unity_symbol;
                detail.product_name = Util.formatProdName(product);
                detail.version = product.version;
                detail.stock = product.stock;
                detail.quantity = product.quantity;

                if (product['location'] != null) {
                    detail['location'] = product['location'];
                    detail.batches = [{ product_location_id: product['location'].id, warehouse_id: detail.warehouse_id }];
                }
                
                state.list.push(detail);
            }
        },
        update(state: TransferOrderDetailState, detail: TransferOrderDetail) {
            detail.quantity = parseInt(String(detail.quantity));
        },
        updateList(state: TransferOrderDetailState, data: any) {
            state.list[data.index].quantity = data.detail.quantity;
        },
        delete(state: TransferOrderDetailState, index: number) {
            state.list.splice(index, 1);
        },
        updateStocks(state: TransferOrderDetailState, products: any) {
            state.list.forEach(item => {
                let exist = products.filter((product: any) => {
                    return item.product_id === product.id && item.product_attribute_id == product['product_attribute_id'];
                });

                if (exist.length > 0) {
                    item.warehouse_id = (exist[0]['warehouse_id'] != undefined ? exist[0]['warehouse_id'] : Util.abp.session.warehouseId);
                    item.stock = exist[0].stock;
                }
            });
        },
    });
}
const transferorderdetailModule=new TransferOrderDetailModule();
export default transferorderdetailModule;