import Entity from '../entity'
import Customer from '../customers/customer';

export default class Project extends Entity<number>{
    reference: string;
    serie: string;
    number: string;
    branch_id: number;
    customer_id: number;
    responsable_id: number;
    payment_type_id: number;
    current_state_id: number;
    name: string;
    notes: string;
    issue_date: string;
    start_date: string;
    finish_date: string;
    due_date: string;
    valid: boolean;
    planned: boolean;

    customer: Customer;
    customer_name: string;
}


