import Entity from '../entity'
export default class User extends Entity<number>{
    first_name:string;
    last_name:string;
    username:string;
    email:string;
    avatar_type:string;
    avatar_location:string;
    password:string;
    active: boolean;
    bo_menu: number;
    default_tab_id: number;
    area_id: number;
    cashier_id: number;
    branch_id: number;
    printer_id: number;
    roles: Array<any>;
    branches: Array<number>;
    user_branches: Array<any>;

    is_seller: boolean;
    avatar_url: string;
}