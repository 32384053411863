import Entity from '../entity'

export default class Pack extends Entity<number>{
    product_id: number;
    product_attribute_id: number;
    group_id: number;
    item_id: number;
    item_attribute_id: number;
    item_type_id: number;
    currency_id: number;
    code: string;
    item_name: string;
    item_price: number;
    item_unity_id: number;
    item_unit_price: number;
    quantity: number;
    optional: boolean;
    weight: number;

    name: string;
    group_nro: number;
    group: any;
    key: string;
    reference: string;
    unity_sym: string;
    currency_sign: string;
}


