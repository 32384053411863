import Entity from '../entity'

export default class ProductFeature extends Entity<number> {
    product_id: number;
    feature_value_id: number;
    feature_id: number;
    include: boolean;
    position: number;
    name: string;

    value: string;
}

