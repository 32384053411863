import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Activity from '../../entities/production/activity'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'

interface ActivityState extends ListState<Activity>{
    editModel:Activity;
    activeList: Array<Activity>;
}
class ActivityModule extends ListModule<ActivityState,any,Activity>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Activity>(),
        loading:false,
        editModel:new Activity(),
        activeList: new Array<Activity>(),
        path: 'activities',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<ActivityState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let item = response.data.result;
            context.state.activeList.push(response.data.result);
            return item;
        },
        async get(context: ActionContext<ActivityState, any>, payload: any) {
            if (payload.id == undefined) return {};
            let existing = context.state.list.filter((item: any) => {
                return item.id == payload.id;
            });

            if (existing.length > 0) return existing[0];
            
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.list.push(reponse.data.result);
            context.state.loading = false;
            return reponse.data.result;
        },
        async getLastCode(context: ActionContext<ActivityState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/activities/last-code', { params: payload.data}).then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ActivityState, model: Activity) {
            state.editModel = Object.assign({});
            if (model != null && model != undefined)
                state.editModel = model;

            state.editModel.code = '';
            state.editModel.duration_type = 'H';
            state.editModel.time_estimated = 0;
            state.editModel.process_id = null;
        },
    });
}
const activityModule=new ActivityModule();
export default activityModule;