import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import BankAccount from '../../entities/cashiers/bankaccount'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface BankAccountState extends ListState<BankAccount>{
    editModel:BankAccount;    
}
class BankAccountModule extends ListModule<BankAccountState,any,BankAccount>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<BankAccount>(),
        loading:false,
        editModel: new BankAccount(),
        activeList: new Array<BankAccount>(),
        path: 'bank-accounts'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations        
    });
}
const bankaccountModule=new BankAccountModule();
export default bankaccountModule;