import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Supplier from '../../entities/purchases/supplier'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Address from '../../entities/customers/address'

interface SupplierState extends ListState<Supplier>{
    editModel: Supplier;
    reportList: Array<any>;
    filename: string;
}
class SupplierModule extends ListModule<SupplierState, any, Supplier>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Supplier>(),
        reportList: new Array<any>(),
        loading:false,
        editModel:new Supplier(),
        activeList: new Array<Supplier>(),
        filename: '',
        path: 'suppliers'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastCode(context: ActionContext<SupplierState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/suppliers/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        }, 
        async updateBatch(context: ActionContext<SupplierState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/suppliers/batch', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async importData(context: ActionContext<SupplierState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/suppliers/import',
                payload.data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList = response.data.result;
            return true;
        },
        async reportSupplierQuery(context: ActionContext<SupplierState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/supplier-query', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            if (payload.data.type=='r')
                context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: SupplierState) {
            state.editModel = Object.assign({});
            state.editModel.address = Object.assign({});
            state.editModel.code = '0000000';
            state.editModel.document_type_id = 2;
            state.editModel.origin_id = Util.abp.setting.get('DEFAULT_SUPPLIER_ORIGIN');
            state.editModel.type_id = Util.abp.setting.get('DEFAULT_SUPPLIER_TYPE');

            state.editModel.address = new Address();
            state.editModel.address.id = 0;
            state.editModel.address.country_id = Util.abp.setting.get('DEFAULT_COUNTRY');
            state.editModel.address.ubigeo = {
                department: 4,
                province: 35,
                district: 330
            };
            state.editModel.address.department_id = 4;
            state.editModel.address.province_id = 35;
            state.editModel.address.district_id = 330;
            state.editModel.address.address1 = '';
            state.editModel.address.other = '';
        },
        setReportList(state: SupplierState, list: Array<any>) {
            state.reportList = list;
        },
    });
}
const supplierModule=new SupplierModule();
export default supplierModule;