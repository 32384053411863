import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import District from '../../entities/preferences/district'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface DistrictState extends ListState<District>{
    editModel:District;
    activeList: Array<District>;
}
class DistrictModule extends ListModule<DistrictState,any,District>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<District>(),
        loading:false,
        editModel: new District(),
        activeList: new Array<District>(),
        path: 'districts'  
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setActiveList(state: DistrictState, list: Array<District>) {
            if (list != null) {
                state.activeList = list;
            }
        }
    });
}
const districtModule=new DistrictModule();
export default districtModule;