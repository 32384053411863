import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import AttributeGroup from '../../entities/catalogs/attributegroup'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface AttributeGroupState extends ListState<AttributeGroup>{
    editModel: AttributeGroup;
}
class AttributeGroupModule extends ListModule<AttributeGroupState, any, AttributeGroup>{
    state={
        totalCount:0, 
        currentPage:1,
        pageSize: 10,
        list: new Array<AttributeGroup>(),
        loading:false,
        editModel:new AttributeGroup(),
        activeList: new Array<AttributeGroup>(),
        path: 'attribute-groups'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        saveAttribute(state: AttributeGroupState, model: any) {
            if (state.activeList != null) {
                state.activeList.forEach(group => {
                    if (group.id == model.attribute_group_id) {
                        group.attributes = model.list;

                        //console.log(group);
                        //let attr = group.attributes.filter(attr => { return attr.id == model.id });
                        //if (attr.length > 0) {
                        //    attr[0].reference = model.reference;
                        //    attr[0].name = model.name;
                        //} else {
                        //    group.attributes.push(model);
                        //}                        
                    }
                });
            }
        },
    });
}
const attributegroupModule=new AttributeGroupModule();
export default attributegroupModule;