import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Province from '../../entities/preferences/province'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ProvinceState extends ListState<Province>{
    editModel:Province;
    activeList: Array<Province>;
}
class ProvinceModule extends ListModule<ProvinceState,any,Province>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Province>(),
        loading:false,
        editModel:new Province(),
        activeList: new Array<Province>(),
        path: 'provinces'  
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setActiveList(state: ProvinceState, list: Array<Province>) {
            if (list != null) {
                state.activeList = list;
            }
        }
    });
}
const provinceModule=new ProvinceModule();
export default provinceModule;