import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Gender from '../../entities/customers/gender'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface GenderState extends ListState<Gender>{
    editModel:Gender;
    
}
class GenderModule extends ListModule<GenderState,any,Gender>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Gender>(),
        loading:false,
        editModel: new Gender(),
        activeList: new Array<Gender>(),
        path: 'genders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const genderModule=new GenderModule();
export default genderModule;