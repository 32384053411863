import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Manufacturer from '../../entities/catalogs/manufacturer'
import ManufacturerModel from '@/store/entities/catalogs/manufacturermodel';
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ManufacturerState extends ListState<Manufacturer>{
    editModel: Manufacturer;
    activeList: Array<Manufacturer>;
    vehicleList: Array<Manufacturer>;
    productList: Array<Manufacturer>;
    modelsList: Array<ManufacturerModel>;
    type: any;
    path: string;
}
class ManufacturerModule extends ListModule<ManufacturerState, any, Manufacturer>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Manufacturer>(),
        loading:false,
        editModel:new Manufacturer(),
        activeList: new Array<Manufacturer>(),
        vehicleList: new Array<Manufacturer>(),
        productList: new Array<Manufacturer>(),
        modelsList: new Array<ManufacturerModel>(),
        type: null,
        path: 'manufacturers'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<ManufacturerState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + context.state.path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (response.data.result && context.state.activeList != undefined) {
                context.state.activeList.push(response.data.result);
                let veh = Util.abp.list.get('T_MANUF', 'V');
                let pro = Util.abp.list.get('T_MANUF', 'P');
                if (veh != null && response.data.result.type_id == veh.id)
                    context.state.vehicleList.push(response.data.result);
                if (pro != null && response.data.result.type_id == pro.id)
                    context.state.productList.push(response.data.result);
            }
            return response.data.result;
        },
        async getAllActive(context: ActionContext<ManufacturerState, any>, payload: any) {
            if (Util.abp.setting.get('ENABLE_MANUFACTURER') != 1) return true;
            if (context.state.activeList.length > 0) return true;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: { perPage: -1 } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.activeList = reponse.data.result;
            let veh = Util.abp.list.get('T_MANUF', 'V');
            let pro = Util.abp.list.get('T_MANUF', 'P');
            if (veh != null) {
                context.state.vehicleList = context.state.activeList.filter((item: any) => {
                    return item.type_id === veh.id;
                });
            }
            if (pro != null) {
                context.state.productList = context.state.activeList.filter((item: any) => {
                    return item.type_id === pro.id;
                });
            }
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ManufacturerState, type: any) {
            state.type = type;
            state.editModel = Object.assign({});
            state.editModel.type_id = (state.type != null ? state.type.id : Util.abp.list.get('T_MANUF', 'P'));
        },
    });
}
const manufacturerModule=new ManufacturerModule();
export default manufacturerModule;