import Entity from '../entity'

export default class Group extends Entity<number>{
    name: string;
    reduction: number;
    price_rule_id: number;
    active: boolean;
    req_approval: boolean;
}


