import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Product from '../../entities/catalogs/product'
import ProductField from '../../entities/catalogs/productfield'
import PageResult from '@/store/entities/page-result';
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ProductState extends ListState<Product>{
    editModel:Product;
    images: Array<any>;
    loadingRelated: boolean;
    uploading: boolean;
    //warehouse: number;
    related: Array<any>;
    reportList: Array<any>;
    reportList2: Array<any>;
    reportList3: Array<any>;
    reportList4: Array<any>;
    reportList5: Array<any>;
    products: Array<any>;
    stats: Array<any>;
    fields: Array<any>;
    filename: string;
    activeList: Array<Product>;
}
class ProductModule extends ListModule<ProductState,any,Product>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Product>(),
        loading: false,
        uploading: false,
        loadingRelated:false,
        editModel:new Product(),
        activeList: new Array<Product>(),
        reportList: new Array<any>(),
        reportList2: new Array<any>(),
        reportList3: new Array<any>(),
        reportList4: new Array<any>(),
        reportList5: new Array<any>(),
        products: new Array<any>(),
        stats: new Array<any>(),
        fields: new Array<any>(),
        filename: '',
        related: new Array<any>(),
        //warehouse: null,
        images: new Array<any>(),
        path: 'products',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<ProductState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/products', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Product>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }
            context.state.list.forEach(a => {
                if (a.images != null && a.images.length > 0) {
                    a.url = a.images[0].url;
                    a.url_icon = (a.images[0].url_icon != null ? a.images[0].url_icon : a.images[0].url);
                    a.url_catalog = (a.images[0].url_catalog != null ? a.images[0].url_catalog : a.images[0].url);
                    a.url_box = (a.images[0].url_box != null ? a.images[0].url_box : a.images[0].url);
                    a.url_preview = (a.images[0].url_preview != null ? a.images[0].url_preview : a.images[0].url);
                }
            })

            return context.state.list;
        },
        async create(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let item = Util.extend(true, {}, response.data.result); 
            if (response.data.result && context.state.activeList != undefined) {
                item.product_name = item.name;
                item.name = item.code + ' - ' + item.name
                context.state.activeList.push(item);
            }
            return response.data.result;
        },
        async getInfo(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/products/get-info/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async uploadImage(context: ActionContext<ProductState, any>, payload: any) {
            context.state.uploading = true;
            let response = await Ajax.post('/api/products/upload-image', payload.data).catch(e => {
                context.state.uploading = false;
                return Promise.reject(e);
            });
            context.state.uploading = false;
            context.state.images = response.data.result;
        },
        async deleteImage(context: ActionContext<ProductState, any>, payload: any) {
            context.state.uploading = true;
            let response = await Ajax.delete('/api/products/delete-image/' + payload.data.id).catch(e => {
                context.state.uploading = false;
                return Promise.reject(e);
            });
            context.state.uploading = false;
            context.state.images = context.state.images.filter((item: any) => {
                return item.id !== payload.data.id;
            });
        },
        async getLastCode(context: ActionContext<ProductState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/products/last-code', { params: payload.data}).then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
        async getRelated(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loadingRelated = true;
            let reponse = await Ajax.get('/api/products-related', { params: { product: context.state.editModel.id} }).catch(e => {
                context.state.loadingRelated = false;
                return Promise.reject(e);
            });
            context.state.loadingRelated = false;
            context.state.related = reponse.data.result;
            return reponse.data.result;
        },
        async getFactoryProducts(context: ActionContext<ProductState, any>, payload: any) {
            if (context.state.products != null && context.state.products.length > 0) return context.state.products;
            context.state.loading = true;
            let product_type = Util.abp.setting.get('DEFAULT_PRODUCTION_TYPE');
            let pack_type = Util.abp.setting.get('DEFAULT_LDM_TYPE');
            let reponse = await Ajax.get('/api/products', { params: { product_type: product_type, pack_type: pack_type, perPage:-1 } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.products = reponse.data.result;
            return reponse.data.result;
        },
        async createRelated(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loadingRelated = true;
            let response = await Ajax.post('/api/products-related', payload.data).catch(e => {
                context.state.loadingRelated = false;
                return Promise.reject(e);
            });
            context.state.loadingRelated = false;
            context.state.related = response.data.result;
        },
        async deleteRelated(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loadingRelated = true;
            let response = await Ajax.post('/api/products-related-delete', payload.data).catch(e => {
                context.state.loadingRelated = false;
                return Promise.reject(e);
            });
            context.state.loadingRelated = false;
            context.state.related = response.data.result;
        },
        async updateState(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/products/state/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async updateMinimals(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/products-minimals', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;

        },
        async getPackItems(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/product-pack-items', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.loading = false;
            return response.data.result;
        },
        async getFoodFields(context: ActionContext<ProductState, any>, payload: any) {
            if (context.state.fields[payload.data.product_id] != undefined) return context.state.fields[payload.data.product_id];
            context.state.loading = true;
            let response = await Ajax.get('/api/product-foods', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.fields[payload.data.product_id] = response.data.result;
            context.state.loading = false;
            return response.data.result;
        },
        async updateBatch(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/products/batch', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async importData(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/products/import',
                payload.data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList = response.data.result;
            return true;
        },
        async reportProductQuery(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/product-query', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            if (payload.data.type == 'r')
                context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportProductABC(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/products-abc', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.stats = reponse.data.result.stats;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportProductDiscounts(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/product-discounts', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportProductCatalog(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let data = { params: payload.data };
            if (payload.data.export) {
                data['responseType'] = 'blob';
            } else {
                context.state.reportList = [];
            }
            let response = await Ajax.get('/api/reports/product-catalog', data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (payload.data.export) {
                const link = document.createElement('a');
                const url = window.URL.createObjectURL(new Blob([response.data]));
                link.href = url;
                link.setAttribute('download', 'Catalogo-Productos.pdf'); 
                document.body.appendChild(link);
                link.click();
            } else {
                context.state.reportList = response.data.result;
            }
        },
        async reportProductDiscountCatalog(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let data = { params: payload.data };
            if (payload.data.export) {
                data['responseType'] = 'blob';
            } else {
                context.state.reportList = [];
            }
            let response = await Ajax.get('/api/reports/product-discount-catalog', data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (payload.data.export) {
                const link = document.createElement('a');
                const url = window.URL.createObjectURL(new Blob([response.data]));
                link.href = url;
                link.setAttribute('download', 'Catalogo-Promociones.pdf');
                document.body.appendChild(link);
                link.click();
            } else {
                context.state.reportList = response.data.result;
            }
        },
        async reportProductsWithoutImages(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/reports/product-monitoring', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList = response.data.result;
        },
        async reportProductsWithoutPrice(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/reports/product-monitoring', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList2 = response.data.result;
        },
        async reportProductsInactive(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/reports/product-monitoring', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList3 = response.data.result;
        },
        async reportCombinationsWithoutImages(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/reports/product-monitoring', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList4 = response.data.result;
        },
        async reportCombinationsWithoutStock(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/reports/product-monitoring', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList5 = response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ProductState, model: Product) {
            state.editModel = Object.assign({});
            if (model != null && model != undefined)
                state.editModel = model;

            state.images = [];
            state.editModel.service_type = null;
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.line_id = null; //Util.abp.setting.get('DEFAULT_LINE');
            state.editModel.category_id = Util.abp.setting.get('DEFAULT_CATEGORY');
            state.editModel.available_id = Util.abp.list.get('T_AVAIL', 'B').id;            
            state.editModel.manufacturer_id = null;
            state.editModel.supplier_id = null;
            state.editModel.unity_id = Util.abp.setting.get('DEFAULT_UNITY');
            state.editModel.unity_conver_id = Util.abp.setting.get('DEFAULT_UNITY');
            state.editModel.tax_id = Util.abp.setting.get('IGV_TAXES');
            state.editModel.price = 0;
            state.editModel.price_wt = 0;
            state.editModel.cost = 0;
            state.editModel.profit = Util.abp.setting.get('PRODUCT_PROFIT');
            state.editModel.conversion = 1;
            state.editModel.code = '';
            state.editModel.fields = new ProductField();
            state.editModel.fields.duration_type = 'D';
            state.editModel.fields.allow_children = false;
            state.editModel.fields.frecuency_days = [];
            state.editModel.fields.frecuency_hours = [];
            state.editModel.fields.room_prices = [];
            state.editModel.fields.categories = [];
            state.editModel.categories = [];

            if (state.editModel.product_type_id == null)
                state.editModel.product_type_id = Util.abp.setting.get('DEFAULT_PRODUCT_TYPE');
        },
        edit(state: ProductState, model: Product) {
            state.images = [];
            state.editModel = model;
            if (state.editModel.fields == null) {
                state.editModel.fields = new ProductField();
                state.editModel.fields.duration_type = 'D';
                state.editModel.fields.allow_children = false;
                state.editModel.fields.frecuency_days = [];
                state.editModel.fields.frecuency_hours = [];
                state.editModel.fields.room_prices = [];
                state.editModel.fields.categories = [];
            } else {
                if (state.editModel.fields.room_prices == null) {
                    state.editModel.fields.room_prices = [];
                }
            }
            state.editModel.categories = state.editModel.fields.categories;
        },
        setProductType(state: ProductState, id: number) {
            state.editModel.product_type_id = id;
        },
        setImagesList(state: ProductState, images: Array<any>) {
            state.images = images;
        },
        setReportList(state: ProductState, list: Array<any>) {
            state.reportList = list;
        },
        setProductName(state: ProductState, name: string) {
            state.editModel.name = name;
        },
    });
}
const productModule=new ProductModule();
export default productModule;