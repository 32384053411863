import Entity from '../entity'

export default class ProductCompatibility extends Entity<number> {
    product_id: number;
    vehicle_id: number;
    version: string;
    year_start: number;
    year_end: number;
}


