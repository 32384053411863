import Entity from '../entity'

export default class Line extends Entity<number>{
    product_types: Array<any>;
    name: string;
    code: number;
    active: boolean;
    public_name: string;
    public_visibility: boolean;

    image_url: string;
    imageData: string;
}


