import Entity from '../entity'

export default class Location extends Entity<number>{
    code: string;
    name: string;
    type_id: number;
    warehouse_id: number;
    area_id: number;
    process_id: number;
    active: boolean;
    shelfs: [];
}


