import Entity from '../entity'

export default class SunatVoided extends Entity<number>{
    document: string;
    reason: string;
    sign: string;
    sign_value: string;
    response: string;
    nro_ticket: string;
    filename: string;
    invoiced: boolean;
    serie: string;
    number_start: number;
    number_end: number;
    date_process: string;
}


