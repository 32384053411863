import Entity from '../entity'

export default class Goal extends Entity<number>{
    code: string;
    name: string;
    year: number;
    month: number;
    amount: number;
}


