import Entity from '../entity'

export default class IncomeOutputConcept extends Entity<number>{
    code: string;
    name: string;
    type: string;
    description: string;
    invoice: boolean;
    print: boolean;
    active: boolean;
}

