import Entity from '../entity'
import Customer from '../customers/customer';
import RepairOrderProduct from './repairorderproduct';
import RepairOrderDocument from './repairorderdocument';
import RepairOrderPlan from './repairorderplan';
import Product from '../catalogs/product';

export default class RepairOrder extends Entity<number>{
    reference: string;
    serie: string;
    number: string;
    user_type_id: number;
    employee_id: number;
    supplier_id: number;
    customer_id: number;
    workshop_id: number;
    branch_id: number;
    warehouse_id: number;
    currency_id: number;
    repair_type_id: number;
    service_type_id: number;
    responsable_id: number;
    product_id: number;
    vehicle_id: number;
    sale_quotation_id: number;
    current_state_id: number;
    priority: number;
    description: string;
    progress: number;
    total_work_hours: number;
    total_work_cost: number;
    total_products: number;
    total_purchases: number;
    total: number;
    total_paid: number;
    balance: number;
    mileage: number;
    mileage_last: number;
    mileage_next: number;
    product_manufacturer: string;
    product_color: string;
    product_serie: string;
    admission_date: string;
    start_date: string;
    finish_date: string;
    delivery_date: string;
    estimated_date: string;
    notes: string;
    details: string;
    valid: boolean;
    assigned: boolean;
    requested_assign: boolean;
    require_purchase: boolean;
    waiting_stocks: boolean;
    invoiced: boolean;
    in_process: boolean;
    finished: boolean;
    quotation_nro: string;

    product: Product;
    vehicle: any;
    supplier: any;
    customer: Customer;
    customer_name: string;
    product_name: string;
    nro: string;
    delivery_orders: Array<any>;

    products: Array<RepairOrderProduct>;
    documents: Array<RepairOrderDocument>;
    planning: Array<RepairOrderPlan>;
}


