import Entity from '../entity'

export default class Country extends Entity<number>{
    iso_code: string;
    call_prefix: number;
    name: string;
    need_zip_code: boolean;
    active: boolean;
    zone_id: number;
    currency_id: number;
}


