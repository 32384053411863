import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import BookingGuest from '../../entities/bookings/bookingguest'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Booking from '@/store/entities/bookings/booking';

interface BookingGuestState extends ListState<BookingGuest>{
    editModel:BookingGuest;    
    guests: Array<any>; 
    loadingGuest: boolean;
    booking: number;    
}
class BookingGuestModule extends ListModule<BookingGuestState,any,BookingGuest>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<BookingGuest>(),
        loading:false,
        editModel: new BookingGuest(),
        guests: [],
        loadingGuest: false,
        booking: 0,
        path: 'booking-guests'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async querySearch(context: ActionContext<BookingGuestState, any>, payload: any) {
            if (payload.data.query == null || payload.data.query == 'null') return [];
            if (context.state.guests != undefined && context.state.guests.length > 0) {
                let result = context.state.guests.filter((item: any) => {
                    return item != null && (item[payload.data.filter].toUpperCase().indexOf(payload.data.query.toUpperCase()) >= 0);
                });

                if (result.length > 0)
                    return result;
            }
            payload.data.guest = 1;
            context.state.loadingGuest = true;
            let reponse = await Ajax.get('/api/customers/query-search', { params: payload.data }).catch(e => {
                context.state.loadingGuest = false;
                return Promise.reject(e);
            });
            context.state.guests = reponse.data.result;
            context.state.loadingGuest = false;
            return reponse.data.result;
        }
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: BookingGuestState) {
            let date = new Date();
            state.editModel = Object.assign({});
            state.editModel.default = false;
            state.editModel.customer_id = null;
            state.editModel.customer = null;
            state.editModel.seq = 1;
        },   
        init(state: BookingGuestState, model: Booking) {
            state.editModel = Object.assign({});
            state.booking = 0;
            state.list = [];
            if (model != undefined) {
                state.booking = model.id;
                if (model.guests != null)
                    state.list = model.guests;
            }
            state.totalCount = state.list.length;
        },
        add(state: BookingGuestState, guest: BookingGuest) {
            let exist = [];

            if (guest.customer != null && guest.customer.id != null) {
                exist = state.list.filter((detail: any) => {
                    return detail.customer_id === guest.customer.id;
                });
            }

            if (exist.length == 0) {
                guest.customer_id = guest.customer.id;
                guest.default = false;
                guest.guest_name = guest.customer.guest_name;
                guest.seq = state.list.length + 1;
                if (state.list.length == 0) {
                    guest.default = true;
                }

                state.list.push(guest);
                state.totalCount = state.list.length;
            }            
        },
        delete(state: BookingGuestState, index: number) {
            state.list.splice(index, 1);
            state.totalCount = state.list.length;
        },
    });
}
const bookingguestModule=new BookingGuestModule();
export default bookingguestModule;