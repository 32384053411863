import Entity from '../entity'
import Tax from '../preferences/tax';

export default class SaleItem extends Entity<number>{
    sale_id: number;
    tax_id: number;
    tax_rate: number;
    number: number;
    exchange_rate: number;
    product_name: string;
    quantity: number;
    price: number;
    price_wt: number;
    taxes: number;
    total: number;
    total_wt: number;
    quantity_returned: number;
    credit_node_detail_id: number;

    total_returned: number;
    tax: Tax;
}


