import Entity from '../entity'

export default class CostCenter extends Entity<number>{
    branch_id : number;
    center_type_id : number;
    parent_id : number;
    name: string;
    code: string;
    level_depth: number;
    active: boolean;
}


