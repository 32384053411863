import Entity from '../entity'
import MeasureUnit from '../catalogs/measureunit';
import Product from '../catalogs/product';

export default class PurchaseQuotationDetail extends Entity<number>{
    purchase_quotation_id : number;
    purchase_quotation_supplier_id : number;
    product_id : number;
    product_attribute_id : number; 
    unity_id : number;
    product_name: string;
    version: string;
    upc: string;
    code: string;
    reference: string;
    quantity: number;
    discount_type: string;
    cost: number;
    cost_wt: number;
    taxes: number;
    discounts: number;
    total: number;
    total_wt: number;
    product_cost: number;
    stock: number;
    service: boolean;

    unity: MeasureUnit;
    product: Product;
    unity_sym: string;
}


