import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import FixedAsset from '../../entities/logistics/fixedasset'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface FixedAssetState extends ListState<FixedAsset>{
    editModel: FixedAsset;
    activeList: Array<FixedAsset>;
    product: number;
    action: string;
}
class FixedAssetModule extends ListModule<FixedAssetState, any, FixedAsset>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<FixedAsset>(),
        loading:false,
        editModel: new FixedAsset(),
        product: 0,
        action: '',
        activeList: new Array<FixedAsset>(),
        path: 'fixed-assets'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastCode(context: ActionContext<FixedAssetState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/fixed-assets/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: FixedAssetState) {
            state.editModel = Object.assign({});
            state.editModel.code = '';
            state.editModel.name = '';
            state.editModel.depreciation_type_id = Util.abp.list.get('T_DEPRES', 'C').id;
            state.editModel.depreciation_method_id = Util.abp.list.get('M_DEPRES', 'LR').id;
            state.editModel.asset_class_id = Util.abp.list.get('C_ASSET', 'F').id;
            state.editModel.useful_life_unit = 'A';
            state.editModel.useful_life = 1;
            state.action = 'new';
        },
        edit(state: FixedAssetState, model: FixedAsset) {
            state.editModel = model;
            state.action = 'edit';
        },
        setProduct(state: FixedAssetState, product: number) {
            state.product = product;
        },
        setAction(state: FixedAssetState, action: string) {
            state.action = action;
        },
    });
}
const fixedassetModule=new FixedAssetModule();
export default fixedassetModule;