import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Datatable from '../../entities/preferences/datatable'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface DatatableState extends ListState<Datatable>{
    editModel:Datatable;    
    conntected: Array<any>;    
}
class DatatableModule extends ListModule<DatatableState,any,Datatable>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Datatable>(),
        loading:false,
        editModel: new Datatable(),
        path: 'datatables'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const datatableModule=new DatatableModule();
export default datatableModule;