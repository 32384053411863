import Entity from '../entity'

export default class ProductSerie extends Entity<number>{
    product_id: number;
    batch_id: number;
    number: number;
    location_id: number;
    assigned_to_id: number;
    model: string;
    serial: string;
    code2: string;
    code3: string;
    state_id: number;
}


