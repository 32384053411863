import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import InventoryDetail from '../../entities/stocks/inventorydetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import Inventory from '@/store/entities/stocks/inventory';

interface InventoryDetailState extends ListState<InventoryDetail>{
    editModel:InventoryDetail;
    products: Array<any>;
    inventory:number;
}
class InventoryDetailModule extends ListModule<InventoryDetailState,any,InventoryDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<InventoryDetail>(),
        products: new Array<any>(),
        loading:false,
        editModel: new InventoryDetail(),
        inventory: 0,
        path: 'inventories'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<InventoryDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: InventoryDetailState, model: InventoryDetail) {
            state.editModel = model;
        },
        init(state: InventoryDetailState, model: Inventory) {
            state.editModel = Object.assign({});
            state.list = [];
            if (model != undefined) {
                state.inventory = model.id;
                state.list = model.details;
            }
        },
        add(state: InventoryDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return (detail.product_id === product.id || detail.product_id === product['product_id']) && detail.product_attribute_id == product['product_attribute_id'];
            });

            if (exist.length > 0) {
                exist[0].quantity = product.quantity;
            } else {
                let detail = new InventoryDetail();
                detail.inventory_id = state.editModel.id;
                detail.code = product.code;
                detail.product_id = product.id;
                detail.product_attribute_id = product['product_attribute_id'];
                detail.unity_id = product.unity_id;
                detail.unity_sym = product.unity_symbol;
                detail.product_name = Util.formatProdName(product);
                detail.stock = product.stock;
                detail.quantity = product.quantity;
                
                state.list.push(detail);
            }
        },
        update(state: InventoryDetailState, detail: InventoryDetail) {
            detail.quantity = parseInt(String(detail.quantity));
        },
        updateList(state: InventoryDetailState, data: any) {
            state.list[data.index].stock = data.detail.stock;
            state.list[data.index].quantity = data.detail.quantity;
        },
        delete(state: InventoryDetailState, item: any) {
            let idx = -1;
            state.list.forEach((detail, i) => {
                if (detail.product_id === item.product_id && detail.product_attribute_id == item.product_attribute_id)
                    idx = i;
            });

            state.products.forEach((detail, i) => {
                if (detail.product_id === item.product_id && detail.product_attribute_id == item.product_attribute_id)
                    detail.lista = '2. Busqueda';
            });

            if (idx >= 0) state.list.splice(idx, 1);
        },
        setProducts(state: InventoryDetailState, list: Array<any>) {
            state.products = [];

            state.list.forEach(item => {
                item['lista'] = '1. Productos';
                state.products.push(item);
            });

            list.forEach(item => {
                let exist = state.list.filter((detail: any) => {
                    return detail.product_id === item.id && detail.product_attribute_id == item['product_attribute_id'];
                });

                item.quantity = item.stock;
                item.lista = '2. Busqueda';

                if (exist.length == 0) {
                    state.products.push(item);
                }                 
            });
        },
        updateStocks(state: InventoryDetailState, products: any) {
            state.list.forEach(item => {
                let exist = products.filter((product: any) => {
                    return item.product_id === product.id && item.product_attribute_id == product['product_attribute_id'];
                });

                if (exist.length > 0) {
                    item.stock = exist[0].stock;
                }
            });
        },
    });
}
const inventorydetailModule=new InventoryDetailModule();
export default inventorydetailModule;