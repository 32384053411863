import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import FoodOrderProduct from '../../entities/foods/foodorderproduct'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import FoodOrder from '@/store/entities/foods/foodorder';

interface FoodOrderProductState extends ListState<FoodOrderProduct>{
    editModel:FoodOrderProduct;    
    order:number;
}
class FoodOrderProductModule extends ListModule<FoodOrderProductState,any,FoodOrderProduct>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<FoodOrderProduct>(),
        loading:false,
        editModel: new FoodOrderProduct(),
        activeList: new Array<FoodOrderProduct>(),
        path: 'food-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<FoodOrderProductState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: FoodOrderProductState, model: FoodOrderProduct) {
            state.editModel = model;
        },
        init(state: FoodOrderProductState, model: FoodOrder) {
            state.editModel = Object.assign({});

            if (model != undefined) {
                state.order = model.id;
                state.list = model.products;
            }
        },
        add(state: FoodOrderProductState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id && detail.product_attribute_id == product['product_attribute_id'];
            });

            if (exist.length > 0) {
                exist[0].quantity += product.quantity;
                exist[0].total = exist[0].quantity * exist[0].price;
                exist[0].total_wt = exist[0].quantity * exist[0].price_wt
                exist[0].taxes = parseFloat((exist[0].total_wt - exist[0].total).toFixed(3));
            } else {

                let detail = new FoodOrderProduct();
                detail.food_order_id = state.editModel.id;
                detail.product = product;
                detail.product_id = product.id;
                detail.product_attribute_id = product['product_attribute_id'];
                detail.warehouse_id = (product['warehouse_id'] != undefined ? product['warehouse_id'] : Util.abp.session.warehouseId);
                detail.unity_id = product.unity_id;
                detail.unity_sym = product.unity_symbol;
                detail.price_rule_id = product.price_rule_id;
                detail.code = product.code;
                detail.product_name = Util.formatProdName(product);
                detail.stock = product.stock;
                detail.quantity = product.quantity;
                detail.price = Util.removeTaxes(product.price_wt);
                detail.price_wt = product.price_wt;
                detail.total = parseFloat((detail.quantity * detail.price).toFixed(3));
                detail.total_wt = parseFloat((detail.quantity * detail.price_wt).toFixed(3));
                Util.updateTotalDetails(detail);
                detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(3));
                detail.product_price = product['product_price'];

                state.list.push(detail);
            }
        },
        update(state: FoodOrderProductState, detail: FoodOrderProduct) {
            detail.quantity = parseInt(String(detail.quantity));
            detail.price = Util.removeTaxes(detail.price_wt);
            detail.total = detail.quantity * detail.price;
            detail.total_wt = (detail.quantity * detail.price_wt);

            detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(3));
            detail.total_wt = parseFloat(detail.total_wt.toFixed(6));
        },
        updateList(state: FoodOrderProductState, data: any) {
            state.list[data.index].warehouse_id = data.detail.warehouse_id;
            state.list[data.index].price_rule_id = data.detail.price_rule_id;
            state.list[data.index].quantity = data.detail.quantity;
            state.list[data.index].price = data.detail.price;
            state.list[data.index].price_wt = data.detail.price_wt;
            state.list[data.index].taxes = data.detail.taxes;
            state.list[data.index].total = data.detail.total;
            state.list[data.index].total_wt = data.detail.total_wt;
        },
        delete(state: FoodOrderProductState, index: number) {
            state.list.splice(index, 1);
        },
    });
}
const foodorderproductModule=new FoodOrderProductModule();
export default foodorderproductModule;