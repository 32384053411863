import Entity from '../entity'

export default class AssignmentDetail extends Entity<number>{
    assignment_id: number;
    product_id: number;
    fixed_asset_id: number;
    code: string;
    product_name: string;
    serial: string;
}


