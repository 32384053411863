import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Notification from '../../entities/preferences/notification'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface NotificationState extends ListState<Notification>{
    editModel: Notification;
}
class NotificationModule extends ListModule<NotificationState, any, Notification>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Notification>(),
        //noticeList: [{ readed: false, type: 0, title: 'First notice', description: 'One day ago', icon: 'schedule', time: '2021-06-09 14:00:00' }, { readed: false, type: 1 }, { readed: false, type: 0, title: 'Second notice', description: 'One month ago' }],
        loading:false,
        editModel:new Notification(),
        path: 'notifications'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async update(context: ActionContext<NotificationState, any>, payload: any) {
            await Ajax.put('/api/notifications/' + payload.data.id, payload.data);            
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        initNotify(state: NotificationState, obj: any) {
            if (localStorage.noticeList != null) {
                state.list = JSON.parse(localStorage.noticeList);
            }
        },
        addNotify(state: NotificationState, obj: any) {
            state.list.unshift(obj);
            localStorage.noticeList = JSON.stringify(state.list);
        },
        readNotify(state: NotificationState, index: number) {
            state.list[index].readed = true;
            localStorage.noticeList = JSON.stringify(state.list);
        },
    });
}
const notificationModule=new NotificationModule();
export default notificationModule;