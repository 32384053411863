import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Tax from '../../entities/preferences/tax'
import Util from '../../../lib/util'

interface TaxState extends ListState<Tax>{
    editModel:Tax;
    activeList: Array<Tax>;
}
class TaxModule extends ListModule<TaxState,any,Tax>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Tax>(),
        loading:false,
        editModel: new Tax(),
        activeList: new Array<Tax>(),
        path: 'taxes'  
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: TaxState, model: Tax) {
            state.editModel = Object.assign({});
            state.editModel.country_id = Util.abp.setting.get('DEFAULT_COUNTRY');
            state.editModel.behavior_id = Util.abp.list.get('T_TAXES', 'T1').id;
            state.editModel.rate = 0;
            state.editModel.code = '';
            state.editModel.name = '';            
        },
    });
}
const taxModule=new TaxModule();
export default taxModule;