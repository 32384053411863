import Entity from '../entity'

export default class GeneralTypeValue extends Entity<number>{
    general_type_id: number;
    code: string;
    name: string;
    value: number;
    active: boolean;
}



