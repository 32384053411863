import Entity from '../entity'

export default class PurchasePlanningProduct extends Entity<number>{
    purchase_planning_id: number;
    category_id: number;
    product_id: number;
    product_attribute_id: number;
    number: number;
    unity_id: number;
    supplier_id: number;
    customer_id: number;
    state_id: number;
    product_name: string;
    code: string;
    quantity_suggested: number;
    quantity_out: number;
    quantity_request: number;
    quantity_ordered: number;
    quantity_aditional: number;
    minimal_quantity: number;
    quantity: number;
    stock: number;
    cost: number;
    cost_wt: number;
    taxes: number;
    total: number;
    total_wt: number;
    product_cost: number;
    budget: number;
    quantity_received: number;
    quantity_pending: number;
    service: boolean;
    notes: string;

    unity_sym: string;
    supplier: any;
    supplier_name: string;
    category_name: string;
}


