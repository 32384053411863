import Entity from '../entity'

export default class ProductPrice extends Entity<number> {
    product_id: number;
    product_attribute_id: number;
    price_rule_id: number;
    currency_id: number;
    price: number;
    price_wt: number;
}

