import Entity from '../entity'

export default class Department extends Entity<number>{
    name: string;
    code: string;
    active: boolean;
    country_id: number;
}


