import Entity from '../entity'

export default class ImageType extends Entity<number>{
    name: string;
    prefix: string;
    width: number;
    height: number;
    product: boolean;
    line: boolean;
    category: boolean;
    subcategory: boolean;
}


