import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Budget from '../../entities/cashiers/budget'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface BudgetState extends ListState<Budget>{
    editModel: Budget;
    reportList: Array<any>;    
}
class BudgetModule extends ListModule<BudgetState,any,Budget>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Budget>(),
        loading: false,
        editModel: new Budget(),
        activeList: new Array<Budget>(),
        reportList: new Array<any>(),
        path: 'budgets'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async close(context: ActionContext<BudgetState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/budgets/close', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async reportCostCenterMoves(context: ActionContext<BudgetState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/cost-center-moves', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations 
    });
}
const budgetModule=new BudgetModule();
export default budgetModule;