import Entity from '../entity'
import Tax from '../preferences/tax';

export default class ServiceOrderDetail extends Entity<number>{
    service_order_id: number;
    service_id: number;
    tax_id: number;
    tax_rate: number;
    including_taxes: boolean;
    service_name: string;
    code: string;
    quantity: number;
    cost: number;
    cost_wt: number;
    taxes: number;
    total: number;
    total_wt: number;
    product_cost: number;

    tax: Tax;
}


