import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PayableAccount from '../../entities/cashiers/payableaccount'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';
import PayableAccountDue from '@/store/entities/cashiers/payableaccountdue';

interface PayableAccountState extends ListState<PayableAccount>{
    editModel: PayableAccount;
    reportList: Array<any>;
    dues: Array<PayableAccountDue>;    
}
class PayableAccountModule extends ListModule<PayableAccountState,any,PayableAccount>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PayableAccount>(),
        loading:false,
        editModel: new PayableAccount(),
        activeList: new Array<PayableAccount>(),
        reportList: new Array<any>(),
        dues: new Array<PayableAccountDue>(),
        path: 'payable-accounts'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getPendingAccounts(context: ActionContext<PayableAccountState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/payable-accounts/pending', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<PayableAccount>;
            context.state.totalCount = page.total;
            context.state.list = page.data;
        },
        async getDues(context: ActionContext<PayableAccountState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/payable-accounts/dues', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.dues = reponse.data.result;
            context.state.dues.forEach(item => {
                item.currency = context.state.editModel.currency;
            });
        },
        async buildDues(context: ActionContext<PayableAccountState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/payable-accounts/dues', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.dues = reponse.data.result;
        },
        async updateDue(context: ActionContext<PayableAccountState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/payable-account-due/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async reportPayable(context: ActionContext<PayableAccountState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/payable', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList = reponse.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations 
        view(state: PayableAccountState, model: PayableAccount) {
            state.editModel = model;
            state.editModel.supplier_name = model['supplier'].full_name;
            state.dues = (model.dues != undefined ? model.dues : []);
            state.dues.forEach(item => {
                item.currency = state.editModel.currency;
            });
        },
        setTotals(state: PayableAccountState, model: PayableAccount) {
            state.editModel.paid = model.paid;
            state.editModel.balance = model.balance;
            state.editModel.state_id = model.state_id;
        },
    });
}
const payableaccountModule=new PayableAccountModule();
export default payableaccountModule;