import Entity from '../entity'

export default class ProductLocation extends Entity<number> {
    warehouse_id: number;
    location_id: number;
    shelf_id: number;
    product_id: number;
    product_attribute_id: number;
    warehouse: string;
    location: string;
    position: string;
    stock: number;

    product: any;
}

