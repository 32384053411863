import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import WorkOrderPlan from '../../entities/production/workorderplan'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import WorkOrder from '@/store/entities/production/workorder';

interface WorkOrderPlanState extends ListState<WorkOrderPlan>{
    editModel:WorkOrderPlan;    
    order:number;    
    loadingAction: boolean;   
    hisLoading: boolean;
    reportList: Array<any>;
    history: Array<any>;   
}
class WorkOrderPlanModule extends ListModule<WorkOrderPlanState,any,WorkOrderPlan>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<WorkOrderPlan>(),
        history: new Array<any>(),
        loading:false,
        loadingAction: false,
        hisLoading: false,
        editModel: new WorkOrderPlan(),
        reportList: new Array<any>(),
        order: 0,
        path: 'work-order-planning'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<WorkOrderPlanState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = reponse.data.result.planning.length;
            context.state.list = reponse.data.result.planning;
            return reponse.data.result;
        },
        async create(context: ActionContext<WorkOrderPlanState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list.push(response.data.result);
            return response.data.result;
        },
        async schedule(context: ActionContext<WorkOrderPlanState, any>, payload: any) {
            context.state.loadingAction = true;
            let response = await Ajax.post('/api/' + (context.state as any).path + '/schedule', payload.data).catch(e => {
                context.state.loadingAction = false;
                return Promise.reject(e);
            });
            context.state.loadingAction = false;
            context.state.list.forEach((item, i) => {
                if (item.id == payload.data.id) {
                    context.state.list[i].state_id = response.data.result.state_id;
                    context.state.list[i].position = response.data.result.position;
                }
            });
            return response.data.result;
        },
        async start(context: ActionContext<WorkOrderPlanState, any>, payload: any) {
            context.state.loadingAction = true;
            let response = await Ajax.post('/api/' + (context.state as any).path + '/start', payload.data).catch(e => {
                context.state.loadingAction = false;
                return Promise.reject(e);
            });
            context.state.loadingAction = false;
            context.state.list.forEach((item, i) => {
                if (item.id == payload.data.id) {
                    context.state.list[i].state_id = response.data.result.state_id;
                    context.state.list[i].start_date = response.data.result.start_date;
                    context.state.list[i].last_start_date = response.data.result.last_start_date;
                    context.state.list[i].in_process = response.data.result.in_process;
                }
            });
            return response.data.result;
        },
        async pause(context: ActionContext<WorkOrderPlanState, any>, payload: any) {
            context.state.loadingAction = true;
            let response = await Ajax.post('/api/' + (context.state as any).path + '/pause', payload.data).catch(e => {
                context.state.loadingAction = false;
                return Promise.reject(e);
            });
            context.state.loadingAction = false;
            context.state.list.forEach((item, i) => {
                if (item.id == payload.data.id) {
                    context.state.list[i].in_process = response.data.result.in_process;
                    context.state.list[i].duration = response.data.result.duration;
                }
            });
            return response.data.result;
        },
        async complete(context: ActionContext<WorkOrderPlanState, any>, payload: any) {
            context.state.loadingAction = true;
            let response = await Ajax.post('/api/' + (context.state as any).path + '/complete', payload.data).catch(e => {
                context.state.loadingAction = false;
                return Promise.reject(e);
            });
            context.state.loadingAction = false;
            context.state.list.forEach((item, i) => {
                if (item.id == payload.data.id) {
                    context.state.list[i].in_process = response.data.result.in_process;
                    context.state.list[i].duration = response.data.result.duration;
                    context.state.list[i].state_id = response.data.result.state_id;
                    context.state.list[i].finish_date = response.data.result.finish_date;
                }
            });
            return response.data.result;
        },
        async getHistory(context: ActionContext<WorkOrderPlanState, any>, payload: any) {
            context.state.hisLoading = true;
            let reponse = await Ajax.get('/api/work-order-planning/history', { params: payload.data }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        
        async reportSchedule(context: ActionContext<WorkOrderPlanState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/production-schedule', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: WorkOrderPlanState) {
            let date = new Date();
            state.editModel = Object.assign({});
            state.editModel.duration_type = 'H';
            state.editModel.hour_rate = 0;
            state.editModel.hour_rate_employee = 0;
            state.editModel.hour_rate_equipment = 0;
            state.editModel.estimated_start_date = Util.abp.clock.today();
            state.editModel.state_id = null;
            state.editModel.employee_id = null;
            state.editModel.process_id = null;
            state.editModel.activity_id = null;
            state.editModel.process_name = '';
            state.editModel.activity_name = '';
            state.editModel.equipment_type_id = null;
            state.editModel.equipment_id = null;
            state.editModel.equipment_name = '';
            state.editModel.time_estimated = 0;
            state.editModel.duration_expected = 0;
            state.editModel.duration = 0;
            state.editModel.position = 0;
            state.editModel.process_name = '';
            state.editModel.employee_name = '';
        },        
        edit(state: WorkOrderPlanState, model: WorkOrderPlan) {
            state.editModel = model;
        },
        init(state: WorkOrderPlanState, model: WorkOrder) {
            state.editModel = Object.assign({});
            state.order = 0;
            state.list = [];
            if (model != undefined) {
                state.order = model.id;
                state.list = model.planning;
            }
            state.totalCount = state.list.length;
        },
        add(state: WorkOrderPlanState, plan: WorkOrderPlan) {
            plan.duration_expected = 0;
            plan.duration = 0;
            plan.state_id = Util.abp.list.get('E_PLAN', 'PL').id;

            let hours = Util.abp.setting.get('PRODUCTION_DAY_HOURS');
            if (plan.time_estimated != null)
                plan.duration_expected = (plan.duration_type == 'H' ? plan.time_estimated * 60 :
                                         (plan.duration_type == 'D' ? plan.time_estimated * hours * 60 : plan.time_estimated));

            state.list.push(plan);
            state.totalCount = state.list.length;
        },
        delete(state: WorkOrderPlanState, index: number) {
            state.list.splice(index, 1);
            state.totalCount = state.list.length;
        },
        setHistory(state: WorkOrderPlanState, list: Array<any>) {
            state.history = list;
        },
    });
}
const workorderplanModule=new WorkOrderPlanModule();
export default workorderplanModule;