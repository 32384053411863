import Entity from '../entity'

export default class RepairOrderProduct extends Entity<number>{
    repair_order_id: number;
    product_type_id: number;
    supplier_id: number;
    product_id: number;
    product_attribute_id: number;
    warehouse_id: number;
    unity_id: number;
    price_rule_id: number;
    special_price_rule_id: number;
    product_name: string;
    version: string;
    code: string;
    upc: string;
    quantity: number;
    quantity_request: number;
    quantity_received: number;
    stock_physical: number;
    stock: number;
    discount_type: string;
    price_wt: number;
    taxes: number;
    discounts: number;
    total_wt: number;
    product_price: number;
    product_cost: number;
    service: boolean;

    unity_sym: string;
    product: any;
    special_price_rule: any;
}


