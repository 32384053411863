import Entity from '../entity'
import Currency from '../preferences/currency';
import PayableAccountDue from './payableaccountdue';

export default class PayableAccount extends Entity<number>{
    reference: string;
    supplier_id: number;
    invoice_type_id: number;
    currency_id: number;
    state_id: number;
    account_combined_id: number;
    purchase_id: number;
    invoice_number: string;
    debt: number;
    paid: number;
    debit_note: number;
    balance: number;
    operation_date: string;
    due_date: string;
    last_payment_date: string;
    supplier_name: string;
    charge_interest: boolean;
    charge_extra_fee: boolean;
    nro_dues: number;

    currency: Currency;
    dues: Array<PayableAccountDue>;
}


