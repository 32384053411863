import Entity from '../entity'

export default class StockMvt extends Entity<number>{
    stock_id: number;
    stock_batch_id: number;
    stock_location_id: number;
    entity: string;
    entity_id: number;
    stock_mvt_reason_id: number;
    user_id: number;
    physical_quantity: number;
    sign: number;
    cost: number;
    price: number;
    last_wa: number;
    current_wa: number;
    notes: string;

    warehouse_id: number;
    to_warehouse_id: number;
}


