import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import EntryOrderDetail from '../../entities/stocks/entryorderdetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import EntryOrder from '@/store/entities/stocks/entryorder';

interface EntryOrderDetailState extends ListState<EntryOrderDetail>{
    editModel:EntryOrderDetail;    
    entryorder:number;    
}
class EntryOrderDetailModule extends ListModule<EntryOrderDetailState,any,EntryOrderDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<EntryOrderDetail>(),
        loading:false,
        editModel: new EntryOrderDetail(),
        activeList: new Array<EntryOrderDetail>(),
        entryorder: 0,
        path: 'entry-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<EntryOrderDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: EntryOrderDetailState, model: EntryOrderDetail) {
            state.editModel = model;
        },
        init(state: EntryOrderDetailState, model: EntryOrder) {
            state.editModel = Object.assign({});
            if (model != undefined) {
                state.entryorder = model.id;
                state.list = model.details;
            }
        },
        add(state: EntryOrderDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id && detail.product_attribute_id == product['product_attribute_id'];
            });

            if (exist.length > 0) { 
                exist[0].quantity += product.quantity;
            } else {
                let detail = new EntryOrderDetail();
                detail.entry_order_id = state.editModel.id;
                detail.code = product.code;
                detail.upc = product.upc;
                detail.product_id = product.id;
                detail.product_attribute_id = product['product_attribute_id'];
                detail.warehouse_id = (product['warehouse_id'] != undefined ? product['warehouse_id'] : Util.abp.session.warehouseId);
                detail.unity_id = product.unity_id;
                detail.unity_sym = product.unity_symbol;
                detail.product_name = Util.formatProdName(product);
                detail.version = product.version;
                detail.stock = product.stock;
                detail.quantity = product.quantity;
                detail.cost_wt = parseFloat(parseFloat(String(product.cost_wt)).toFixed(6));
                detail.total_wt = detail.quantity * detail.cost_wt;
                detail.quantity_received = 0;
                detail.quantity_pending = product.quantity - detail.quantity_received;
                detail.product_cost = product.product_cost;
                
                state.list.push(detail);
            }
        },
        updateList(state: EntryOrderDetailState, data: any) {
            state.list[data.index].quantity = data.detail.quantity;
        },
        delete(state: EntryOrderDetailState, index: number) {
            state.list.splice(index, 1);
        },
        updateStocks(state: EntryOrderDetailState, products: any) {
            state.list.forEach(item => {
                let exist = products.filter((product: any) => {
                    return item.product_id === product.id && item.product_attribute_id == product['product_attribute_id'];
                });

                if (exist.length > 0) {
                    item.warehouse_id = (exist[0]['warehouse_id'] != undefined ? exist[0]['warehouse_id'] : Util.abp.session.warehouseId);
                    item.stock = exist[0].stock;
                }
            });
        },
    });
}
const entryorderdetailModule=new EntryOrderDetailModule();
export default entryorderdetailModule;