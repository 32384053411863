import Entity from '../entity'
import Currency from '../preferences/currency';
import ReceivableAccountDue from './receivableaccountdue';

export default class ReceivableAccount extends Entity<number>{
    reference: string;
    customer_id: number;
    invoice_type_id: number;
    currency_id: number;
    state_id: number;
    sale_id: number;
    account_combined_id: number;
    delivery_order_id: number;
    invoice_number: string;
    debt: number;
    paid: number;
    credit_note: number;
    balance: number;
    operation_date: string;
    due_date: string;
    last_payment_date: string;
    charge_interest: boolean;
    charge_extra_fee: boolean;
    nro_dues: number;

    currency: Currency;
    dues: Array<ReceivableAccountDue>;
}


