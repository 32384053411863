import Entity from '../entity'
import SaleOrderDetail from './saleorderdetail';
import Customer from '../customers/customer';
import Currency from '../preferences/currency';
import Address from '../customers/address';

export default class SaleOrder extends Entity<number>{
    reference: string;
    serie: string;
    number: string;
    customer_id: number;
    sale_quotation_id: number;
    branch_id: number;
    delivery_address_id: number;
    currency_id: number;
    currency_sign: string;
    seller_id: number;
    payment_type_id: number;
    current_state_id: number;
    tax_id: number;
    tax_rate: number;
    credit_days: number;
    exchange_rate: number;
    including_taxes: boolean;
    discount_type: string;
    subtotal: number;
    subtotal_wt: number;
    taxes: number;
    discounts: number;
    total: number;
    total_wt: number;
    total_paid: number;
    balance: number;
    issue_date: string;
    delivery_date: string;
    expiration_date: string;
    notes: string;
    purchase_order: string;
    is_confirmed: boolean;
    quotation_nro: string;
    details: Array<SaleOrderDetail>;

    customer: Customer;
    currency: Currency;
    delivery_address: Address;
    address_full: string;
    nro: string;
    customer_code: string;
    customer_document: string;
    customer_name: string;
    customer_email: string;
    group_name: string;
    credit_line: number;
}


