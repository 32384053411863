import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import EntryOrder from '../../entities/stocks/entryorder'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'
import Customer from '@/store/entities/customers/customer';
import EntryOrderDetail from '@/store/entities/stocks/entryorderdetail';
import EntryOrderHistory from '@/store/entities/sales/history';
import PageResult from '@/store/entities/page-result';
import PurchaseOrder from '@/store/entities/purchases/purchaseorder';
import WorkOrder from '@/store/entities/production/workorder';
import Purchase from '@/store/entities/purchases/purchase';
import PurchaseImport from '@/store/entities/purchases/purchaseimport';
import axios from 'axios'

interface EntryOrderState extends ListState<EntryOrder>{
    editModel:EntryOrder;    
    editCustomer: Customer;      
    hisLoading: boolean;
    hasChanges: boolean;
    supplier: number;
    history: Array<EntryOrderHistory>;
    reportList: Array<any>;
    filename: string;     
}
class EntryOrderModule extends ListModule<EntryOrderState,any,EntryOrder>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<EntryOrder>(),
        loading:false,
        hisLoading: false,
        hasChanges: false,
        editModel: new EntryOrder(),
        editCustomer: new Customer(),
        activeList: new Array<EntryOrder>(),
        history: new Array<EntryOrderHistory>(),
        supplier: null,
        reportList: new Array<any>(),
        filename: '',
        path: 'entry-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<EntryOrderState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<EntryOrder>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }
            context.state.list.forEach(item => {
                item.nro_document = item.invoice_number;
                if (item.invoice_number == null || item.invoice_number == '') {
                    item.nro_document = 'Pendiente...';
                    item['color'] = 'red';
                }                
            });

            return context.state.list;
        },
        async get(context: ActionContext<EntryOrderState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/entry-orders/get/' + payload.id);
            context.state.editModel = reponse.data.result as EntryOrder;
            context.state.loading = false;
            return context.state.editModel;
        },
        async getLastNumber(context: ActionContext<EntryOrderState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/entry-orders/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<EntryOrderState, any>, payload: any) {
            context.state.hisLoading = true; 
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/entry-orders/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async print(context: ActionContext<EntryOrderState, any>, payload: any) {
            context.state.loading = true;

            let format = Util.abp.setting.get('ENTRY_PRINT_FORMAT');
            let localUrl = Util.abp.setting.get('LOCAL_PRINTER_URL');
            let mode = Util.abp.setting.get('REMOTE_PRINTER_MODE');
            let method = Util.abp.setting.get('REMOTE_PRINTER_METHOD');
            let useRemote = Util.abp.setting.get('USE_REMOTE_PRINTER');

            let api = (format == 'A4' ? 'entry-orders-pdf' : 'entry-orders-receipt');

            let response = await Ajax.get('/api/' + api, { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            if (mode == 'LOCAL' && useRemote == 1 && format == 'TK') {
                if (response.data.result.localUrl != undefined) localUrl = response.data.result.localUrl;
                if (method == 'POST') {
                    await axios.post(localUrl, response.data.result);
                } else {
                    let href: any = localUrl + "?endpoint=" + (url + 'api/' + api) + "&token=" + window.abp.auth.getToken() + "&id=" + payload.id;
                    const link = document.createElement('a');
                    link.href = href;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    return '';
                    //await axios.get(localUrl, { params: response.data.result });
                }
                return '';
            } else
                return response.data.result;
        },
        async receiveProducts(context: ActionContext<EntryOrderState, any>, payload: any) {
            context.state.loading = true; 
            let response = await Ajax.post('/api/entry-orders/receive', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return true;
        },

        async reportEntryNotes(context: ActionContext<EntryOrderState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/entry-notes', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: EntryOrderState, model: EntryOrder) {
            state.editCustomer = Object.assign({});

            state.editModel = Object.assign({});
            state.editModel.entry_date = Util.abp.clock.today();
            state.editModel.supplier_id = null;
            state.editModel.serie = Util.abp.setting.get('ENTRY_SERIE');
            state.editModel.warehouse_id = Util.abp.session.warehouseId;
            state.editModel.received_by_id = Util.abp.session.employeeId;
            state.editModel.reason_id = Util.abp.setting.get('STOCK_MVT_INC_REASON_DEFAULT');
            state.editModel.number = '000000';
            state.editModel.details = new Array<EntryOrderDetail>();
        },
        edit(state: EntryOrderState, model: EntryOrder) {
            state.editModel = model;
            if (model.supplier != null)
                state.editModel.supplier_name = model.supplier.full_name;
        },
        setSupplier(state: EntryOrderState, id: number) {
            state.editModel.supplier_id = id;
            state.supplier = id;
        },
        loadFromPurchaseOrder(state: EntryOrderState, model: PurchaseOrder) {
            if (model == undefined) return;
            
            state.editModel.reference = model.reference;
            state.editModel.serie = Util.abp.setting.get('ENTRY_SERIE');
            state.editModel.number = '000000';
            state.editModel.supplier_id = model.supplier_id;
            state.editModel.warehouse_id = Util.abp.session.warehouseId;
            state.editModel.received_by_id = Util.abp.session.employeeId;
            state.editModel.reason_id = Util.abp.setting.get('STOCK_MVT_PURCHASE_REASON');
            state.editModel.purchase_planning_id = model.purchase_planning_id;
            state.editModel.entity_id = model.id;
            state.editModel.entity = 'purchase_order';
            state.editModel.origin_document = model.nro;
            state.editModel.invoice_number = null;
            state.editModel.entry_date = Util.abp.clock.today();
            state.editModel.supplier_name = model.supplier_name;
            state.editModel.invoiced = model.invoiced;
            if (model.invoiced) state.editModel.invoice_number = model['invoice'];

            state.editModel.details = new Array<EntryOrderDetail>();
            
            model.details.forEach((item, index) => {
                if (!item.service && (item.quantity - item.quantity_received) > 0) {
                    let det = new EntryOrderDetail();
                    det = Util.extend(true, {}, item);
                    if (det.warehouse_id == undefined) det.warehouse_id = Util.abp.session.warehouseId;
                    det.entity_id = model.id;
                    det.entity = 'purchase_order';
                    det.quantity = (item.quantity - item.quantity_received);
                    det.product_cost = det.product_cost;
                    if (model.currency_id != Util.abp.setting.get('DEFAULT_CURRENCY'))
                        det.cost_wt = Util.convertAmountToCurrency(det.cost_wt, model.currency_id, Util.abp.setting.get('DEFAULT_CURRENCY'));
                    det.total_wt = det.quantity * det.cost_wt;
                    det.quantity_received = 0;

                    delete det['prices'];
                    delete det.unity;
                    delete det.id;
                    state.editModel.details.push(det);
                }
            });
        },
        loadFromPurchaseOrders(state: EntryOrderState, list: Array<PurchaseOrder>) {
            if (list == undefined) return;

            state.editModel.serie = Util.abp.setting.get('ENTRY_SERIE');
            state.editModel.number = '000000';
            state.editModel.warehouse_id = Util.abp.session.warehouseId;
            state.editModel.received_by_id = Util.abp.session.employeeId;
            state.editModel.reason_id = Util.abp.setting.get('STOCK_MVT_PURCHASE_REASON');
            state.editModel.entry_date = Util.abp.clock.today();
            state.editModel.entity = 'purchase_order';
            state.editModel.invoice_number = null;
            state.editModel.details = new Array<EntryOrderDetail>();
            
            list.forEach(model => {
                state.editModel.supplier = model['supplier'];
                state.editModel.supplier_id = model.supplier_id;
                state.editModel.purchase_planning_id = model.purchase_planning_id;
                state.editModel.entity_id = model.id;
                state.editModel.origin_document = model.nro;
                state.editModel.supplier_name = model.supplier_name;
                state.editModel.invoiced = model.invoiced;
                if (model.invoiced) state.editModel.invoice_number = model['invoice'];

                model.details.forEach((item, index) => {
                    if (!item.service && item.quantity_received < item.quantity) {
                        let det = new EntryOrderDetail();
                        det = Util.extend(true, {}, item);
                        if (det.warehouse_id == undefined) det.warehouse_id = Util.abp.session.warehouseId;
                        det.entity_id = model.id;
                        det.entity = 'purchase_order';
                        det.quantity = item.quantity - item.quantity_received;
                        det.quantity_received = det.quantity;
                        det.quantity_pending = det.quantity - det.quantity_received;
                        det.product_cost = det.product_cost;
                        if (model.currency_id != Util.abp.setting.get('DEFAULT_CURRENCY'))
                            det.cost_wt = Util.convertAmountToCurrency(det.cost_wt, model.currency_id, Util.abp.setting.get('DEFAULT_CURRENCY'));

                        det.total_wt = det.quantity * det.cost_wt;

                        delete det['prices'];
                        delete det.unity;
                        delete det.id;
                        state.editModel.details.push(det);
                    }
                });
            });            
        },
        loadFromPurchase(state: EntryOrderState, model: Purchase) {
            if (model == undefined) return;

            state.editModel.reference = model.reference;
            state.editModel.serie = Util.abp.setting.get('ENTRY_SERIE');
            state.editModel.number = '000000';
            state.editModel.supplier_id = model.supplier_id;
            state.editModel.warehouse_id = Util.abp.session.warehouseId;
            state.editModel.received_by_id = Util.abp.session.employeeId;
            state.editModel.reason_id = Util.abp.setting.get('STOCK_MVT_PURCHASE_REASON');
            state.editModel.purchase_planning_id = model.purchase_planning_id;
            state.editModel.entity_id = model.id;
            state.editModel.entity = 'purchase';
            state.editModel.origin_document = model['invoice'];
            state.editModel.invoiced = true;
            state.editModel.invoice_number = model['invoice'];
            state.editModel.entry_date = Util.abp.clock.today();
            state.editModel.supplier_name = model.supplier_name;
            
            state.editModel.details = new Array<EntryOrderDetail>();

            model.details.forEach((item, index) => {
                if (!item.service) {
                    let det = new EntryOrderDetail();
                    det = Util.extend(true, {}, item);
                    if (det.warehouse_id == undefined) det.warehouse_id = Util.abp.session.warehouseId;
                    det.product_cost = det.product_cost;
                    if (model.currency_id != Util.abp.setting.get('DEFAULT_CURRENCY'))
                        det.cost_wt = Util.convertAmountToCurrency(det.cost_wt, model.currency_id, Util.abp.setting.get('DEFAULT_CURRENCY'));

                    det.total_wt = det.quantity * det.cost_wt;
                    delete det['prices'];
                    delete det.unity;
                    delete det.id;
                    state.editModel.details.push(det);
                }
            });
        },
        loadFromPurchases(state: EntryOrderState, list: Array<Purchase>) {
            if (list == undefined) return;

            state.editModel.serie = Util.abp.setting.get('ENTRY_SERIE');
            state.editModel.number = '000000';
            state.editModel.warehouse_id = Util.abp.session.warehouseId;
            state.editModel.received_by_id = Util.abp.session.employeeId;
            state.editModel.reason_id = Util.abp.setting.get('STOCK_MVT_PURCHASE_REASON');
            state.editModel.entry_date = Util.abp.clock.today();
            state.editModel.entity = 'purchase';
            state.editModel.details = new Array<EntryOrderDetail>();

            list.forEach(model => {
                state.editModel.supplier = model['supplier'];
                state.editModel.supplier_id = model.supplier_id;
                state.editModel.purchase_planning_id = model.purchase_planning_id;
                state.editModel.entity_id = model.id;
                state.editModel.origin_document = model['invoice'];
                state.editModel.invoiced = true;
                state.editModel.invoice_number = model['invoice'];
                state.editModel.supplier_name = model.supplier_name;

                model.details.forEach((item, index) => {
                    if (!item.service) {
                        let det = new EntryOrderDetail();
                        det = Util.extend(true, {}, item);
                        if (det.warehouse_id == undefined) det.warehouse_id = Util.abp.session.warehouseId;
                        det.entity_id = model.id;
                        det.entity = 'purchase';
                        det.quantity_received = det.quantity;
                        det.quantity_pending = det.quantity - det.quantity_received;
                        det.product_cost = det.product_cost;
                        if (model.currency_id != Util.abp.setting.get('DEFAULT_CURRENCY'))
                            det.cost_wt = Util.convertAmountToCurrency(det.cost_wt, model.currency_id, Util.abp.setting.get('DEFAULT_CURRENCY'));

                        det.total_wt = det.quantity * det.cost_wt;
                        delete det['prices'];
                        delete det.unity;
                        delete det.id;
                        state.editModel.details.push(det);
                    }
                });
            });
        },
        loadFromWorkOrder(state: EntryOrderState, model: WorkOrder) {
            if (model == undefined) return;

            state.editModel.reference = model.reference;
            state.editModel.serie = Util.abp.setting.get('ENTRY_SERIE');
            state.editModel.number = '000000';
            state.editModel.warehouse_id = Util.abp.session.warehouseId;
            state.editModel.received_by_id = Util.abp.session.employeeId;
            state.editModel.reason_id = Util.abp.setting.get('STOCK_MVT_WO_IN');
            state.editModel.entity_id = model.id;
            state.editModel.entity = 'work_order';
            state.editModel.origin_document = model.nro;
            state.editModel.entry_date = Util.abp.clock.today();
            state.editModel.supplier_name = model['supplier_name'];

            state.editModel.details = new Array<EntryOrderDetail>();
            let det = new EntryOrderDetail();
            det.product_id = model.work_product_id;
            det.product_attribute_id = 0;
            det.entity_id = model.id;
            det.entity = 'work_order';
            det.unity_id = (model.work_product != null ? model.work_product.unity_id : null);
            det.warehouse_id = state.editModel.warehouse_id;
            det.code = (model.work_product != null ? model.work_product.code : null);
            det.product_name = model.work_product_name;
            det.quantity = model.quantity_production;
            //det.stock: number;
            det.cost_wt = model.total_cost / det.quantity;
            det.total_wt = model.total_cost;
            det.product_cost = model.total_cost / det.quantity;

            state.editModel.details.push(det);
        },
        loadFromPlanning(state: EntryOrderState, data) {
            if (data.model == undefined) return;
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);

            state.editModel.reference = data.model.reference;
            state.editModel.serie = Util.abp.setting.get('ENTRY_SERIE');
            state.editModel.number = '000000';
            state.editModel.warehouse_id = Util.abp.session.warehouseId;
            state.editModel.received_by_id = Util.abp.session.employeeId;
            state.editModel.reason_id = Util.abp.setting.get('STOCK_MVT_WO_IN');
            state.editModel.entity_id = data.model.id;
            state.editModel.entity = 'purchase_planning';
            state.editModel.purchase_planning_id = data.model.id;
            state.editModel.origin_document = data.model.nro;
            state.editModel.entry_date = Util.abp.clock.today();
            state.editModel.supplier_name = data.products[0].supplier_name;
            state.editModel.supplier_id = data.products[0].supplier_id;
            state.editModel['supplier'] = data.products[0].supplier;
            state.editModel.details = new Array<EntryOrderDetail>();

            if (state.editModel.supplier_id == 0) state.editModel.supplier_id = null;

            data.products.forEach((item, index) => {
                let quantityVal = item.quantity_received < item.quantity && item.quantity_ordered < item.quantity && item.quantity_request < item.quantity && !item.service;
                if (Util.abp.setting.get('ENABLE_LOAD_PURCHASE_REQUEST') == 1)
                    quantityVal = item.quantity_received < item.quantity && item.quantity_ordered < item.quantity && !item.service;

                if (quantityVal) {
                    let det = new EntryOrderDetail();
                    det = Util.extend(true, {}, item);
                    det.warehouse_id = Util.abp.session.warehouseId;

                    if (item.quantity_request > 0 && item.quantity_request > item.quantity_received)
                        det.quantity = item.quantity - item.quantity_request;
                    else if (item.quantity_ordered > 0 && item.quantity_ordered > item.quantity_received)
                        det.quantity = item.quantity - item.quantity_ordered;
                    else
                        det.quantity = item.quantity - item.quantity_received;

                    if (Util.abp.setting.get('ENABLE_LOAD_PURCHASE_REQUEST') == 1) {
                        if (item.quantity_ordered > 0 && item.quantity_ordered > item.quantity_received)
                            det.quantity = item.quantity - item.quantity_ordered;
                        else
                            det.quantity = item.quantity - item.quantity_received;
                    }                        

                    det.quantity_received = det.quantity;

                    delete det.unity;
                    delete det.id;
                    state.editModel.details.push(det);
                }
            });
        },
        loadFromImport(state: EntryOrderState, model: PurchaseImport) {
            if (model == undefined) return;

            state.editModel.reference = model.reference;
            state.editModel.serie = Util.abp.setting.get('ENTRY_SERIE');
            state.editModel.number = '000000';
            state.editModel.supplier_id = model.details[0].supplier_id;
            state.editModel.warehouse_id = Util.abp.session.warehouseId;
            state.editModel.received_by_id = Util.abp.session.employeeId;
            state.editModel.reason_id = Util.abp.setting.get('STOCK_MVT_PURCHASE_REASON');
            state.editModel.purchase_planning_id = model.purchase_planning_id;
            state.editModel.entity_id = model.id;
            state.editModel.entity = 'import';
            state.editModel.origin_document = model['nro'];
            state.editModel.invoiced = true;
            state.editModel.invoice_number = model['nro_policy'];
            state.editModel.entry_date = Util.abp.clock.today();
            state.editModel.supplier_name = model.details[0]['supplier'].name;

            state.editModel.details = new Array<EntryOrderDetail>();

            model.details.forEach((item, index) => {
                let det = new EntryOrderDetail();
                det = Util.extend(true, {}, item);
                if (det.warehouse_id == undefined) det.warehouse_id = Util.abp.session.warehouseId;
                det.product_cost = det.product_cost;
                if (model.currency_id != Util.abp.setting.get('DEFAULT_CURRENCY'))
                    det.cost_wt = Util.convertAmountToCurrency(det.cost_wt, model.currency_id, Util.abp.setting.get('DEFAULT_CURRENCY'));

                det.total_wt = det.quantity * det.cost_wt;
                delete det['prices'];
                delete det.unity;
                delete det.id;
                state.editModel.details.push(det);
            });
        },
        setChanged(state: EntryOrderState, value: boolean) {
            state.hasChanges = value;
        },
        setWarehouse(state: EntryOrderState, warehouse: number) {
            state.editModel.warehouse_id = warehouse;
        },
    });
}
const entryorderModule=new EntryOrderModule();
export default entryorderModule;