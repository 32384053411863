import Entity from '../entity'

export default class Process extends Entity<number>{
    code: string;
    name: string;
    position: number;
    active: boolean;
}


