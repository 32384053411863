import Entity from '../entity'

export default class AccountCombined extends Entity<number>{
    customer_id: number;
    supplier_id: number;
    state_id: number;
    entity: string;
    invoice_numbers: Array<string>;
    amount: number;
    paid: number;
    balance: number;
    last_payment_date: string;
    charge_interest: boolean;
    charge_extra_fee: boolean;
    nro_dues: number;

    accounts: Array<number>;
    dues: Array<number>;
}


