import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Configuration from '../../entities/preferences/configuration'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ConfigurationState extends ListState<Configuration>{
    editModel: Configuration;
}
class ConfigurationModule extends ListModule<ConfigurationState, any, Configuration>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Configuration>(),
        loading:false,
        editModel:new Configuration(),
        path: 'configurations'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<ConfigurationState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let response = await Ajax.get('/api/configurations').catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list = response.data.result;
            Util.abp.setting.values = response.data.result;
        },
        async save(context: ActionContext<ConfigurationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/save', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            
            Util.abp.setting.values = response.data.result;
            //Util.abp.setting.values = Util.extend(true, Util.abp.setting.values, response.data.result);
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const configurationModule=new ConfigurationModule();
export default configurationModule;