import Entity from '../entity'

export default class WorkOrderDocument extends Entity<number>{
    work_order_id: number;
    document_type_id: number;
    name: string;
    filename: string;

    fileData: string;
}


