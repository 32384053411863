import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import Account from '../../entities/accounting/account'
import Util from '../../../lib/util'

interface AccountState extends ListState<Account>{
    editModel: Account;
    activeList: Array<Account>;
    reportList: Array<any>;
    filename: string;
}
class AccountModule extends ListModule<AccountState, any, Account>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10, 
        list: new Array<Account>(),
        loading:false,
        editModel:new Account(),
        activeList: new Array<Account>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'accounts'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async reportMoves(context: ActionContext<AccountState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/account-moves', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result;
            context.state.loading = false;
        },
        async reportSiigo(context: ActionContext<AccountState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/siigo', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.filename;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setType(state: AccountState, type: number) {
            state.editModel.type_id = type;
        },
        addAccount(state: AccountState, acc: any) {
            state.activeList.push(acc);
        },
    });
}
const accountModule=new AccountModule();
export default accountModule;