import Entity from '../entity'

export default class Activity extends Entity<number>{
    process_id: number;
    equipment_type_id: number;
    equipment_id: number;
    code: string;
    name: string;
    description: string;
    duration_type: string;
    time_estimated: number;
    active: boolean;
}


