import Entity from '../entity'

export default class BudgetConcept extends Entity<number>{
    budget_id: number;
    employee_id: number;
    cost_center_id: number;
    concept_id: number;
    requested: number;
    approved: number;
    used: number;
    balance: number;
}


