import Entity from '../entity'
import InvoiceTypeSerie from './invoicetypeserie';

export default class InvoiceType extends Entity<number>{
    name: string;
    reference: string;
    code: string;
    description: string;
    for_sales: boolean;
    for_purchases: boolean;
    for_referral_guide: boolean;
    for_credit: boolean;
    invoiceable: boolean;
    active: boolean;
    series: Array<InvoiceTypeSerie>;
}


