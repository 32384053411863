import Entity from '../entity'
import Tax from '../preferences/tax';
import MeasureUnit from '../catalogs/measureunit';
import Product from '../catalogs/product';

export default class SaleOrderDetail extends Entity<number>{
    sale_order_id: number;
    product_id: number;
    product_attribute_id: number;
    warehouse_id: number;
    unity_id: number;
    price_rule_id: number;
    special_price_rule_id: number;
    tax_id: number;
    tax_rate: number;
    exchange_rate: number;
    code: string;
    upc: string;
    product_name: string;
    version: string;
    quantity: number;
    stock_physical: number;
    stock_reserved: number;
    stock: number;
    discount_type: string;
    price: number;
    price_wt: number;
    taxes: number;
    discounts: number;
    total: number;
    total_wt: number;
    delivery_date: string;
    product_price: number;
    confirmed: number;
    finished: number;
    product_location_id: number;
    service: boolean;
    gift: boolean;

    tax: Tax;
    unity: MeasureUnit;
    product: Product;
    unity_sym: string;
    special_price_rule: any;
    discount_manual: boolean;
    batches: Array<any>;
}


