import Entity from '../entity'
import Product from '../catalogs/product';

export default class BookingProduct extends Entity<number>{
    booking_id: number;
    price_rule_id: number;
    currency_id: number;
    product_type_id: number;
    product_id: number;
    product_attribute_id: number;
    hotel_id: number;
    room_type_id: number;
    sale_detail_id: number;
    current_state_id: number;
    exchange_rate: number;
    seq: number;
    code: string;
    reference: string;
    product_name: string;
    nro_pax: number;
    nro_inf: number;
    nro_child: number;
    days: number;
    price: number;
    price_inf: number;
    price_child: number;
    taxes: number;
    total: number;
    total_wt: number;
    booking_date: string;
    schedule: string;
    pack_items: Array<any>;
    changed: boolean;
    quiz_sent: boolean;
    finished: boolean;
    product_price: number;

    hotel: any;
    features: Array<any>;
    schedule_list: string;
    product: Product;
    currency_sign: string;
    including_taxes: boolean;
}


