import Entity from '../entity'

export default class FoodPlan extends Entity<number>{
    code: string;
    customer_id: number;
    year: number;
    month: number;
    week: number;
    price_break: number;
    price_lunch: number;
    price_dinner: number;
    start_date: string;
    end_date: string;
    categories: Array<any>;
    customer: any;
}


