import Entity from '../entity'
import WorkOrderActivity from './workorderactivity';

export default class WorkOrderTask   extends Entity<number>{
    work_order_id: number;
    process_id: number;
    quantity: number;
    process_name: string;
    
    activities: Array<WorkOrderActivity>;
}


