import Entity from '../entity'

export default class Custom extends Entity<number>{
    code: string;
    name: string;
    department_id: number;
    province_id: number;
    district_id: number;
    active: boolean;
}


