import Entity from '../entity'

export default class PriceRule extends Entity<number>{
    currency_id: number;
    name: string;
    code: string;
    from: string;
    to: string;
    active: boolean;
    main: boolean;
}


