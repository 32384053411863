import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ManufacturerModel from '@/store/entities/catalogs/manufacturermodel';
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Manufacturer from '@/store/entities/catalogs/manufacturer';

interface ManufacturerModelState extends ListState<ManufacturerModel>{
    editModel: ManufacturerModel;
    activeList: Array<ManufacturerModel>;
    manufacturer: Manufacturer;
}
class ManufacturerModelModule extends ListModule<ManufacturerModelState, any, ManufacturerModel>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ManufacturerModel>(),
        loading:false,
        editModel: new ManufacturerModel(),
        activeList: new Array<ManufacturerModel>(),
        manufacturer: new Manufacturer(),
        path: 'manufacturers'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<ManufacturerModelState, any>, payload: any) {
            context.state.loading = true;
            let result = await Ajax.post('/api/manufacturers/' + payload.data.manufacturer_id + '/add-model', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list.push(result.data.result);
            return result.data.result;
        },
        async delete(context: ActionContext<ManufacturerModelState, any>, payload: any) {
            context.state.loading = true;
            let result = await Ajax.post('/api/manufacturers/delete-model/' + payload.data, { id: payload.data });
            context.state.loading = false;
            context.state.list = result.data.result;
        },
        async querySearch(context: ActionContext<ManufacturerModelState, any>, payload: any) {
            if (context.state.activeList.length > 0) return true;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/manufacturer-models/query-search', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return reponse.data.result;
        }
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ManufacturerModelState, model: any) {
            //state.manufacturer = model;
            state.editModel = Object.assign({});
            if (state.manufacturer != null)
                state.editModel.manufacturer_id = state.manufacturer.id;
        },
        setList(state: ManufacturerModelState, model: Manufacturer) {
            state.manufacturer = model;
            if (model != null && model.models != null) {
                state.list = model.models;
            } else
                state.list = [];
        },
    });
}
const manufacturermodelModule = new ManufacturerModelModule();
export default manufacturermodelModule;