import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ProductType from '../../entities/catalogs/producttype'
import Util from '../../../lib/util'

interface ProductTypeState extends ListState<ProductType>{
    editModel:ProductType;
    activeList: Array<ProductType>;
}
class ProductTypeModule extends ListModule<ProductTypeState,any,ProductType>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ProductType>(),
        loading: false,
        editModel:new ProductType(),
        activeList: new Array<ProductType>(),
        path: 'product-types',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const producttypeModule=new ProductTypeModule();
export default producttypeModule;