import Entity from '../entity'

export default class PreparationRemark extends Entity<number>{
    preparation_group_id: number;
    name: string;
    image: string;
    active: boolean;

    image_url: string;
    imageData: string;

    categories: Array<any>;
    remark_categories: Array<any>;
}
