import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import DebitNoteDetail from '../../entities/sales/debitnotedetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import DebitNote from '@/store/entities/sales/debitnote';

interface DebitNoteDetailState extends ListState<DebitNoteDetail>{
    editModel:DebitNoteDetail;    
    debitnote:number;    
}
class DebitNoteDetailModule extends ListModule<DebitNoteDetailState,any,DebitNoteDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<DebitNoteDetail>(),
        loading:false,
        editModel: new DebitNoteDetail(),
        activeList: new Array<DebitNoteDetail>(),
        debitnote: 0,
        path: 'debit-notes'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<DebitNoteDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: DebitNoteDetailState, model: DebitNoteDetail) {
            state.editModel = model;
        },
        init(state: DebitNoteDetailState, model: DebitNote) {
            state.editModel = Object.assign({});
            if (model != undefined) {
                state.debitnote = model.id;
                state.list = model.details;
            }
        },
        setProduct(state: DebitNoteDetailState, product: any) {
            state.editModel.product = product;
            state.editModel.product.manufacturer_name = product.manufacturer.name;
            state.editModel.product.category_name = product.category_default != undefined ? product.category_default.name : '';

        },
        add(state: DebitNoteDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id && detail.product_attribute_id == product['product_attribute_id'];
            });

            if (exist.length > 0) {
                exist[0].quantity++;
                exist[0].total = exist[0].quantity * exist[0].price;
                exist[0].total_wt = exist[0].quantity * exist[0].price_wt
                exist[0].taxes = parseFloat((exist[0].total_wt - exist[0].total).toFixed(3));
            } else {
                let detail = new DebitNoteDetail();
                detail.debit_note_id = state.editModel.id;
                detail.product_id = product.id;
                detail.product_attribute_id = product['product_attribute_id'];
                detail.warehouse_id = (product['warehouse_id'] != undefined ? product['warehouse_id'] : Util.abp.session.warehouseId);
                detail.unity_id = product.unity_id;
                //detail.unity_sym = product.unity_symbol;
                detail.product_name = Util.formatProdName(product);
                detail.version = product.version;
                //detail.discount_type
                detail.quantity = 1;
                detail.price = product.price;
                detail.price_wt = product.price_wt;
                detail.total = detail.quantity * detail.price;
                detail.total_wt = detail.quantity * detail.price_wt
                detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(3));
                detail.service = product['service'];
                
                state.list.push(detail);
            }
        },
        update(state: DebitNoteDetailState, detail: DebitNoteDetail) {
            detail.quantity = parseInt(String(detail.quantity));

            detail.total = detail.quantity * detail.price;
            detail.total_wt = (detail.quantity * detail.price_wt);

            detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(3));            
        },
        delete(state: DebitNoteDetailState, index: number) {
            state.list.splice(index, 1);
        },
        save(state: DebitNoteDetailState, detail: DebitNoteDetail) {
            for (var i = 0; i < state.list.length; i++) {
                if (state.list[i].id == detail.id) {
                    state.list[i] = detail;
                }
            }
        },
    });
}
const debitnotedetailModule=new DebitNoteDetailModule();
export default debitnotedetailModule;