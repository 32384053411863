import Entity from '../entity'

export default class Stock extends Entity<number>{
    product_id: number;
    product_attribute_id: number;
    code: string;
    expiry_date: string;
    active: boolean;

    product_name: string;
}


