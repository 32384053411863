import Entity from '../entity'

export default class Address extends Entity<number>{
    index: number;
    customer_id: number;
    country_id: number;
    department_id: number;
    province_id: number;
    district_id: number;
    address1: string;
    address2: string;
    postcode: string;
    phone: string;
    phone_mobile: string;
    other: string;

    district: any;
    ubigeo: any;
}


