import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import SpecialPrice from '../../entities/catalogs/specialprice'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface SpecialPriceState extends ListState<SpecialPrice>{
    editModel:SpecialPrice;
}
class SpecialPriceModule extends ListModule<SpecialPriceState,any,SpecialPrice>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize:10,
        list: new Array<SpecialPrice>(),
        loading:false,
        editModel:new SpecialPrice(),
        activeList: new Array<SpecialPrice>(),
        path: 'special-prices',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const productpriceModule=new SpecialPriceModule();
export default productpriceModule;