import url from './url'
const AppConsts= {
    userManagement:{
        defaultAdminUserName: 'admin'
    },
    localization:{
        defaultLocalizationSourceName: process.env.VUE_APP_NAME.toLowerCase()
    },
    authorization:{
        encrptedAuthTokenName: 'enc_auth_token'
    },
    sState: {
        PEND: 1,
        PART: 2,
        PAID: 3,
        VENC: 4,
        ANUL: 5,
        ACEP: 6,
        RECH: 7,
        FINA: 8,
        PROC: 9,
        TDEL: 10,
        DISP: 11,
        DELI: 12,
        SEND: 13,
    },
    setting: {
        shrink: false,
        confirmLeave: false,
        nroTabs: 12,
        timeout: 240000,
        appName: process.env.VUE_APP_NAME,
    },
    appBaseUrl: "http://localhost:8080",
    remoteServiceBaseUrl: url

}
export default AppConsts