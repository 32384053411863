import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ImportExpenseType from '../../entities/purchases/importexpensetype'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ImportExpenseTypeState extends ListState<ImportExpenseType>{
    editModel: ImportExpenseType;
}
class ImportExpenseTypeModule extends ListModule<ImportExpenseTypeState, any, ImportExpenseType>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ImportExpenseType>(),
        loading:false,
        editModel:new ImportExpenseType(),
        activeList: new Array<ImportExpenseType>(),
        path: 'import-expense-types'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastCode(context: ActionContext<ImportExpenseTypeState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/import-expense-types/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        }, 
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ImportExpenseTypeState) {
            state.editModel = Object.assign({});
            state.editModel.code = 1;
        },
    });
}
const importexpensetypeModule=new ImportExpenseTypeModule();
export default importexpensetypeModule;