import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Employee from '../../entities/admin/employee'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface EmployeeState extends ListState<Employee>{
    editModel:Employee;
    
}
class EmployeeModule extends ListModule<EmployeeState,any,Employee>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Employee>(),
        loading:false,
        editModel: new Employee(),
        activeList: new Array<Employee>(),
        path: 'employees'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions        
        async getLastCode(context: ActionContext<EmployeeState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/employees/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: EmployeeState, model: Employee) {
            state.editModel = Object.assign({});
            state.editModel.code = '';
            state.editModel.username = null;
            state.editModel.email = null;
        },
        edit(state: EmployeeState, model: Employee) {
            state.editModel = model;

            if (model.user != null) {
                state.editModel.username = model.user.username;
                state.editModel.email = model.user.email;
                state.editModel.branches = model.user.branches;
            }
        },
    });
}
const employeeModule=new EmployeeModule();
export default employeeModule;
