import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Table from '@/store/entities/foods/table';

interface TableState extends ListState<Table>{
    editModel: Table;
}
class TableModule extends ListModule<TableState, any, Table>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Table>(),
        loading: false,
        editModel:new Table(),
        activeList: new Array<Table>(),
        path: 'tables'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: TableState, model: Table) {
            state.editModel = Object.assign({});
        },
        edit(state: TableState, model: Table) {
            state.editModel = model;
        },
    });
}
const tableModule=new TableModule();
export default tableModule;