import Entity from '../entity'

export default class Stock extends Entity<number>{
    warehouse_id: number;
    product_id: number;
    product_attribute_id: number;
    upc: string;
    reference: string;
    physical_quantity: number;
    usable_quantity: number;
    price: number;
}


