import Entity from '../entity'
import User from './user';

export default class Employee extends Entity<number>{
    code: string;
    first_name: string;
    last_name: string;
    document: string;
    user_id: number;
    area_id: number;
    cost_center_id: number;
    job_title_id: number;
    hour_rate: number;
    active: boolean;
    user: User;
    username: string;
    email: string;
    password: string;
    branches: Array<number>;
}


