import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import TransferOrder from '../../entities/stocks/transferorder'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Customer from '@/store/entities/customers/customer';
import TransferOrderDetail from '@/store/entities/stocks/transferorderdetail';
import TransferOrderHistory from '@/store/entities/sales/history';
import Sale from '@/store/entities/sales/sale';
import WorkOrder from '@/store/entities/production/workorder';

interface TransferOrderState extends ListState<TransferOrder>{
    editModel:TransferOrder;    
    hisLoading: boolean;
    hasChanges: boolean;      
    history: Array<TransferOrderHistory>;
    reportList: Array<any>; 
    filename: string;     
}
class TransferOrderModule extends ListModule<TransferOrderState,any,TransferOrder>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<TransferOrder>(),
        loading:false,
        hisLoading: false,
        hasChanges: false,
        editModel: new TransferOrder(),
        activeList: new Array<TransferOrder>(),
        history: new Array<TransferOrderHistory>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'transfer-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<TransferOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async update(context: ActionContext<TransferOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async getLastNumber(context: ActionContext<TransferOrderState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/transfer-orders/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<TransferOrderState, any>, payload: any) {
            context.state.hisLoading = true; 
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/transfer-orders/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async print(context: ActionContext<TransferOrderState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.get('/api/transfer-order-pdf', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            return response.data.result;
        },
        async delivery(context: ActionContext<TransferOrderState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/transfer-orders/delivery', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return true;
        },
        async transferProducts(context: ActionContext<TransferOrderState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/transfer-orders/transfer', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return true;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: TransferOrderState, model: TransferOrder) {
            state.editModel = Object.assign({});
            state.editModel.transfer_date = Util.abp.clock.today();
            state.editModel.warehouse_out_id = Util.abp.session.warehouseId;
            state.editModel.warehouse_in_id = null;
            state.editModel.serie = Util.abp.setting.get('TRANSFER_ORDER_SERIE');
            state.editModel.delivered_by_id = Util.abp.session.employeeId;
            state.editModel.number = '000000';
            state.editModel.details = new Array<TransferOrderDetail>();
            state.hasChanges = false;
        },
        edit(state: TransferOrderState, model: TransferOrder) {
            state.editModel = model;
            state.hasChanges = false;
        },
        loadFromSale(state: TransferOrderState, model: Sale) {
            if (model == undefined) return;

            state.editModel = Object.assign({});
            state.editModel.transfer_date = Util.abp.clock.today();
            state.editModel.warehouse_out_id = null;
            state.editModel.warehouse_in_id = null;
            state.editModel.serie = Util.abp.setting.get('TRANSFER_ORDER_SERIE');
            state.editModel.delivered_by_id = Util.abp.session.employeeId;
            state.editModel.number = '000000';
            state.editModel.reference = model.reference;
            state.editModel.entity_id = model.id;
            state.editModel.entity = (model['entity'] != undefined ? model['entity'] : 'sale');
            state.editModel.details = new Array<TransferOrderDetail>();
            state.hasChanges = false;
            
            model.details.forEach((item, index) => {
                if (!item.service) {
                    state.editModel.warehouse_in_id = item.warehouse_id;
                    let det = new TransferOrderDetail();
                    det = Util.extend(true, {}, item);

                    delete det.unity;
                    delete det.id;
                    state.editModel.details.push(det);
                }
            });
        },
        loadFromProduct(state: TransferOrderState, product: any) {
            if (product == undefined) return;

            state.editModel = Object.assign({});
            state.editModel.transfer_date = Util.abp.clock.today();
            state.editModel.warehouse_out_id = null;
            state.editModel.warehouse_in_id = product.warehouse_id;
            state.editModel.serie = Util.abp.setting.get('TRANSFER_ORDER_SERIE');
            state.editModel.delivered_by_id = Util.abp.session.employeeId;
            state.editModel.number = '000000';
            state.editModel.entity_id = null;
            state.editModel.entity = null;
            state.editModel.details = new Array<TransferOrderDetail>();
            state.hasChanges = false;

            let det = new TransferOrderDetail();
            det.product_id = product.product_id;
            det.product_attribute_id = product.product_attribute_id;
            det.unity_id = product.unity_id;
            det.warehouse_id = state.editModel.warehouse_out_id;
            det.product_name = Util.formatProdName(product);
            det.version = product.version;
            det.quantity = 1;
            det.stock = product.stock;
            det.code = product.code;
            det.product = product;
            det.unity_sym = product.unity_symbol;

            state.editModel.details.push(det);
        },
        setChanged(state: TransferOrderState, value: boolean) {
            state.hasChanges = value;
        },
    });
}
const transferorderModule=new TransferOrderModule();
export default transferorderModule;