import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ImageType from '../../entities/preferences/imagetype'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ImageTypeState extends ListState<ImageType>{
    editModel:ImageType;    
    conntected: Array<any>;    
}
class ImageTypeModule extends ListModule<ImageTypeState,any,ImageType>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ImageType>(),
        loading:false,
        editModel: new ImageType(),
        activeList: new Array<ImageType>(),
        conntected: new Array<any>(),
        path: 'image-types'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async regenerate(context: ActionContext<ImageTypeState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/image-types/regenerate', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const imagetypeModule=new ImageTypeModule();
export default imagetypeModule;