import Entity from '../entity'

export default class SaleState extends Entity<number>{
    name: string;
    color: string;
    icon: string;
    for_quotations: boolean;
    for_orders: boolean;
    for_sales: boolean;
    for_delivery: boolean;
    for_notes: boolean;
    active: boolean;
}


