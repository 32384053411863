import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import InvoiceTypeSerie from '../../entities/sales/invoicetypeserie'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import InvoiceType from '@/store/entities/sales/invoicetype';

interface InvoiceTypeSerieState extends ListState<InvoiceTypeSerie>{
    editModel: InvoiceTypeSerie;
    activeList: Array<InvoiceTypeSerie>;
    invoice_type_id: number;
}
class InvoiceTypeSerieModule extends ListModule<InvoiceTypeSerieState,any,InvoiceTypeSerie>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<InvoiceTypeSerie>(),
        loading:false,
        editModel: new InvoiceTypeSerie(),
        activeList: new Array<InvoiceTypeSerie>(),
        invoice_type_id: 0,
        path: 'invoice-type-series'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<InvoiceTypeSerieState, any>, payload: any) {
            context.state.loading = true;
            payload.data.invoice_type_id = context.state.invoice_type_id;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list.push(response.data.result);
            context.state.totalCount++;
        },
        async update(context: ActionContext<InvoiceTypeSerieState, any>, payload: any) {
            context.state.loading = true;
            payload.data.invoice_type_id = context.state.invoice_type_id;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list = response.data.result;
            context.state.totalCount = response.data.result.length;
        },
        async getLastNumber(context: ActionContext<InvoiceTypeSerieState, any>, payload: any) {
            if (context.state.loading) return '0';
            context.state.loading = true;
            let result = null;
            await Ajax.get('/api/invoice-type-series/last-number/' + payload.id).then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            context.state.loading = false;
            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setList(state: InvoiceTypeSerieState, invoice: InvoiceType) {
            state.invoice_type_id = invoice.id;
            if (invoice != null) {
                state.list = invoice.series;
                state.totalCount = invoice.series.length;
            }
        },
        edit(state: InvoiceTypeSerieState, serie: InvoiceTypeSerie) {
            state.editModel = serie;
            state.editModel.invoice_type_id = state.invoice_type_id;
        },
        new(state: InvoiceTypeSerieState, serie: InvoiceTypeSerie) {
            state.editModel = Object.assign({});
            state.editModel.invoice_type_id = state.invoice_type_id;
        }
    });
}
const invoicetypeserieModule=new InvoiceTypeSerieModule();
export default invoicetypeserieModule;