import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import JobTitle from '../../entities/admin/jobtitle'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface JobTitleState extends ListState<JobTitle>{
    editModel: JobTitle;
    activeList: Array<JobTitle>;
}
class JobTitleModule extends ListModule<JobTitleState, any, JobTitle>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<JobTitle>(),
        loading:false,
        editModel:new JobTitle(),
        activeList: new Array<JobTitle>(),
        path: 'job-titles'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const jobtitleModule=new JobTitleModule();
export default jobtitleModule;