import Entity from '../entity'

export default class Configuration extends Entity<number>{
    company_id: number;
    tab: string;
    name: string;
    value: string;
}


