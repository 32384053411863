import Entity from '../entity'
import PurchaseCreditNoteDetail from './purchasecreditnotedetail';
import Supplier from '../purchases/supplier';
import Currency from '../preferences/currency';
import Purchase from './purchase';

export default class PurchaseCreditNote extends Entity<number>{
    reference: string;
    supplier_id: number;
    branch_id: number;
    currency_id: number;
    currency_sign: string;
    invoice_type_id: number;
    purchase_id: number;
    current_state_id: number;
    reason_id: number;
    exchange_rate: number;
    subtotal: number;
    subtotal_wt: number;
    taxes: number;
    discounts: number;
    total: number;
    total_wt: number;
    used_amount: number;
    balance: number;
    process_date: string;
    invoice_serie: string;
    invoice_number: string;
    invoice_date: string;
    notes: string;
    valid: boolean;
    details: Array<PurchaseCreditNoteDetail>;

    purchase_invoice_type: any;
    purchase_invoice: string;
    invoice_type_name: string;
    invoice_type: any;
    supplier: Supplier;
    currency: Currency;
    purchase: Purchase;
    supplier_document: string;
    supplier_name: string;
}


