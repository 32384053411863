import Entity from '../entity'

export default class WorkOrderVehicle extends Entity<number>{
    work_order_id: number;
    vehicle_id: number;
    vehicle_name: string;
    description: string;
    code: string;
    hours: number;
    cost_wt: number;
    total_wt: number;
}


