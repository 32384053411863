import Entity from '../entity'
import RefundDetail from './refunddetail';

export default class Refund extends Entity<number>{
    reference: string;
    number: string;
    current_state_id: number;
    refund_by_id: number;
    received_by_id: number;
    refund_type: string;
    issue_date: string;
    reception_date: string;
    notes: string;
    valid: boolean;
    refund: boolean;

    details: Array<RefundDetail>
}


