import Entity from '../entity'
import Tax from '../preferences/tax';
import MeasureUnit from '../catalogs/measureunit';
import Product from '../catalogs/product';

export default class PurchaseOrderDetail extends Entity<number>{
    purchase_order_id : number;
    product_id : number;
    product_attribute_id : number;
    warehouse_id : number;
    unity_id : number;
    tax_id : number;
    tax_rate: number;
    product_name: string;
    version: string;
    code: string;
    upc: string;
    reference: string;
    quantity: number;
    stock: number;
    exchange_rate: number;
    profit: number;
    cost: number;
    cost_wt: number;
    taxes: number;
    total: number;
    total_wt: number;
    product_cost: number;
    quantity_received: number;
    service: boolean;
    updated: boolean;

    prices: Array<any>;
    unity: MeasureUnit;
    product: Product;
    unity_sym: string;
}


