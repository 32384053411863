import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Product from '../../entities/catalogs/product'
import ProductField from '../../entities/catalogs/productfield'
import PackHotel from '@/store/entities/catalogs/packhotel';
import PageResult from '@/store/entities/page-result';
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'

interface KitState extends ListState<Product>{
    editModel: Product;
    activeList: Array<Product>;
    uploading: boolean;
    hasCombinations: boolean;
    images: Array<any>;
    hotels: Array<any>;
    currency: number;
    productType: number;
}
class KitModule extends ListModule<KitState,any,Product>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Product>(),
        loading: false,
        uploading: false,
        hasCombinations: false,
        editModel:new Product(),
        activeList: new Array<Product>(),
        images: new Array<any>(),
        hotels: new Array<any>(),
        currency: null,
        productType: null,
        path: 'kits',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<KitState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/products', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Product>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }
            context.state.list.forEach(a => {
                if (a.images != null && a.images.length > 0) {
                    a.url = a.images[0].url;
                    a.url_icon = (a.images[0].url_icon != null ? a.images[0].url_icon : a.images[0].url);
                    a.url_catalog = (a.images[0].url_catalog != null ? a.images[0].url_catalog : a.images[0].url);
                    a.url_box = (a.images[0].url_box != null ? a.images[0].url_box : a.images[0].url);
                    a.url_preview = (a.images[0].url_preview != null ? a.images[0].url_preview : a.images[0].url);
                }
            })

            return context.state.list;
        },
        async getInfo(context: ActionContext<KitState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/products/get-info/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async uploadImage(context: ActionContext<KitState, any>, payload: any) {
            context.state.uploading = true;
            let response = await Ajax.post('/api/products/upload-image', payload.data).catch(e => {
                context.state.uploading = false;
                return Promise.reject(e);
            });
            context.state.uploading = false;
            context.state.images = response.data.result;
        },
        async deleteImage(context: ActionContext<KitState, any>, payload: any) {
            context.state.uploading = true;
            let response = await Ajax.delete('/api/products/delete-image/' + payload.data.id).catch(e => {
                context.state.uploading = false;
                return Promise.reject(e);
            });
            context.state.uploading = false;
            context.state.images = context.state.images.filter((item: any) => {
                return item.id !== payload.data.id;
            });
        },
        async getAllActive(context: ActionContext<KitState, any>, payload: any) {
            if (context.state.activeList.length > 0 || context.state.loading) return true;
            let params = (payload.data == undefined ? {} : payload.data); 
            params.perPage = -1;
            params.product_type = Util.abp.setting.get('DEFAULT_PACK_TYPE');
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: params }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.activeList = reponse.data.result;
            context.state.loading = false;
        },
        async querySearch(context: ActionContext<KitState, any>, payload: any) {
            if (payload.data.query == null || payload.data.query == 'null') return [];
            if (context.state.activeList != undefined && context.state.activeList.length > 0) {
                let result = context.state.activeList.filter((item: any) => {
                    return item != null && item[payload.data.filter] != null && (item[payload.data.filter].toUpperCase().indexOf(payload.data.query.toUpperCase()) >= 0);
                });

                if (result.length > 0)
                    return result;
            }
            if (context.state.loading) return context.state.activeList;
            context.state.loading = true;
            payload.data.product_type = Util.abp.setting.get('DEFAULT_PACK_TYPE');
            let reponse = await Ajax.get('/api/products/query-search', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.activeList = reponse.data.result;
            context.state.loading = false;
            return reponse.data.result;
        }
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: KitState, model: Product) {
            state.editModel = Object.assign({});
            state.hotels = [];

            let type = Util.abp.list.getProd(Util.abp.setting.get('DEFAULT_PACK_TYPE'));
            state.editModel.price = 0;
            state.editModel.price_wt = 0;
            state.editModel.weight = 0;          
            state.editModel.cost = 0;
            state.editModel.code = '';
            state.editModel.profit = Util.abp.setting.get('PRODUCT_PROFIT');
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.line_id = Util.abp.setting.get('DEFAULT_PACK_LINE');
            state.editModel.category_id = Util.abp.setting.get('DEFAULT_PACK_CATEGORY');
            state.editModel.unity_id = Util.abp.setting.get('DEFAULT_UNITY');            
            state.editModel.product_type_id = Util.abp.setting.get('DEFAULT_PACK_TYPE');
            state.editModel.available_id = type.available_id;            
            state.editModel.pack_type_id = Util.abp.setting.get('DEFAULT_KIT_TYPE');
            state.editModel.tax_id = Util.abp.setting.get('IGV_TAXES');
            state.editModel.has_combinations = false;   
            state.currency = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.productType = state.editModel.product_type_id;
            state.hasCombinations = state.editModel.has_combinations;

            state.editModel.fields = new ProductField();
            state.editModel.fields.duration_type = 'D';
            state.editModel.fields.allow_children = false;
            state.editModel.fields.frecuency_days = [];
            state.editModel.fields.frecuency_hours = [];
            state.editModel.fields.categories = [];
            state.editModel.categories = [];
        },
        edit(state: KitState, model: Product) {
            state.editModel = model;
            state.editModel.weight = 0;
            state.currency = model.currency_id;
            state.productType = model.product_type_id;
            state.hasCombinations = model.has_combinations;

            if (state.editModel.fields == null) {
                state.editModel.fields = new ProductField();
                state.editModel.fields.duration_type = 'D';
                state.editModel.fields.allow_children = false;
                state.editModel.fields.frecuency_days = [];
                state.editModel.fields.frecuency_hours = [];
                state.editModel.fields.categories = [];
            }
            state.editModel.categories = state.editModel.fields.categories;
        },
        setImagesList(state: KitState, images: Array<any>) {
            state.images = images;
            state.images.forEach(img => {
                img.url = url + img.url;
            });
        },
        setProductType(state: KitState, id: number) {
            let type = Util.abp.list.getProd(id);
            state.editModel.product_type_id = id;
            state.editModel.available_id = type.available_id;
            state.productType = id;
        },
        addHotel(state: KitState, product: Product) {
            let exist = state.hotels.filter((detail: any) => {
                return detail.product_id == product.id;
            });

            if (exist.length == 0) {
                let detail = new PackHotel();
                detail.hotel_id = product.id;
                detail.price = product.price;
                detail.price_wt = product.price_wt;
                detail.hotel = product;
                detail.rooms = [];
                if (product.fields != null && product.fields.room_prices != null) {
                    detail.rooms = product.fields.room_prices.map(a => {
                        let value = a.id;
                        let roT = Util.abp.list.getByValue('T_ROOM', value);
                        let newData: any = {
                            hotel_id: product.id,
                            room_type_id: null,
                            price: a.price,
                            total: a.price,
                        };
                        if (roT != null) {
                            newData.room_type_id = roT.id;
                            newData.name = roT.name;
                            newData.code = roT.code;
                        }

                        return newData;
                    });
                }

                state.hotels.push(detail);
            }
        },
        deleteHotel(state: KitState, index: number) {
            state.hotels.splice(index, 1);
        },
        setHotelList(state: KitState, list: Array<any>) {
            state.hotels = list;
        },
        setCurrency(state: KitState, id: number) {
            state.currency = id;
        },
        setProductName(state: KitState, name: string) {
            state.editModel.name = name;
        },
        setCombinations(state: KitState, value: boolean) {
            state.hasCombinations = value;
        },
    });
}
const productModule=new KitModule();
export default productModule;