import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ProductItinerary from '../../entities/catalogs/productitinerary'
import Util from '../../../lib/util'

interface ProductItineraryState extends ListState<ProductItinerary>{
    editModel:ProductItinerary;
    product: number;
    activeList: Array<ProductItinerary>;
}
class ProductItineraryModule extends ListModule<ProductItineraryState,any,ProductItinerary>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 5,
        list: new Array<ProductItinerary>(),
        loading:false,
        editModel:new ProductItinerary(),
        activeList: new Array<ProductItinerary>(),
        product: 0,
        path: 'product-itineraries',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations  
        new(state: ProductItineraryState, model: ProductItinerary) {
            state.editModel = Object.assign({});
            state.editModel.product_id = state.product;
            state.editModel.day = 1;
        },
        setProduct(state: ProductItineraryState, product: number) {
            state.product = product;
        },
    });
}
const productitineraryModule=new ProductItineraryModule();
export default productitineraryModule;