import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Category from '../../entities/catalogs/category'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface CategoryState extends ListState<Category>{
    editModel: Category;
    viewModel: Category;
    parent: number;
    history: Array<any>;    
    tree: Array<any>;    
}
class CategoryModule extends ListModule<CategoryState, any, Category>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Category>(),
        activeList: new Array<Category>(),
        loading:false,
        editModel:new Category(),
        viewModel:new Category(),
        path: 'categories',
        parent: 0,
        history: new Array<any>(),
        tree: new Array<any>(),
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastCode(context: ActionContext<CategoryState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/categories/last-code', { params: payload.data }).then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
        async getCategoryTree(context: ActionContext<CategoryState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/categories-tree', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.tree = reponse.data.result;
            return context.state.tree;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: CategoryState, model: Category) {
            state.editModel = Object.assign({});
            state.editModel.code = 0;
        },
        edit(state: CategoryState, model: Category) {
            state.editModel = model;
        },
        setLine(state: CategoryState, id: number) {
            state.editModel.line_id = id;
        },
    });
}
const categoryModule=new CategoryModule();
export default categoryModule;