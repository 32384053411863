import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Carrier from '../../entities/stocks/carrier'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Address from '@/store/entities/customers/address';

interface CarrierState extends ListState<Carrier>{
    editModel: Carrier;
}
class CarrierModule extends ListModule<CarrierState, any, Carrier>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Carrier>(),
        loading:false,
        editModel:new Carrier(),
        activeList: new Array<Carrier>(),
        path: 'carriers'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<CarrierState, any>, payload: any) {
            context.state.loading = true;
            if (payload.data.address != null && payload.data.address.ubigeo != null) {
                payload.data.address.department_id = payload.data.address.ubigeo.department;
                payload.data.address.province_id = payload.data.address.ubigeo.province;
                payload.data.address.district_id = payload.data.address.ubigeo.district;
            }

            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async update(context: ActionContext<CarrierState, any>, payload: any) {
            context.state.loading = true;
            if (payload.data.address != null && payload.data.address.ubigeo != null) {
                payload.data.address.department_id = payload.data.address.ubigeo.department;
                payload.data.address.province_id = payload.data.address.ubigeo.province;
                payload.data.address.district_id = payload.data.address.ubigeo.district;
            }

            await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async get(context: ActionContext<CarrierState, any>, payload: any) {
            if (payload.id == undefined) return {};
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.loading = false;
            return reponse.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: CarrierState, model: Carrier) {
            state.editModel = Object.assign({});
            state.editModel.address = new Address();
            state.editModel.address.country_id = Util.abp.setting.get('DEFAULT_COUNTRY');
            state.editModel.address.ubigeo = {
                department: 4,
                province: 35,
                district: 330
            };
            state.editModel.address.department_id = 4;
            state.editModel.address.province_id = 35;
            state.editModel.address.district_id = 330;
        },
        edit(state: CarrierState, model: Carrier) {
            state.editModel = model;
            if (state.editModel.address == null) {
                state.editModel.address = new Address();
                state.editModel.address.country_id = Util.abp.setting.get('DEFAULT_COUNTRY');
            }
        },
    });
}
const carrierModule=new CarrierModule();
export default carrierModule;