import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Tab from '../../entities/admin/tab'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface TabState extends ListState<Tab>{
    editModel:Tab;
    loadingTab:boolean;  
}
class TabModule extends ListModule<TabState,any,Tab>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Tab>(),
        loading:false,
	loadingTab:false,
        editModel: new Tab(),
        activeList: new Array<Tab>(),
        path: 'tabs'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getTabs(context: ActionContext<TabState, any>, payload: any) {
            context.state.loadingTab = true;
            let result = await Ajax.get("/api/tabs-tree");
            context.state.loadingTab = false;
            return result.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const tabModule=new TabModule();
export default tabModule;