import Entity from '../entity'

export default class Table extends Entity<number>{
    company_id: number;
    room_id: number;
    name: string;
    number: number;
    shape: number;
    size: string;
    position_x: number;
    position_y: number;
}


