import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PurchaseOrder from '@/store/entities/purchases/purchaseorder';
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Supplier from '@/store/entities/purchases/supplier';
import PurchaseOrderDetail from '@/store/entities/purchases/purchaseorderdetail';
import PurchaseOrderHistory from '@/store/entities/purchases/history';
import PurchaseQuotation from '@/store/entities/purchases/purchasequotation';
import PurchaseRequest from '@/store/entities/purchases/purchaserequest';
import PageResult from '@/store/entities/page-result';

interface PurchaseOrderState extends ListState<PurchaseOrder>{
    editModel: PurchaseOrder;
    supplier: number;
    hisLoading: boolean;
    hasChanges: boolean;
    history: Array<PurchaseOrderHistory>;
    reportList: Array<any>;
    filename: string;
}
class PurchaseOrderModule extends ListModule<PurchaseOrderState, any,PurchaseOrder>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PurchaseOrder>(),
        loading:false,
        hisLoading:false,
        hasChanges: false,
        editModel: new PurchaseOrder(),
        supplier: null,
        activeList: new Array<PurchaseOrder>(),
        history: new Array<PurchaseOrderHistory>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'purchase-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<PurchaseOrder>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }

            if (payload.data.filter_for == 'ENTRY') {
                context.state.list.forEach(item => {
                    item.pending_quantity = 0;
                    item.details.forEach(det => {
                        item.pending_quantity += (det.quantity - det.quantity_received);
                    });
                });
            }

            return context.state.list;
        },
        async get(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id);
            context.state.editModel = reponse.data.result as PurchaseOrder;
            context.state.loading = false;
            return context.state.editModel;
        },
        async create(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async update(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async getLastNumber(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/purchase-orders/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            context.state.hisLoading = true; 
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/purchase-orders/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async confirm(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/purchase-orders/confirm', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            context.state.editModel.current_state_id = response.data.result.current_state_id;
            context.state.editModel.confirm_date = response.data.result.confirm_date;
            context.state.editModel.valid = response.data.result.valid;

            return response.data.result;
        },
        async receiveProducts(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/purchase-orders/receive', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return true;
        },
        async print(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.get('/api/purchase-order-pdf', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            return response.data.result;
        },
        async downloadOrder(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            context.state.loading = true;
            payload.data.downloadPDF = true;
            await Ajax.get('/api/purchase-order-pdf', {
                responseType: 'blob',
                params: payload.data
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', payload.data.filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async send(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.post('/api/purchase-orders/send', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;

            return response.data.result;
        },
        async downloadForImport(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.get('/api/purchase-order-for-import', {
                responseType: 'blob',
                params: payload.data
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', payload.data.filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },

        async reportHistoryCost(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/purchase-history-cost', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result;
            context.state.loading = false;
        },
        async reportOutputs(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/purchase-outputs', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportTopSuppliers(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/top-suppliers', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportTopProducts(context: ActionContext<PurchaseOrderState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/top-purchase-products', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: PurchaseOrderState, model: PurchaseOrder) {
            var today = Util.abp.clock.today();
            state.supplier = null;
            let serie = Util.abp.setting.get('P_ORDER_SERIE');

            state.editModel = Object.assign({});
            state.editModel.issue_date = today;
            state.editModel.order_date = today;
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PURCHASE_COSTCENTER');     
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_PURCHASE_CURRENCY');
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.currency = null;
            state.editModel.taxes = 0.00;
            state.editModel.total = 0.00;
            state.editModel.total_wt = 0.00;
            state.editModel.quotation_number = '';
            state.editModel.supplier_id = null;
            state.editModel.supplier_document = '';
            state.editModel.supplier_address = '';
            state.editModel.supplier_name = '';
            state.editModel.credit_days = 0;
            state.editModel.serie = (serie == 'YYYY' ? today.substr(0, 4) : serie);
            state.editModel.number = '0000';
            state.editModel.for_import = false;            
            state.editModel.details = new Array<PurchaseOrderDetail>();

            state.editModel.user_id = Util.abp.session.userId;
        },
        edit(state: PurchaseOrderState, model: PurchaseOrder) {
            state.editModel = model;
            state.editModel.currency_sign = model.currency.sign;
            state.hasChanges = false;
        },
        setCurrency(state: PurchaseOrderState, currency: any) {
            state.editModel.currency = currency;
            state.editModel.currency_id = currency.id;
            state.editModel.currency_sign = currency.sign;
            state.editModel.exchange_rate = currency.conversion_rate;
            state.hasChanges = true;
        },
        setSupplier(state: PurchaseOrderState, data: any) {
            state.editModel.supplier = data.supplier;
            state.editModel.supplier_id = data.supplier_id;
            state.editModel.supplier_document = data.supplier_document;
            state.editModel.supplier_name = data.supplier_name;
            state.supplier = data.supplier_id;
            state.hasChanges = true;
        },
        updateAmount(state: PurchaseOrderState, details: Array<PurchaseOrderDetail>) {
            state.editModel.taxes = 0;
            state.editModel.total = 0;
            state.editModel.total_wt = 0;

            details.forEach((item, index) => {
                state.editModel.total += item.total;
                state.editModel.total_wt += item.total_wt;
            });

            state.editModel.taxes = state.editModel.total_wt - state.editModel.total;
            state.hasChanges = true;
        },
        loadFromRequest(state: PurchaseOrderState, model: PurchaseRequest) {
            if (model == undefined) return;

            state.editModel.reference = model.reference;
            state.editModel.purchase_planning_id = model.purchase_planning_id;
            state.editModel.purchase_request_id = model.id;
            state.editModel.work_order_id = model.work_order_id;
            state.editModel.request_number = model.nro;
            state.editModel.cost_center_id = model.cost_center_id;
            state.editModel.currency_id = model.currency_id;
            state.editModel.currency_sign = model.currency_sign;
            state.editModel.currency = model.currency;
            state.editModel.exchange_rate = model.exchange_rate;
            state.editModel.issue_date = model.order_date;
            state.editModel.for_import = false;
            state.editModel.taxes = 0;
            state.editModel.total = 0;
            state.editModel.total_wt = 0;

            model.details.forEach((item, index) => {
                let det = new PurchaseOrderDetail();
                det = Util.extend(true, {}, item);
                delete det.id;
                
                det.warehouse_id = Util.abp.session.warehouseId;
                det.tax_id = Util.abp.setting.get('IGV_TAXES');
                det.tax_rate = Util.abp.setting.get('TAX_RATE');
                det.exchange_rate = model.exchange_rate;
                det.profit = 0;
                det.cost = Util.removeTaxes(det.cost_wt, det.tax_rate);
                det.total = det.cost * det.quantity;
                det.total_wt = det.cost_wt * det.quantity;
                det.taxes = det.total_wt - det.total;
                det.quantity_received = 0;
                det.updated = false;
                state.editModel.details.push(det);

                state.editModel.total += det.total;
                state.editModel.total_wt += det.total_wt;
            });

            state.editModel.taxes = state.editModel.total_wt - state.editModel.total;
        },
        loadFromQuotation(state: PurchaseOrderState, data) {
            if (data.model == undefined) return;

            //{ model: PurchaseQuotation, details: Array<any> }
            state.editModel.details = [];
            state.editModel.reference = data.model.reference;
            state.editModel.purchase_planning_id = data.model.purchase_planning_id;
            state.editModel.purchase_request_id = data.model.purchase_request_id;
            state.editModel.purchase_quotation_id = data.model.id;
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PURCHASE_COSTCENTER');     
            state.editModel.work_order_id = data.model.work_order_id;
            state.editModel.request_number = data.model.request_number;
            state.editModel.branch_id = data.model.branch_id;
            state.editModel.currency_id = data.model.currency_id;
            state.editModel.payment_type_id = data.model.payment_type_id;
            state.editModel.exchange_rate = data.model.exchange_rate;
            state.editModel.issue_date = data.model.issue_date;
            state.editModel.quotation_number = data.model.serie + '-' + data.model.number;
            state.editModel.taxes = 0;
            state.editModel.total = 0;
            state.editModel.total_wt = 0;
            state.editModel.for_import = false;
            state.editModel.supplier = new Supplier();
            state.editModel.supplier.id = data.supplier.supplier_id,
            state.editModel.supplier.document = data.supplier.supplier_document,
            state.editModel.supplier.name = data.supplier.supplier_name,            
            state.editModel.supplier_id = data.supplier.supplier_id;
            state.editModel.supplier_document = data.supplier.supplier_document;
            state.editModel.supplier_name = data.supplier.supplier_name;

            data.details.forEach((item, index) => {
                if (item.purchase_quotation_supplier_id == data.supplier.id) {
                    let det = new PurchaseOrderDetail();
                    det = Util.extend(true, {}, item);
                    det.quantity_received = 0;
                    det.updated = false;
                    delete det.id;
                    state.editModel.details.push(det);
                }
            });            

            state.editModel.details.forEach((item, index) => {
                state.editModel.total += item.total;
                state.editModel.total_wt += item.total_wt;
            });

            state.editModel.taxes = state.editModel.total_wt - state.editModel.total;
        },
        loadFromPlanning(state: PurchaseOrderState, data) {
            if (data.model == undefined) return;
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);
            
            state.editModel.reference = data.model.reference;
            state.editModel.purchase_planning_id = data.model.id;
            //state.editModel.purchase_request_id = data.model.purchase_request_id;
            //state.editModel.purchase_quotation_id = data.model.purchase_quotation_id;
            //state.editModel.request_number = data.model.request_number;
            state.editModel.branch_id = data.model.branch_id;
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_PURCHASE_CURRENCY');
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PURCHASE_COSTCENTER');     
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.for_import = false;
            //state.editModel.quotation_number = data.model.serie + '-' + data.model.number;
            state.editModel.taxes = 0;
            state.editModel.total = 0;
            state.editModel.total_wt = 0;
            state.editModel.supplier = data.products[0].supplier;
            state.editModel.supplier_id = data.products[0].supplier_id;
            state.editModel.supplier_document = data.products[0].supplier.document;
            state.editModel.supplier_name = data.products[0].supplier_name;

            data.products.forEach((item, index) => {
                let quantityVal = item.quantity_ordered < item.quantity && item.quantity_request < item.quantity;
                if (Util.abp.setting.get('ENABLE_LOAD_PURCHASE_REQUEST') == 1) 
                    quantityVal = item.quantity_ordered < item.quantity;
                
                if (quantityVal) {
                    let det = new PurchaseOrderDetail();
                    det = Util.extend(true, {}, item);
                    if (item.quantity_request > 0)
                        det.quantity = item.quantity - item.quantity_request;
                    else
                        det.quantity = item.quantity - item.quantity_ordered;

                    if (Util.abp.setting.get('ENABLE_LOAD_PURCHASE_REQUEST') == 1)
                        det.quantity = item.quantity - item.quantity_ordered;

                    det.total_wt = det.quantity * det.cost_wt;
                    det.quantity_received = 0;
                    det.tax_id = Util.abp.setting.get('IGV_TAXES');
                    det.tax_rate = Util.abp.setting.get('TAX_RATE');
                    det.updated = false;

                    delete det.id;
                    state.editModel.details.push(det);
                }
            });

            state.editModel.details.forEach((item, index) => {
                state.editModel.total += item.total;
                state.editModel.total_wt += item.total_wt;
            });

            state.editModel.taxes = state.editModel.total_wt - state.editModel.total;
        },
        setChanged(state: PurchaseOrderState, value: boolean) {
            state.hasChanges = value;
        },
        setBranch(state: PurchaseOrderState, branch: number) {
            state.editModel.branch_id = branch;
        },
        setReportList(state: PurchaseOrderState, list: Array<any>) {
            state.reportList = list;
            state.totalCount = list.length;
        },
    });
}
const orderModule = new PurchaseOrderModule();
export default orderModule;