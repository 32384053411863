import Entity from '../entity'
import TransferOrderDetail from './transferorderdetail';

export default class TransferOrder extends Entity<number>{
    reference: string;
    serie: string;
    number: string;
    warehouse_out_id: number;
    warehouse_in_id: number;
    current_state_id: number;
    delivered_by_id: number;
    received_by_id: number;
    transfer_date: string;
    received_date: string;
    notes: string;
    delivered: boolean;
    received: boolean;
    referral_guide: string;
    entity_id: number;
    entity: string;
    details: Array<TransferOrderDetail>;

    nro: string;
}


