import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import MeasureUnit from '../../entities/catalogs/measureunit'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface MeasureUnitState extends ListState<MeasureUnit>{
    editModel: MeasureUnit;
    activeList: Array<MeasureUnit>;
    filteredList: Array<MeasureUnit>;
}
class MeasureUnitModule extends ListModule<MeasureUnitState, any, MeasureUnit>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<MeasureUnit>(),
        loading:false,
        editModel:new MeasureUnit(),
        activeList: new Array<MeasureUnit>(),
        filteredList: new Array<MeasureUnit>(),
        path: 'measure-units'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAllActive(context: ActionContext<MeasureUnitState, any>, payload: any) {
            if (Util.abp.setting.get('ENABLE_UNITS') != 1) return true;
            if (context.state.activeList.length > 0) return true;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: { perPage: -1 } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.activeList = reponse.data.result;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: MeasureUnitState, model: MeasureUnit) {
            state.editModel = Object.assign({});
            state.editModel.name = null;
            state.editModel.parent_id = null;
            state.editModel.precision = 0;
        },
        edit(state: MeasureUnitState, model: MeasureUnit) {
            state.editModel = model;
            state.filteredList = state.activeList.filter(item => {
                return item.id != model.id;
            });
        },
    });
}
const measureunitModule=new MeasureUnitModule();
export default measureunitModule;