import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module' 
import ListState from '../list-state'
import PurchasePlanning from '@/store/entities/purchases/purchaseplanning';
import PurchasePlanningProduct from '@/store/entities/purchases/purchaseplanningproduct';
import PurchasePlanningHistory from '@/store/entities/purchases/history';
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface PurchasePlanningState extends ListState<PurchasePlanning>{
    editModel: PurchasePlanning;         
    hisLoading: boolean;      
    suggLoading: boolean;      
    history: Array<PurchasePlanningHistory>;
    products: Array<PurchasePlanningProduct>;
    suggestion: Array<any>;
    documents: Array<any>;
}
class PurchasePlanningModule extends ListModule<PurchasePlanningState, any,PurchasePlanning>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PurchasePlanning>(),
        loading:false,
        hisLoading:false,
        suggLoading:false,
        editModel: new PurchasePlanning(),
        activeList: new Array<PurchasePlanning>(),
        history: new Array<PurchasePlanningHistory>(),
        products: new Array<PurchasePlanningProduct>(),
        suggestion: new Array<any>(),
        documents: new Array<any>(),
        path: 'purchase-planning'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async update(context: ActionContext<PurchasePlanningState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            context.state.activeList = [];
            return response.data.result;
        },
        async getLastNumber(context: ActionContext<PurchasePlanningState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/purchase-planning/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.code = result;

            return result;
        },
        async getHistory(context: ActionContext<PurchasePlanningState, any>, payload: any) {
            context.state.hisLoading = true; 
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/purchase-planning/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async get(context: ActionContext<PurchasePlanningState, any>, payload: any) {
            if (payload.id == undefined) return {};
            
            context.state.loading = true;
            let reponse = await Ajax.get('/api/purchase-planning/get/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            let item = reponse.data.result as PurchasePlanning;
            context.state.products = item.products;
            context.state.loading = false;
            return item;
        },
        async getSuggestion(context: ActionContext<PurchasePlanningState, any>, payload: any) {
            context.state.suggLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/purchase-suggestion', { params: payload.data }).catch(e => {
                context.state.suggLoading = false;
                return Promise.reject(e);
            });
            context.state.suggestion = reponse.data.result;
            context.state.suggLoading = false;
        },
        async requestBudget(context: ActionContext<PurchasePlanningState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/purchase-planning/request-budget', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async finish(context: ActionContext<PurchasePlanningState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/purchase-planning/finish', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async download(context: ActionContext<PurchasePlanningState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/purchase-planning/download', { params: payload.data}).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async print(context: ActionContext<PurchasePlanningState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.get('/api/purchase-planning-pdf', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: PurchasePlanningState, model: PurchasePlanning) {
            var today = new Date();

            state.editModel = Object.assign({});
            state.editModel.year = parseInt(Util.abp.clock.today().substr(0, 4));
            state.editModel.month = parseInt(Util.abp.clock.today().substr(5, 2));
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.code = state.editModel.year + "-000";
            state.documents = [];
        },
        edit(state: PurchasePlanningState, model: PurchasePlanning) {
            state.editModel = model;
            if (model.documents != undefined && model.documents != null)
                state.documents = model.documents;
        },
        setDocuments(state: PurchasePlanningState, model: PurchasePlanning) {
            if (model.documents != undefined && model.documents != null)
                state.documents = model.documents;
        },
    });
}
const orderModule = new PurchasePlanningModule();
export default orderModule;