import Entity from '../entity'

export default class BookingGuest extends Entity<number>{
    booking_id: number;
    customer_id: number;
    seq: number;
    guest_name: string;
    default: boolean;

    customer: any;
}


