import Entity from '../entity'

export default class CorrespDocument extends Entity<number>{
    receiver: string;
    address: string;
    contact: string;
    phone : string;
    destination: string;
    copies: number;
}


