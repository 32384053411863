import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Address from '../../entities/customers/address'
import Util from '../../../lib/util'
import Department from '@/store/entities/preferences/department';
import Province from '@/store/entities/preferences/province';
import District from '@/store/entities/preferences/district';

interface AddressState extends ListState<Address>{
    editModel:Address;
    activeDepts0: Array<Department>;
    activeDepts1: Array<Department>;
    activeDepts2: Array<Department>;
    activeProvs0: Array<Province>;
    activeProvs1: Array<Province>;
    activeProvs2: Array<Province>;
    activeDists0: Array<District>;
    activeDists1: Array<District>;
    activeDists2: Array<District>;
}
class AddressModule extends ListModule<AddressState,any,Address>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Address>(),
        loading:false,
        editModel: new Address(),
        activeList: new Array<Address>(),
        activeDepts0: new Array<Department>(),
        activeDepts1: new Array<Department>(),
        activeDepts2: new Array<Department>(),
        activeProvs0: new Array<Province>(),
        activeProvs1: new Array<Province>(),
        activeProvs2: new Array<Province>(),
        activeDists0: new Array<District>(),
        activeDists1: new Array<District>(),
        activeDists2: new Array<District>(),
        path: 'customers'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setList(state: AddressState, list: Array<Address>) {
            if (list != null) {
                list.forEach((ad, index) => {
                    ad.index = index;
                });
                state.list = list;
            }
        },
        setDepts(state: AddressState, data: any) {
            if (data.list != null) {
                state['activeDepts' + data.index] = data.list;
            }
        },
        setProvs(state: AddressState, data: any) {
            if (data.list != null) {
                state['activeProvs' + data.index] = data.list;
            }
        },
        setDists(state: AddressState, data: any) {
            if (data.list != null) {
                state['activeDists' + data.index] = data.list;
            }
        },
        setLastAddress(state: AddressState, data: any) {
            if (state.list != null) {
                state.list[0].address1 = data.address1;
                state.list[0].country_id = data.country_id;
                state.list[0].department_id = data.department_id;
                state.list[0].province_id = data.province_id;
                state.list[0].district_id = data.district_id;
                state.list[0].ubigeo = {
                    department: data.department_id,
                    province: data.province_id,
                    district: data.district_id,
                };
            }
        }
    });
}
const addressModule=new AddressModule();
export default addressModule;