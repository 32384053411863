import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import StockAvailable from '../../entities/stocks/stockavailable'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface StockAvailableState extends ListState<StockAvailable>{
    editModel: StockAvailable;
    activeList: Array<StockAvailable>;
}
class StockAvailableModule extends ListModule<StockAvailableState, any, StockAvailable>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<StockAvailable>(),
        loading:false,
        editModel:new StockAvailable(),
        activeList: new Array<StockAvailable>(),
        path: 'stock-availables'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const stockavailableModule=new StockAvailableModule();
export default stockavailableModule;