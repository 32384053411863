import Entity from '../entity'
import Address from '../customers/address';

export default class Carrier extends Entity<number>{
    document_type_id: number;
    address_id: number;
    supplier_id: number;
    name_legal: string;
    driving_license: string;
    document: string;
    driver_name: string;
    contact_name: string;
    active: boolean;

    address: Address;
}


