import Entity from '../entity'

export default class Warehouse extends Entity<number>{
    branch_id: number;
    currency_id: number;
    address_id: number;
    employee_id: number;
    management_type_id: number;
    reference: string;
    name: string;
    active: boolean;
    for_sales: boolean;
}


