import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import SaleInvoice from '../../entities/sales/saleinvoice'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import appconst from '../../../lib/appconst'
import PageResult from '@/store/entities/page-result';
import Sale from '@/store/entities/sales/sale';

interface SaleInvoiceState extends ListState<SaleInvoice>{
    editModel:SaleInvoice;
}
class SaleInvoiceModule extends ListModule<SaleInvoiceState,any,SaleInvoice>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<SaleInvoice>(),
        loading:false,
        editModel: new SaleInvoice(),
        activeList: new Array<SaleInvoice>(),
        path: 'sale-invoices'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getPendingInvoices(context: ActionContext<SaleInvoiceState, any>, payload: any) {
            context.state.totalCount = 0;
            context.state.list = [];
            context.state.loading = true;
            let reponse = await Ajax.get('/api/sales/pending-invoices', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as any;
            context.state.totalCount = page.total;
            context.state.list = page.data;
        },
        async getProcessedInvoices(context: ActionContext<SaleInvoiceState, any>, payload: any) {
            context.state.totalCount = 0;
            context.state.list = [];
            context.state.loading = true;
            let reponse = await Ajax.get('/api/sales/processed-invoices', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as any;
            context.state.totalCount = page.total;
            context.state.list = page.data;
        },
        async invoice(context: ActionContext<SaleInvoiceState, any>, payload: any) {
            context.state.loading = true; 
            let response = await Ajax.post('/api/sale-invoices', payload.data).catch(e => {
                context.state.loading = false;
                return false;
            });
            context.state.loading = false;
            if (!response)
                return response;
            else if ((response as any).data != undefined) 
                return (response as any).data.result;
            return false;
        },
        async downloadInvoiceFile(context: ActionContext<SaleInvoiceState, any>, payload: any) {
            let enableNube = Util.abp.setting.get('NUBEFACT_EINVOICE_ENABLE');
            let enableSunat = Util.abp.setting.get('SUNAT_EINVOICE_ENABLE');
            context.state.loading = true;
            let data = { params: payload.data };
            if (enableSunat == 1) data['responseType'] = 'blob';
            let response = await Ajax.get('/api/sale-invoices-download', data).then(response => {
                const link = document.createElement('a');
                if (enableNube == 1) {
                    const url = response.data.result;
                    link.href = url;
                    if (payload.data.type == 'pdf')
                        link.target = '_blank';
                } else if (enableSunat == 1) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    link.href = url;
                }
                link.setAttribute('download', payload.data.filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const saleinvoiceModule=new SaleInvoiceModule();
export default saleinvoiceModule;