import Entity from '../entity'

export default class Room extends Entity<number>{
    company_id: number;
    branch_id: number;
    name: string;
    height: number;
    width: number;
    number: number;
    active: boolean;

    tables: Array<any>;
}


