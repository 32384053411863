import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PurchaseCreditNoteDetail from '../../entities/purchases/purchasecreditnotedetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import PurchaseCreditNote from '@/store/entities/purchases/purchasecreditnote';

interface PurchaseCreditNoteDetailState extends ListState<PurchaseCreditNoteDetail>{
    editModel:PurchaseCreditNoteDetail;    
    purchasecreditnote:number;    
}
class PurchaseCreditNoteDetailModule extends ListModule<PurchaseCreditNoteDetailState,any,PurchaseCreditNoteDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PurchaseCreditNoteDetail>(),
        loading:false,
        editModel: new PurchaseCreditNoteDetail(),
        activeList: new Array<PurchaseCreditNoteDetail>(),
        purchasecreditnote: 0,
        path: 'purchase-credit-notes'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<PurchaseCreditNoteDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: PurchaseCreditNoteDetailState, model: PurchaseCreditNoteDetail) {
            state.editModel = model;
        },
        init(state: PurchaseCreditNoteDetailState, model: PurchaseCreditNote) {
            state.editModel = Object.assign({});
            if (model != undefined) {
                state.purchasecreditnote = model.id;
                state.list = model.details;
            }
        },
        setProduct(state: PurchaseCreditNoteDetailState, product: any) {
            state.editModel.product = product;
            state.editModel.product.manufacturer_name = product.manufacturer.name;
            state.editModel.product.category_name = product.category_default != undefined ? product.category_default.name : '';

        },
        add(state: PurchaseCreditNoteDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id && detail.product_attribute_id == product['product_attribute_id'];
            });

            if (exist.length > 0) {
                exist[0].quantity++;
                exist[0].total = exist[0].quantity * exist[0].cost;
                exist[0].total_wt = exist[0].quantity * exist[0].cost_wt
                exist[0].taxes = parseFloat((exist[0].total_wt - exist[0].total).toFixed(3));
            } else {
                let detail = new PurchaseCreditNoteDetail();
                detail.purchase_credit_note_id = state.editModel.id;
                detail.product_id = product.id;
                detail.product_attribute_id = product['product_attribute_id'];
                detail.warehouse_id = (product['warehouse_id'] != undefined ? product['warehouse_id'] : Util.abp.session.warehouseId);
                detail.unity_id = product.unity_id;
                detail.code = product.code;
                detail.upc = product.upc;
                detail.product_name = Util.formatProdName(product);
                detail.version = product.version;
                detail.quantity = 1;
                detail.cost = product.cost;
                detail.cost_wt = product.cost_wt;
                detail.total = detail.quantity * detail.cost;
                detail.total_wt = detail.quantity * detail.cost_wt
                detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(3));
                detail.discounts = 0;                
                detail.service = product['service'];
                
                state.list.push(detail);
            }
        },
        delete(state: PurchaseCreditNoteDetailState, index: number) {
            state.list.splice(index, 1);
        },
        save(state: PurchaseCreditNoteDetailState, detail: PurchaseCreditNoteDetail) {
            for (var i = 0; i < state.list.length; i++) {
                if (state.list[i].id == detail.id) {
                    state.list[i] = detail;
                }
            }
        },
    });
}
const purchasecreditnotedetailModule=new PurchaseCreditNoteDetailModule();
export default purchasecreditnotedetailModule;