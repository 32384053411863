import Entity from '../entity'

export default class Application extends Entity<number>{
    name: string;
    description: string;
    icon: string;
    active:boolean;
}


