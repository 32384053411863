import Entity from '../entity'

export default class PurchaseImportExpense extends Entity<number>{
    import_id: number;
    expend_type_id: number;
    currency_id: number;
    item_nro: number;
    document: string;
    exchange_rate: number;
    total: number;
}


