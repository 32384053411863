import Entity from '../entity'

export default class SpecialPriceRule extends Entity<number>{
    name: string;
    groups: Array<number>;
    customers: Array<number>;
    payment_methods: Array<number>;
    lines: Array<number>;
    categories: Array<number>;
    products: Array<string>;
    price_rules: Array<number>;
    available: string;
    mode: string;
    discount_type: string;
    discount: number;
    from: string;
    to: string;
    apply_cost: boolean;
    from_quantity: number;
    active: boolean;

    product_names: Array<any>;
}


