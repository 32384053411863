import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Area from '../../entities/admin/area'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface AreaState extends ListState<Area>{
    editModel: Area;
    activeList: Array<Area>;
}
class AreaModule extends ListModule<AreaState, any, Area>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Area>(),
        loading:false,
        editModel:new Area(),
        activeList: new Array<Area>(),
        path: 'areas'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastCode(context: ActionContext<AreaState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/areas/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.code = result;

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: AreaState, model: Area) {
            state.editModel = Object.assign({});
            state.editModel.code = '';
        },
        setActiveList(state: AreaState, locations: Array<Area>) {
            if (locations != null) {
                state.activeList = locations;
            } else
                state.activeList = new Array<Area>();
        },
    });
}
const areaModule=new AreaModule();
export default areaModule;