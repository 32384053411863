import Entity from '../entity'

export default class RepairOrderPlan extends Entity<number>{
    repair_order_id: number;
    activity_id: number;
    state_id: number;
    employee_id: number;
    activity_name: string;
    duration_type: string;
    time_estimated: number;
    duration_expected: number;
    duration: number;
    hour_rate: number;
    estimated_start_date: string;
    in_process: boolean;
    start_date: string;
    last_start_date: string;
    finish_date: string;

    employee_name: string;
}


