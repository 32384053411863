import Entity from '../entity'
import PurchaseDetail from './purchasedetail';
import Supplier from './supplier';
import Currency from '../preferences/currency';

export default class Purchase extends Entity<number>{
    reference: string;
    number: number;
    supplier_id : number;
    branch_id : number;
    currency_id : number;
    cost_center_id: number;
    purchase_planning_id: number;
    purchase_order_id : number;
    invoice_type_id : number;
    payment_type_id : number;
    current_state_id : number;
    tax_id : number;
    tax_rate: number;
    including_taxes: boolean;
    exchange_rate: number;
    discount_type: string;
    insurance: number;
    subtotal: number;
    subtotal_wt: number;
    taxes: number;
    discounts: number;
    total: number;
    total_wt: number;
    total_paid: number;
    total_return: number;
    balance: number;
    issue_date: string;
    due_date: string;
    invoice_serie: string;
    invoice_number: string;
    notes: string;
    valid: boolean;
    order_number: string;
    referral_guide: string;
    credit_days: number;
    partial_received: boolean;
    received: boolean;
    returned: boolean;
    creditnote: number;
    debitnote: number;
    edit_allowed: boolean;

    details: Array<PurchaseDetail>;
    current_state: any;
    currency_sign: string;
    invoice_type_name: string;
    invoice_type: any;
    supplier_name: string;
    supplier_document: string;
    supplier_address: string;
    currency: Currency;
    entries: Array<any>;
    origin_document: string;
    invoice: string;
}


