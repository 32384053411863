import Entity from '../entity'
import Address from './address'

export default class Customer extends Entity<number>{
    code: string;
    document: string;
    document_full: string;
    company: string;
    fullname: string;
    firstname: string;
    lastname: string;
    email: string;
    birthdate: string;
    representative: string;
    contact: string;
    services: string;
    active: boolean;
    rejected: boolean;
    gender_id: number;
    type_id: number;
    person_type_id: number;
    document_type_id: number;
    group_id: number;
    price_rule_id: number;
    organization_id: number;
    credit_line: number;
    addresses: Array<Address>;

    username: string;
    group: any;
    document_type: any;
    address_full: string;
}


