import Entity from '../entity'
import CashierHistory from './cashierhistory';
import Currency from '../preferences/currency';

export default class Cashier extends Entity<number>{
    branch_id: number;
    currency_id: number;
    name: string;
    active: boolean;

    currency: Currency;
    history: CashierHistory;
}


