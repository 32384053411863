import Entity from '../entity'
import CreditNoteDetail from './creditnotedetail';
import Customer from '../customers/customer';
import Currency from '../preferences/currency';
import Sale from './sale';

export default class CreditNote extends Entity<number>{
    reference: string;
    customer_id: number;
    branch_id: number;
    currency_id: number;
    currency_sign: string;
    invoice_type_id: number;
    sale_id: number;
    current_state_id: number;
    reason_id: number;
    exchange_rate: number;
    subtotal: number;
    subtotal_wt: number;
    taxes: number;
    discounts: number;
    total: number;
    total_wt: number;
    used_amount: number;
    balance: number;
    process_date: string;
    invoice_serie: string;
    invoice_number: string;
    invoice_date: string;
    notes: string;
    valid: boolean;
    invoiced: boolean;
    details: Array<CreditNoteDetail>;

    sale_invoice_type: any;
    sale_invoice: string;
    invoice_type_name: string;
    invoice_type: any;
    customer: Customer;
    currency: Currency;
    sale: Sale;
    customer_code: string;
    customer_document: string;
    customer_name: string;
    customer_address: string;
}


