import Entity from '../entity'

export default class Transaction extends Entity<number>{
    customer_id: number;
    supplier_id: number;
    employee_id: number;
    cashier_id: number;
    cashier_history_id: number;
    currency_id: number;
    payment_method_id: number;
    transaction_type_id: number;
    bank_account_id: number;
    voucher_id: number;
    credit_note_id: number;
    type: string;
    payment: string;
    reference: string;
    amount: number;
    total_paid: number;
    balance: number;
    conversion_rate: number;
    document_number: string;
    nro_operation: string;
    card_number: string;
    card_brand: string;
    card_expiration: string;
    card_holder: string;
    state_id: number;
    payment_date: string;

    currency_sign: string;
    change: number;
    return_amount: number; //CREDIT NOTES

    amount_paid: number;
    amount_return: number;
}


