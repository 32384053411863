import Entity from '../entity'

export default class FixedAsset extends Entity<number>{
    code: string;
    name: string;
    product_id: number;
    location_id: number;
    assigned_to_id: number;
    cost_center_id: number;
    serial: string;
    model: string;
    asset_class_id: number;
    fixed_asset_type_id: number;
    depreciation_type_id: number;
    state_id: number;
    condition_id: number;
    refund_reason_id: number;
    depreciation_method_id: number;
    useful_life_unit: string;
    useful_life: number;
    depreciation_rate: number;
    weight: number;
    acquisition_date: string;
    activation_date: string;
    refund_date: string;
    image: string;

    image_url: string;
    image_data: string;
}


