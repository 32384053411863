import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Shelf from '../../entities/stocks/shelf'
import Util from '../../../lib/util'
import Ajax from '../../../lib/ajax'
import Location from '@/store/entities/stocks/location';

interface ShelfState extends ListState<Shelf> {
    editModel: Shelf;
    activeList: Array<Shelf>;
    positionsList: Array<any>;
    location_id: number;
}
class ShelfModule extends ListModule<ShelfState, any, Shelf>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Shelf>(),
        loading:false,
        editModel:new Shelf(),
        activeList: new Array<Shelf>(),
        positionsList: new Array<any>(),
        location_id: 0,
        path: 'shelfs'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<ShelfState, any>, payload: any) {
        },
        async create(context: ActionContext<ShelfState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.activeList = [];
            context.state.list = response.data.result;
            context.state.totalCount = response.data.result.length;
        },
        async update(context: ActionContext<ShelfState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.activeList = [];
            context.state.list = response.data.result;
            context.state.totalCount = response.data.result.length;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setActiveList(state: ShelfState, shelfs: Array<Shelf>) {
            if (shelfs != null) {
                state.activeList = shelfs;
            } else
                state.activeList = new Array<Shelf>();
        },
        setPositionsList(state: ShelfState, shelf: Shelf) {
            state.positionsList = [];
            for (var i = shelf.code_start; i <= shelf.code_end; i++) {
                let pos = shelf.code + '-' + i.toString();
                state.positionsList.push({ id: pos, name: pos});
            }
        },
        setList(state: ShelfState, location: Location) {
            state.location_id = location.id;
            state.list = [];
            state.totalCount = 0;
            if (location != null && location.shelfs != null) {
                state.list = location.shelfs;
                state.totalCount = location.shelfs.length;
            }
        },
        edit(state: ShelfState, model: Shelf) {
            state.editModel = model;
            state.editModel.location_id = state.location_id;
        },
        new(state: ShelfState, model: Shelf) {
            state.editModel = Object.assign({});
            state.editModel.location_id = state.location_id;
        }
    });
}
const shelfModule=new ShelfModule();
export default shelfModule;