import Entity from '../entity'

export default class Report extends Entity<number>{
    parent_id: number;
    code: string;
    title: string;
    icon: string;
    active: boolean;
    position: number;
    apps: Array<any>;
}


