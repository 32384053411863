import Entity from '../entity'
import PurchasePlanningProduct from './purchaseplanningproduct';

export default class PurchasePlanning extends Entity<number>{
    reference: string;
    code: string;
    branch_id : number;
    current_state_id: number;
    year: number;
    month: number;
    total_quantity: number;
    total_purchase: number;
    issue_date: string;
    notes: string;
    valid: boolean;
    finished: boolean;

    current_state: any;
    products: Array<PurchasePlanningProduct>;
    documents: Array<any>;
}


