import Entity from '../entity'
import PurchaseQuotationDetail from './purchasequotationdetail';

export default class PurchaseQuotationSupplier extends Entity<number>{
    supplier_id : number;
    purchase_quotation_id : number;
    current_state_id : number;
    discount_type: string;
    subtotal: number;
    subtotal_wt: number;
    taxes: number;
    discounts: number;
    total: number;
    total_wt: number;
    issue_date: string;
    due_date: string;
    notes: string;
    valid: boolean;
    invalid: boolean;
    send: boolean;

    current_state: any;
    supplier_document: string;
    supplier_name: string;
    details: Array<PurchaseQuotationDetail>;
}


