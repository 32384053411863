import Entity from '../entity'

export default class Ark extends Entity<number>{
    company_id: number;
    name: string;
    card: number;
    bank: number;
    cash: number;
    total: number;
    receivable: number;
    debt: number;
    balance: number;

    moves: Array<any>;
}


