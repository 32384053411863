import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'
import RepairOrder from '@/store/entities/workshops/repairorder';
import RepairOrderHistory from '@/store/entities/sales/history';
import RepairOrderProduct from '@/store/entities/workshops/repairorderproduct';
import Quotation from '@/store/entities/sales/quotation';
import axios from 'axios'

interface RepairOrderState extends ListState<RepairOrder>{
    editModel: RepairOrder;
    hisLoading: boolean;
    history: Array<RepairOrderHistory>;
    has_changes: boolean;
    reportList: Array<any>;
    filename: string;
}
class RepairOrderModule extends ListModule<RepairOrderState, any, RepairOrder>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<RepairOrder>(),
        loading: false,
        hisLoading: false,
        has_changes: false,
        editModel:new RepairOrder(),
        activeList: new Array<RepairOrder>(),
        history: new Array<RepairOrderHistory>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'repair-orders'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<RepairOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (response.data.result && context.state.activeList != undefined)
                context.state.activeList.push(response.data.result);
            context.state.has_changes = false;
            return response.data.result;
        },
        async update(context: ActionContext<RepairOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (context.state.activeList != undefined) {
                context.state.activeList.forEach((item, k) => {
                    if (item.id == payload.data.id)
                        context.state.activeList[k] = Util.extend(context.state.activeList[k], payload.data);
                });
            }
            context.state.has_changes = false;
            return response.data.result;

        },
        async get(context: ActionContext<RepairOrderState, any>, payload: any) {
            if (payload.id == undefined) return {};
            context.state.loading = true;

            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            let item = reponse.data.result as RepairOrder;
            context.state.loading = false;
            return item;
        },
        async getLastNumber(context: ActionContext<RepairOrderState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/repair-orders/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<RepairOrderState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/repair-orders/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async confirm(context: ActionContext<RepairOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/repair-orders/confirm', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.has_changes = false;
            return response.data.result;
        },
        async reject(context: ActionContext<RepairOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/repair-orders/reject', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.has_changes = false;
            return response.data.result;
        },
        async checkStocks(context: ActionContext<RepairOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/repair-orders/check-stocks', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async start(context: ActionContext<RepairOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/repair-orders/start', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.has_changes = false;
            return response.data.result;
        },
        async finish(context: ActionContext<RepairOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/repair-orders/finish', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.has_changes = false;
            return response.data.result;
        },
        async getAllActive(context: ActionContext<RepairOrderState, any>, payload: any) {
            let params = (payload.data == undefined ? {} : payload.data);
            params.perPage = 100;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: params }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.activeList = reponse.data.result.data;
            context.state.loading = false;
        },
        async print(context: ActionContext<RepairOrderState, any>, payload: any) {
            context.state.loading = true;

            let format = Util.abp.setting.get('REPAIR_PRINT_FORMAT');
            let localUrl = Util.abp.setting.get('LOCAL_PRINTER_URL');
            let mode = Util.abp.setting.get('REMOTE_PRINTER_MODE');
            let method = Util.abp.setting.get('REMOTE_PRINTER_METHOD');
            let useRemote = Util.abp.setting.get('USE_REMOTE_PRINTER');

            let api = (format == 'A4' ? 'repair-order-pdf' : 'repair-order-receipt');

            let response = await Ajax.get('/api/' + api, { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            if (mode == 'LOCAL' && useRemote == 1 && format == 'TK') {
                if (response.data.result.localUrl != undefined) localUrl = response.data.result.localUrl;
                if (method == 'POST') {
                    await axios.post(localUrl, response.data.result);
                } else {
                    let href: any = localUrl + "?endpoint=" + (url + 'api/' + api) + "&token=" + window.abp.auth.getToken() + "&id=" + payload.id;
                    const link = document.createElement('a');
                    link.href = href;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    return '';
                }
                return '';
            } else
                return response.data.result;
        },
        async reportRepairOrders(context: ActionContext<RepairOrderState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/repair-orders', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: RepairOrderState, model: RepairOrder) {
            let date = new Date();

            let serie = Util.abp.setting.get('RO_SERIE');

            state.editModel = Object.assign({});
            state.editModel.admission_date = Util.abp.clock.today();
            state.editModel.customer_id = null;
            state.editModel.customer_name = '';
            state.editModel.responsable_id = Util.abp.session.employeeId;
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');            
            state.editModel.workshop_id = Util.abp.setting.get('DEFAULT_WORKSHOP');
            state.editModel.repair_type_id = Util.abp.setting.get('DEFAULT_REPAIR_TYPE');
            state.editModel.service_type_id = Util.abp.list.get('T_REPAIR_SERVICE', 'GA').id;
            state.editModel.user_type_id = Util.abp.list.get('T_INOUT_USER', 'CL').id;
            state.editModel.warehouse_id = null;
            state.editModel.priority = 1;
            state.editModel.total_work_hours = 1;
            state.editModel.total_work_cost = 0.00;
            state.editModel.total_products = 0.00;
            state.editModel.total_purchases = 0.00;
            state.editModel.total = 0.00;
            state.editModel.total_paid = 0.00;
            state.editModel.balance = 0.00;
            
            state.editModel.serie = (serie == 'YYYY' ? date.toISOString().substr(0, 4) : serie);
            state.editModel.number = '00000';
        },
        edit(state: RepairOrderState, model: RepairOrder) {
            state.editModel = model;

            if (model.product != null)
                state.editModel.product_name = model.product.name;
        },
        updatePayments(state: RepairOrderState, total_paid: any) {
            state.editModel.total_paid = parseFloat(total_paid);
            state.editModel.balance = parseFloat((state.editModel.total - state.editModel.total_paid).toFixed(2));
            state.has_changes = true;
        },
        loadFromQuotation(state: RepairOrderState, model: Quotation) {
            if (model == undefined) return;
            var today = new Date();
                        
            state.editModel = Object.assign({});
            state.editModel.reference = model.reference;
            state.editModel.admission_date = Util.abp.clock.today();
            state.editModel.sale_quotation_id = model.id;
            state.editModel.customer_id = model.customer_id;
            state.editModel.customer_name = model.customer_name;
            state.editModel.product_name = model['product'].product_name;
            state.editModel.responsable_id = Util.abp.session.employeeId;
            state.editModel.priority = Util.abp.list.get('PRIORITY', 'P1').id;
            state.editModel.product_id = model['product'].product_id;
            state.editModel.serie = today.toISOString().substr(0, 4);
            state.editModel.number = '00000';
            state.editModel.description = model['product'].product_name;
            state.editModel.total_products = model['product'].total_wt;      
            state.editModel.quotation_nro = model.nro;      
        },
        setChanged(state: RepairOrderState, value: boolean) {
            state.has_changes = value;
        },
    });
}
const repairorderModule=new RepairOrderModule();
export default repairorderModule;