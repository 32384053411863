import Entity from '../entity'
import MeasureUnit from '../catalogs/measureunit';
import Product from '../catalogs/product';

export default class PurchaseCreditNoteDetail extends Entity<number>{
    purchase_credit_note_id: number;
    product_id: number;
    product_attribute_id: number;
    warehouse_id: number;
    unity_id: number;
    purchase_detail_id: number;
    code: string;
    upc: string;
    product_name: string;
    version: string;
    quantity: number;
    cost: number;
    cost_wt: number;
    taxes: number;
    total: number;
    total_wt: number;
    discounts: number;
    stock: number;
    unity: MeasureUnit;
    product: Product;
    service: boolean;
}


