import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import IncomeOutputConcept from '../../entities/cashiers/incomeoutputconcept'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface IncomeOutputConceptState extends ListState<IncomeOutputConcept>{
    editModel:IncomeOutputConcept;
    
}
class IncomeOutputConceptModule extends ListModule<IncomeOutputConceptState,any,IncomeOutputConcept>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<IncomeOutputConcept>(),
        loading:false,
        editModel: new IncomeOutputConcept(),
        activeList: new Array<IncomeOutputConcept>(),
        path: 'inout-concepts'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const incomeoutputconceptModule=new IncomeOutputConceptModule();
export default incomeoutputconceptModule;