import Entity from '../entity'

export default class Account extends Entity<number>{
    code: string;
    type_id: number;
    name: string;
    credit: number;
    debit: number;
    balance: number;
    active: boolean;
}


