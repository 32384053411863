import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Process from '@/store/entities/production/process';

interface ProcessState extends ListState<Process>{
    editModel: Process;
}
class ProcessModule extends ListModule<ProcessState, any, Process>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Process>(),
        loading:false,
        editModel:new Process(),
        activeList: new Array<Process>(),
        path: 'processes'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastCode(context: ActionContext<ProcessState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/processes/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.code = result;

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ProcessState, model: Process) {
            state.editModel = Object.assign({});
            state.editModel.code = '';
            state.editModel.position = 1;
        },
    });
}
const processModule=new ProcessModule();
export default processModule;