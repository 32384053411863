import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ProductSupplier from '../../entities/catalogs/productsupplier'
import Ajax from '../../../lib/ajax' 
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface ProductSupplierState extends ListState<ProductSupplier>{
    editModel:ProductSupplier;
    product: number;
    activeList: Array<ProductSupplier>;
    batches: Array<any>;
}
class ProductSupplierModule extends ListModule<ProductSupplierState,any,ProductSupplier>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 5,
        list: new Array<ProductSupplier>(),
        loading:false,
        editModel:new ProductSupplier(),
        activeList: new Array<ProductSupplier>(),
        product: 0,
        path: 'product-suppliers',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<ProductSupplierState, any>, payload: any) {
            if (context.state.loading) return; 
            context.state.loading = true;
            let reponse = await Ajax.get('/api/product-suppliers', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<ProductSupplier>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }
        },
        async create(context: ActionContext<ProductSupplierState, any>, payload: any) {
            context.state.loading = true;
            payload.data.product_id = context.state.product;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list.push(response.data.result);
            return response.data.result;
        },
        async delete(context: ActionContext<ProductSupplierState, any>, payload: any) {
            context.state.loading = true;
            payload.data.product_id = context.state.product;
            await Ajax.post('/api/product-suppliers/delete', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations    
        new(state: ProductSupplierState, model: ProductSupplier) {
            state.editModel = Object.assign({});
            state.editModel.product_id = state.product;
            state.editModel.product_attribute_id = null;
            state.editModel.supplier_id = null;
            state.editModel.currency_id = null;
            state.editModel.supplier_reference = null;
            state.editModel.supplier_cost = 0;
        },
        setList(state: ProductSupplierState, list: Array<ProductSupplier>) {
            if (list != null) {
                state.list = list;
                state.totalCount = list.length;
            }
        },
        setProduct(state: ProductSupplierState, product: number) {
            state.product = product;
        },
    });
}
const productsupplierModule=new ProductSupplierModule();
export default productsupplierModule;